// Complex -> Simplied Logic, detailed:
//  https://docs.google.com/spreadsheets/d/1K5zkhRI2xOiX0rOKnS8ZjW909-KO-8S2FujNJhy3Z98/edit#gid=596165603

// A few helpers for string checks
const isPending = (invoice) => invoice.status === 'pending';
const isSubmitted = (invoice) => invoice.status === 'submitted';

// Main export for deciding when a component should show or hide
export const shouldComponentShow = (componentName, componentData) => {
  const { invoice, invoicePaymentSchedule, state } = componentData;

  const paymentSubmitted = state.paymentSubmitted || isPending(invoice) || isSubmitted(invoice);

  switch (componentName) {
    case 'InvoiceHeader':
    case 'InvoiceDetails':
      return true;

    case 'InvoiceSummary':
      return !state.paymentSubmitted && !isPending(invoice) && !isSubmitted(invoice);

    case 'PaymentNotSetup':
      return !paymentSubmitted && invoice.isPayable && !invoice.hasOnlinePaymentOptions;

    case 'PaymentSubmitted':
      return paymentSubmitted;

    case 'PaymentSummary':
      return !paymentSubmitted && invoice.hasPayments;

    case 'CreditCardFormInline':
      return !paymentSubmitted && invoice.hasOnlinePaymentOptions && invoice.isPayable;

    case 'CreditCardFormModal':
      return (
        !paymentSubmitted && invoice.hasOnlinePaymentOptions && invoicePaymentSchedule.isDefaultPaymentMethodUpdatable
      );

    case 'InstallmentToggle':
      return (
        !paymentSubmitted && invoice.hasOnlinePaymentOptions && invoice.isPayable && invoice.canPayByPaymentSchedule
      );

    case 'InstallmentSummary':
      return !paymentSubmitted && invoice.hasOnlinePaymentOptions && state.isInstallmentPlan;

    case 'PaymentLog':
      return !paymentSubmitted && invoice.hasPayments;

    case 'PaymentLogScheduled':
      return (
        !paymentSubmitted && invoice.hasPayments && state.payments.scheduled && state.payments.scheduled.length > 0
      );

    case 'PaymentLogModal':
      return !paymentSubmitted && !invoice.isPayable && invoice.hasPayments;

    case 'CheckoutSummary':
      return !paymentSubmitted && invoice.isPayable && invoice.hasOnlinePaymentOptions;

    case 'PaymentFeedback':
      return !paymentSubmitted && state.paymentUpdateStatus;

    default:
      return false;
  }
};
