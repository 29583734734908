import { connect } from 'react-redux';
import { createBatchInvoice } from 'state/batchInvoices/actions';
import { createBatchInvoiceLineItem } from 'state/batchInvoiceLineItems/actions';
import { analyticEvent } from 'state/analyticEvent/actions';
import { userId } from 'utils/window';
import { selectDateFormat } from 'state/group/selectors';
import InvoicingNew from './InvoicingNew';
import { selectPaymentAccountIsLoaded, selectPaymentAccount } from 'state/paymentAccount/selectors';
import { selectDepositAccount, selectDepositAccountLoaded } from 'state/depositAccount/selectors';

const mapDispatchToProps = {
  createBatchInvoice,
  createBatchInvoiceLineItem,
  analyticEvent,
};

const mapStateToProps = (state, ownProps) => {
  const { location } = ownProps;

  return {
    dateFormat: selectDateFormat(state),
    userId: userId,
    location,
    paymentAccountIsLoaded: selectPaymentAccountIsLoaded(state),
    paymentAccount: selectPaymentAccount(state),
    depositAccountIsLoaded: selectDepositAccountLoaded(state),
    depositAccount: selectDepositAccount(state),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(InvoicingNew);
