import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SummaryList } from '@teamsnap/teamsnap-ui';
import { formatDate } from 'utils/date-service';
import styles from '../PayInvoice.module.scss';

class InstallmentSummary extends Component {
  paymentDate = (date) => formatDate(date, this.props.dateFormat);

  renderInstallments = () => {
    const { installments, total } = this.props;
    const activePayment = installments.find((installment) => installment.isDeposit);

    const items = installments.map((installment, index) => {
      const description =
        installment.id === activePayment.id
          ? 'Collected at Checkout'
          : `Scheduled ${this.paymentDate(installment.paymentDate)}`;

      return {
        description: `Installment ${index + 1}: ${description}`,
        value: installment.paymentTotalWithCurrency,
        ...(installment.id !== activePayment.id && { mods: 'u-colorGrey' }),
      };
    });

    items.push({ description: 'Installment Total', value: total, isFooterRow: true });

    return items;
  };

  render() {
    return (
      <section className={ styles.Component }>
        <SummaryList items={ this.renderInstallments() } heading="Installment Summary" subHeading="Amount" />
      </section>
    );
  }
}

InstallmentSummary.propTypes = {
  installments: PropTypes.arrayOf(PropTypes.object).isRequired,
  total: PropTypes.string,
  dateFormat: PropTypes.string,
};

InstallmentSummary.defaultProps = {
  total: null,
  dateFormat: null,
};

export default InstallmentSummary;
