/**
 * Invoice Payment Transaction Selector
 *
 * Various selectors using reselect for selecting data from state
 *
 */

import _values from 'lodash/values';

const stateSelector = (state) => state.invoicePaymentTransactions;
const itemSelector = (state) => stateSelector(state).items;
const isFetchingSelector = (state) => stateSelector(state).isFetching;
const isErrorSelector = (state) => stateSelector(state).isError;

// Select invoice payment Fetching or ERror states
export const selectInvoicePaymentTransactionIsFetching = (state) => isFetchingSelector(state);
export const selectInvoicePaymentTransactionIsError = (state) => isErrorSelector(state);

export const selectInvoicePaymentTransactions = (state) => itemSelector(state) || {};
export const selectInvoicePaymentTransactionItems = (state) => _values(itemSelector(state));
