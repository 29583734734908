import React, { Component } from 'react';
import { Grid } from '@teamsnap/teamsnap-ui';
import styles from './CheckoutForm.module.scss';
import { ImgCreditCardLogos } from 'components/baseComponents/Img';

class CheckoutHeader extends Component {
  render() {
    return (
      <Grid isWithGutter isFit>
        <h3 className={ styles['CheckoutForm-heading'] }>
          <span>Credit Card Info</span>
          <span>
            <ImgCreditCardLogos width="80" />
          </span>
        </h3>
      </Grid>
    );
  }
}

export default CheckoutHeader;
