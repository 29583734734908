import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Icon, LabelButton } from '@teamsnap/snap-ui';

const Container = styled.div`
    h2 {
      color: var(--sui-colors-gray-10, #191919);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: "Open Sans";
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.5rem;
    }

    p {
      color: var(--sui-colors-gray-40, #666);
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: "Open Sans";
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
    }

    .Card {
      margin: auto;
      padding: var(--sui-spacing-6, 3rem) var(--sui-spacing-2, 1rem);
      width: 37.5rem;
      background-color: var(--sui-colors-neutral-background, #fff);
      border: 1px solid var(--sui-colors-gray-80, #d1d1d1);
    }

    .FinancialIcon {
      color: var(--sui-colors-accent-background, #F56B15);
    }
  `;

const AccountSetupCard = ({ isTeamOwner, onSetUpAccount, isLoading }) => {

  React.useEffect(() => {
    console.log('Rendered AccountSetupCard');
  }, []);

  return (
    <Container>
      <div className="Card sui-rounded sui-text-center" data-testid="account-setup-card">
        <div className="sui-m-3">
          <Icon name="account_balance" className="FinancialIcon sui-mb-1" size="xl" />
          <div className='sui-my-2 sui-mx-3'>
            <h2 className='sui-mb-2'>Deposit account setup pending</h2>

            { isTeamOwner && 
              <p>Please set up a deposit account to enable invoicing.</p>
            }
            { !isTeamOwner && 
              <p>A deposit account is required to enable invoicing. 
                Deposit accounts must be set up by the team owner.
              </p> 
            }
          </div>
        </div>
        { isTeamOwner && 
          <LabelButton
            data-testid="set-up-account-button"
            disabled={ isLoading }
            className="sui-mt-2"
            icon="chevron_right"
            iconPosition="right"
            labelText="Set up deposit account"
            size="large"
            variantType="primary"
            onClick={ onSetUpAccount }
          />
        }
      </div>
    </Container>
  );
};

AccountSetupCard.propTypes = {
  isTeamOwner: PropTypes.bool,
  onSetUpAccount: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

AccountSetupCard.defaultProps = {
  isTeamOwner: false,
  isLoading: false
};

export default AccountSetupCard;
