import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Modal, Form } from 'components/teamsnapUIExtensions';
import { Button } from '@teamsnap/teamsnap-ui';
import InputField from 'components/baseComponents/Fields/InputField';
import TextAreaField from 'components/baseComponents/Fields/TextAreaField';
import { hideModal } from 'state/modal/actions';
import { showFeedback } from 'state/feedback/actions';
import { SUCCESS, ERROR } from 'components/teamsnapUIExtensions/Feedback/Feedback';
import { createInvoiceMessage } from 'state/invoiceMessages/actions';
import { validate } from './validate';
import styles from './ModalSendInvoice.module.scss';

class ModalSendInvoice extends PureComponent {
  onSubmit = ({ subject, body }) => {
    const { invoiceRecipientIds, batchId, createPaymentMessage } = this.props;

    const message = {
      subject,
      body,
      batch_invoice_id: batchId,
      member_ids: invoiceRecipientIds,
    };

    createPaymentMessage(message)
      .then(() => this.props.showFeedback(this.feedbackSuccessProps))
      .catch(() => this.props.showFeedback(this.feedbackErrorProps))
      .finally(() => this.handleClose());
  };

  handleClose = () => {
    this.props.closeModal();
  };

  feedbackSuccessProps = {
    title: 'You did it!',
    message: 'The invoice has been re-sent. Show me the money!',
    type: SUCCESS,
  };

  feedbackErrorProps = {
    title: 'Oops!',
    // eslint-disable-next-line
    message:
      'Something went wrong on our end. Please try again, and if the error persists let us know at https://helpme.teamsnap.com/?solvvy=true so we can see what’s going on.',
    type: ERROR,
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <Modal title="Resend Invoice" handleClose={ this.handleClose }>
        <Form handleSubmit={ handleSubmit(this.onSubmit) }>
          <div className={ styles.ModalBody }>
            <InputField name="subject" type="text" label="Subject:" />
            <TextAreaField name="body" label="Text of your email:" placeholder="Add a note here" />
          </div>
          <div className={ styles.ModalFooter }>
            <Button onClick={ this.handleClose } icon="dismiss" color="negative" mods="u-spaceRightSm">
              Cancel
            </Button>
            <Button type="submit" color="primary" icon="mail">
              Resend Invoice
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
}

ModalSendInvoice.propTypes = {
  closeModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  batchId: PropTypes.string.isRequired,
  invoiceRecipientIds: PropTypes.string.isRequired,
  createPaymentMessage: PropTypes.func.isRequired,
  showFeedback: PropTypes.func,
};

ModalSendInvoice.defaultProps = {
  showFeedback: null,
};

export default connect(null, {
  closeModal: hideModal,
  createPaymentMessage: createInvoiceMessage,
  showFeedback,
})(
  reduxForm({
    form: 'sendMessage',
    validate,
  })(ModalSendInvoice),
);
