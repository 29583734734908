import { isCurrency } from 'utils/validator';

export const validate = (values) => {
  const batchInvoice = values.batchInvoice || {};
  const batchInvoiceLineItems = values.batchInvoiceLineItems || {};
  const errors = { batchInvoice: {}, batchInvoiceLineItems: {} };

  const defaultError = 'Field can not be blank';
  const invoiceFields = ['title', 'dueAt'];

  invoiceFields.forEach((field) => {
    if (!batchInvoice[field]) {
      errors.batchInvoice[field] = defaultError;
    }
  });

  if (!batchInvoiceLineItems.items || !batchInvoiceLineItems.items.length) {
    errors.batchInvoiceLineItems.items = { _error: 'At least one line item must be entered' };
  } else {
    const itemsArrayErrors = [];
    const lineItemFields = ['invoiceCategoryId', 'description', 'amount'];

    batchInvoiceLineItems.items.forEach((item, index) => {
      const itemErrors = {};

      lineItemFields.forEach((field) => {
        if (!item || !item[field]) {
          itemErrors[field] = defaultError;
        }
        if (field === 'description') {
          if (item.description && item.description.length > 250) {
            itemErrors[field] = 'Must be 250 characters or less';
          }
        }
        if (field === 'amount') {
          // check for a non negative value
          if (item.amount < 0) {
            itemErrors[field] = 'Amount must be a positive number';
          }

          // check for only numbers , or .
          const parsed = isCurrency(item.amount);

          if (!parsed.valid) {
            itemErrors[field] = parsed.message;
          }
        }
        itemsArrayErrors[index] = itemErrors;
      });
    });

    if (itemsArrayErrors.length) {
      errors.batchInvoiceLineItems.items = itemsArrayErrors;
    }
  }

  return errors;
};
