import { connect } from 'react-redux';
import { saveAppSettings } from 'state/app/actions';
import { loadDivisionTree } from 'state/divisions/actions';
import { loadGroupPreferences } from 'state/group/actions';
import { loadDivisionAggregates } from 'state/divisionAggregates/actions';
import { loadAdvancedDivisionMembers } from 'state/members/actions';
import { loadTeamNames } from 'state/teamNames/actions';
import { queryRegistrationFormsWithItemOptions } from 'state/registrationForms/actions';
import { saveRouteLocation } from 'state/routerLocation/actions';
import { getPaymentAccount } from 'state/paymentAccount/actions';
import LeaguePayments from './LeaguePayments';

const mapDispatchToProps = {
  loadDivisionTree,
  loadTeamNames,
  loadDivisionAggregates,
  loadAdvancedDivisionMembers,
  loadGroupPreferences,
  queryRegistrationFormsWithItemOptions,
  saveRouteLocation,
  saveAppSettings,
  getPaymentAccount,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LeaguePayments);
