import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './Widget.module.scss';

class LabelIndicator extends PureComponent {
  render() {
    const { indicatorColor, label, value, info, mods } = this.props;

    return (
      <div className={ [styles.Label, mods].join(' ') }>
        <h3 className={ styles.LabelIndicatorHeader }>
          { indicatorColor && (
            <i className={ `${styles.LabelIndicator} ${styles[`LabelIndicator--${indicatorColor}`]}` } />
          ) }
          { label }
        </h3>
        <h5 className={ styles.LabelIndicatorContent }>
          { value }
          { info && <span className={ styles.LabelIndicatorInfo }>{ info }</span> }
        </h5>
      </div>
    );
  }
}

LabelIndicator.propTypes = {
  indicatorColor: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  info: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mods: PropTypes.string,
};

LabelIndicator.defaultProps = {
  indicatorColor: null,
  label: null,
  value: null,
  info: null,
  mods: null,
};

export default LabelIndicator;
