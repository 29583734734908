import { connect } from 'react-redux';
import { selectBatchInvoices, selectBatchInvoiceIsFetching } from 'state/batchInvoices/selectors';
import { selectInvoices, selectInvoiceIsFetching } from 'state/invoices/selectors';
import { loadBatchInvoiceAggregates } from 'state/batchInvoiceAggregates/actions';
import { loadBatchInvoices, cancelBatchInvoice, deleteBatchInvoice } from 'state/batchInvoices/actions';
import { cancelInvoice, deleteInvoiceAndReloadBatchInvoice, loadInvoices } from 'state/invoices/actions';
import { showModal } from 'state/modal/actions';
import { selectBatchInvoiceAggregatesByGroupId } from 'state/batchInvoiceAggregates/selectors';
import {
  selectGroupPreferencesIsFetching,
  selectDateFormat,
  selectIsPaymentProviderStripe,
  selectIsPaymentProviderWePay,
  selectHasActivePaymentProvider,
  selectNeedsRefreshToShowFinancials,
  selectHasActiveClassicPayments,
} from 'state/group/selectors';
import { selectCanUpdate } from 'state/canUpdate/selectors';
import { selectPaymentAccountIsLoaded,
  selectPaymentAccountSetupComplete,
  selectPaymentAccountCurrentlyDueRequirements,
  selectPaymentAccountEventuallyDueRequirements,
  selectPaymentAccountChargesEnabled,
  selectPaymentAccountPayoutsEnabled,
  selectPaymentAccount,
  selectPaymentAccountCurrentDeadline,
  selectPaymentAccountDisabledReason
} from 'state/paymentAccount/selectors';
import { selectDepositAccountIsFetching, selectDepositAccount } from 'state/depositAccount/selectors';
import { createDepositOnboardingLink } from 'state/depositAccountOnboarding/actions';
import { analyticEvent } from 'state/analyticEvent/actions';
import Invoicing from './Invoicing';
import { fetchCanUpdate } from 'state/canUpdate/actions';
import { selectTeam } from 'state/teams/selectors';

const mapDispatchToProps = {
  analyticEvent,
  cancelBatchInvoice,
  deleteBatchInvoice,
  deleteInvoice: deleteInvoiceAndReloadBatchInvoice,
  loadBatchInvoiceAggregates,
  loadBatchInvoices,
  loadInvoices,
  cancelInvoice,
  showModal,
  fetchCanUpdate,
  createDepositOnboardingLink,
};

const mapStateToProps = (state) => ({
  dateFormat: selectDateFormat(state),
  isFetching: selectBatchInvoiceIsFetching(state) || selectGroupPreferencesIsFetching(state),
  invoicesIsFetching: selectInvoiceIsFetching(state),
  batchInvoices: selectBatchInvoices(state),
  invoices: selectInvoices(state),
  batchInvoiceAggregates: selectBatchInvoiceAggregatesByGroupId(state) || {},
  isPaymentProviderWePay: selectIsPaymentProviderWePay(state),
  isPaymentProviderStripe: selectHasActivePaymentProvider(state) && selectIsPaymentProviderStripe(state),
  needsRefresh: selectNeedsRefreshToShowFinancials(state),
  paymentAccountIsLoaded: selectPaymentAccountIsLoaded(state),
  paymentAccountSetupComplete: selectPaymentAccountSetupComplete(state),
  paymentAccount: selectPaymentAccount(state),
  depositAccountIsFetching: selectDepositAccountIsFetching(state),
  depositAccount: selectDepositAccount(state),
  paymentAccountCurrentlyDueRequirements: selectPaymentAccountCurrentlyDueRequirements(state),
  paymentAccountEventuallyDueRequirements: selectPaymentAccountEventuallyDueRequirements(state),
  paymentAccountCurrentDeadline: selectPaymentAccountCurrentDeadline(state),
  paymentAccountDisabledReason: selectPaymentAccountDisabledReason(state),
  paymentAccountChargesEnabled: selectPaymentAccountChargesEnabled(state),
  paymentAccountPayoutsEnabled: selectPaymentAccountPayoutsEnabled(state),
  hasActiveClassicPayments: selectHasActiveClassicPayments(state),
  canUpdate: selectCanUpdate(state),
  teamState: selectTeam(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(Invoicing);
