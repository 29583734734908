import { Button, TextLink } from '@teamsnap/teamsnap-ui';
import InvoiceStatus from 'components/baseComponents/InvoiceStatus';
import InvoicingDetailsOverview from 'components/baseComponents/InvoicingDetailsOverview';
import InvoicingPlaceholder from 'components/baseComponents/InvoicingPlaceholder';
import CancelInvoice from 'components/baseComponents/ModalCancelInvoice';
import DeleteInvoice from 'components/baseComponents/ModalDeleteInvoice';
import SendBatch from 'components/baseComponents/ModalSendBatch';
import SendInvoice from 'components/baseComponents/ModalSendInvoice';
import { Loader, Popover } from 'components/teamsnapUIExtensions';
import Table from 'components/teamsnapUIExtensions/Table';
import { batchInvoicePropShape } from 'interfaces/batchInvoice';
import { invoicePropShape } from 'interfaces/invoice';
import { historyPropShape } from 'interfaces/navigation';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from 'utils/date-service';
import { linkInvoice, linkInvoicingMembers } from 'utils/links';
import styles from './InvoicingDetails.module.scss';

const invoiceColumns = [
  { label: 'Recipient', key: 'recipient', sortable: true, filterable: true, className: 'Panel-cell u-size1of24' },
  { label: 'ID', key: 'idLink', sortable: true, filterable: true },
  { label: 'Date Sent', key: 'createdAt', sortable: true, filterable: true },
  { label: 'Due Date', key: 'dueAt', sortable: true, filterable: true },
  { label: 'Status', key: 'status', sortable: true, filterable: true, className: 'Panel-cell u-size2of24' },
  { label: 'Total', key: 'subTotalWithCurrency', sortable: true, className: 'Panel-cell u-textRight' },
  { label: 'Payments', key: 'amountPaidWithCurrency', sortable: true, className: 'Panel-cell u-textRight' },
  {
    label: 'Refunds',
    key: 'paymentAdjustmentsAmountWithCurrency',
    sortable: true,
    className: 'Panel-cell u-textRight',
  },
  { label: 'Balance', key: 'balanceWithCurrency', sortable: true, className: 'Panel-cell u-textRight' },
  { label: 'Actions', key: 'actions', className: 'Panel-cell u-textCenter' },
];

class InvoicingDetails extends Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { batchInvoiceId, queryBatchInvoiceWithMemberEmailCollections } = this.props;

    return queryBatchInvoiceWithMemberEmailCollections(batchInvoiceId);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.batchInvoice.status || !this.props.batchInvoice.status) {
      return;
    }

    if (nextProps.batchInvoice.status !== this.props.batchInvoice.status) {
      this.props.queryBatchInvoiceWithMemberEmailCollections(this.props.batchInvoiceId);
    }
  }

  mapInvoiceRows = (invoices) =>
    invoices.map((invoice) => ({
      ...invoice,
      idLink: (
        <Link
          to={ linkInvoice(invoice[this.props.groupType], invoice.batchInvoiceId, invoice.id) }
          data-sort={ invoice.id }
        >
          { invoice.id }
        </Link>
      ),
      createdAt: formatDate(invoice.createdAt, this.props.dateFormat),
      dueAt: formatDate(invoice.dueAt, this.props.dateFormat),
      status: this.renderStatus(invoice),
      actions: this.renderActionPopover(invoice),
      recipient: invoice.invoiceTo || '',
    }));

  showSendInvoiceModal = (invoice) => {
    const { showModal } = this.props;
    const { batchInvoiceId: batchId, recipientId: invoiceRecipientIds } = invoice;
    return showModal(SendInvoice, { invoiceRecipientIds, batchId });
  };

  showSendBatchModal = (batchId) => {
    const { showModal } = this.props;
    return showModal(SendBatch, { batchId });
  };

  showCancelInvoiceModal = (id, batchInvoiceId) => {
    const { showModal, cancelInvoice } = this.props;
    return showModal(CancelInvoice, { id, batchInvoiceId, handleConfirm: cancelInvoice });
  };

  showDeleteInvoiceModal = (id) => {
    const { showModal, deleteInvoice } = this.props;
    return showModal(DeleteInvoice, { id, handleConfirm: deleteInvoice });
  };

  showCancelBatchModal = (id) => {
    const { showModal, cancelBatchInvoice } = this.props;
    return showModal(CancelInvoice, { id, handleConfirm: cancelBatchInvoice, isBatch: true });
  };

  renderStatus = (invoice) => (
    <div data-sort={ invoice.status } data-filter={ invoice.status }>
      <InvoiceStatus
        status={ invoice.status }
        hasPaymentSchedule={ invoice.hasPaymentSchedule }
        errorCount={ invoice.failedInvoicePaymentsCount }
        currentValue={ invoice.balance }
        totalValue={ invoice.subTotal }
      />
    </div>
  );

  renderActionPopover = (invoice) => {
    const actionItems = [
      <Link key={ 'view' } to={ linkInvoice(invoice[this.props.groupType], invoice.batchInvoiceId, invoice.id) }>
        View Invoice
      </Link>,
    ];

    if (invoice.isCancelable === 'true') {
      actionItems.push(
        <TextLink location={ null } key={ 'message' } onClick={ () => this.showSendInvoiceModal(invoice) }>
          Resend Invoice
        </TextLink>,
        <TextLink
          location={ null }
          key={ 'cancel' }
          onClick={ () => this.showCancelInvoiceModal(invoice.id, invoice.batchInvoiceId) }
        >
          Cancel Invoice
        </TextLink>,
      );
    }
    if (invoice.isDeletable) {
      actionItems.push(
        <TextLink location={ null } key={ 'delete' } onClick={ () => this.showDeleteInvoiceModal(invoice.id) }>
          Delete Invoice
        </TextLink>,
      );
    }

    return <Popover items={ actionItems.sort() } />;
  };

  renderNoInvoices = (batchInvoice, groupId) => (
    <InvoicingPlaceholder
      title="No Invoices Created"
      bodyText="Easily send invoices to members of your organization and collect payments online."
      buttonText="Add Recipients"
      link={ () => this.props.history.push(linkInvoicingMembers(groupId, batchInvoice.id)) }
    />
  );

  renderInvoiceTableActions = (batchInvoice) => (
    <header className={ styles.BatchActionsHeader }>
      { !batchInvoice.isDeletable && (
        <Button icon="send" onClick={ () => this.showSendBatchModal(batchInvoice.id) }>
          Resend
        </Button>
      ) }
      { batchInvoice.isCancelable && (
        <Button color="negative" icon="dismiss" onClick={ () => this.showCancelBatchModal(batchInvoice.id) }>
          Cancel
        </Button>
      ) }
    </header>
  );

  renderInvoiceItems = (batchInvoice, invoices) => (
    <Table
      columns={ invoiceColumns }
      rows={ this.mapInvoiceRows(invoices) }
      headerComponent={ this.renderInvoiceTableActions(batchInvoice) }
    />
  );

  renderInvoices = () => {
    const { invoices, batchInvoice, groupId } = this.props;

    return invoices.length
      ? this.renderInvoiceItems(batchInvoice, invoices)
      : this.renderNoInvoices(batchInvoice, groupId);
  };

  render() {
    const {
      loading,
      batchInvoice,
      batchInvoicePaymentScheduleDetails,
      batchInvoiceLineItems,
      groupId,
      groupType,
      dateFormat,
      feesPassed,
      showModal,
      history,
    } = this.props;

    // Return Loader if still fetching items
    if (loading) {
      return <Loader message="random" />;
    }

    return (
      <section>
        <InvoicingDetailsOverview
          history={ history }
          feesPassed={ feesPassed }
          batchInvoice={ batchInvoice }
          batchInvoiceLineItems={ batchInvoiceLineItems }
          batchInvoicePaymentScheduleDetails={ batchInvoicePaymentScheduleDetails }
          groupId={ groupId }
          groupType={ groupType }
          dateFormat={ dateFormat }
          showModal={ showModal }
        />
        { this.renderInvoices() }
      </section>
    );
  }
}

InvoicingDetails.propTypes = {
  batchInvoice: PropTypes.shape(batchInvoicePropShape).isRequired,
  batchInvoiceLineItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  batchInvoicePaymentScheduleDetails: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  feesPassed: PropTypes.bool.isRequired,
  batchInvoiceId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  invoices: PropTypes.arrayOf(PropTypes.shape(invoicePropShape)).isRequired,
  queryBatchInvoiceWithMemberEmailCollections: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  dateFormat: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired,
  cancelInvoice: PropTypes.func.isRequired,
  cancelBatchInvoice: PropTypes.func.isRequired,
  deleteInvoice: PropTypes.func.isRequired,
  groupType: PropTypes.string.isRequired,
  history: PropTypes.shape(historyPropShape).isRequired,
};

InvoicingDetails.defaultProps = {
  loading: true,
};

export default InvoicingDetails;
