/**
 * Invoice Recipient Actions
 *
 * Various actions using redux-thunk and axios for managing communication with API and redux store
 *
 */

import { getCollection, removeCollection } from 'state/baseActions';
import { selectInvoiceRecipientsByInvoiceId } from './selectors';

export const INVOICE_RECIPIENT = 'INVOICE_RECIPIENT';

// Temporary loading pattern until email/messaging feature is complete
//  - see also memberEmails
export const loadInvoiceRecipients = (params) => (dispatch) =>
  dispatch(getCollection(INVOICE_RECIPIENT, 'invoice_recipients/search', params));

export const removeInvoiceRecipientsByInvoiceId = (invoiceId) => (dispatch, getState) => {
  selectInvoiceRecipientsByInvoiceId(getState())(invoiceId).forEach((invoiceRecipient) =>
    dispatch(removeCollection(INVOICE_RECIPIENT, invoiceRecipient)),
  );
  return Promise.resolve();
};
