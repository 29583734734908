/**
 * Toggle Component
 *
 * This is a 'helper' component that calls InputControl with the appropriate
 * default props for a 'toggle'
 *
 */

import React from 'react';
import InputControl from './InputControl';
import styles from './Toggle.module.scss';

const Toggle = (props) => <InputControl { ...props } type="toggle" componentStyles={ styles } />;

export default Toggle;
