import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Cell, Button, Checkbox } from '@teamsnap/teamsnap-ui';
import styles from '../PayInvoice.module.scss';

class InvoiceFooter extends Component {
  state = {
    hasAcceptedTerms: false,
    acceptError: null,
  };

  handleChange = () => {
    this.setState({
      hasAcceptedTerms: !this.state.hasAcceptedTerms,
    });
  };

  handleSubmit = (e) => {
    const { hasAcceptedTerms } = this.state;
    const { handleModalClick } = this.props;

    if (!hasAcceptedTerms) {
      e.preventDefault();
      return this.setState({ acceptError: 'You must agree to the Terms of Service.' });
    }

    handleModalClick && handleModalClick();

    return this.setState({ acceptError: null });
  };

  renderTermsLink = () => (
    <span>
      I agree to the TeamSnap{ ' ' }
      <a href="https://www.teamsnap.com/terms" target="_blank" rel="noopener noreferrer">
        terms of service
      </a>
    </span>
  );

  render() {
    const { isSubmitting, isDisabled, note, buttonLabel, icon, handleModalClick } = this.props;

    return (
      <footer className={ styles.InvoiceFooter }>
        <hr className={ styles.FooterDivider } />

        <Grid>
          <Cell mods="u-size1of1 u-sm-size1of2 u-textCenter u-sm-textLeft">
            <p style={ { color: '#e26362', fontStyle: 'italic' } }>{ this.state.acceptError }</p>

            <div className={ styles.CheckoutNote }>
              <Checkbox name="terms" label={ this.renderTermsLink() } inputProps={ { onChange: this.handleChange } } />

              { note }
            </div>
          </Cell>
          <Cell mods="u-size1of1 u-sm-size1of2 u-spaceTopMd u-sm-spaceTopNone u-textCenter u-sm-textRight">
            <Button
              isDisabled={ isSubmitting || isDisabled }
              color="primary"
              size="large"
              icon={ icon }
              label={ buttonLabel }
              onClick={ this.handleSubmit }
              type={ handleModalClick ? 'button' : 'submit' }
              otherProps={ handleModalClick ? {} : { form: 'checkoutForm' } }
            />
          </Cell>
        </Grid>
      </footer>
    );
  }
}

InvoiceFooter.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  handleModalClick: PropTypes.func,
  isSubmitting: PropTypes.bool,
  isDisabled: PropTypes.bool,
  note: PropTypes.string,
  icon: PropTypes.string,
};

InvoiceFooter.defaultProps = {
  handleModalClick: null,
  isSubmitting: false,
  isDisabled: false,
  note: null,
  icon: 'check',
};

export default InvoiceFooter;
