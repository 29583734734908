import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideModal } from 'state/modal/actions';
import { createInvoicePayment } from 'state/invoicePayments/actions';
import { queryInvoiceWithMemberEmailCollections } from 'state/invoices/actions';
import Modal from 'components/teamsnapUIExtensions/Modal';
import ApplyPaymentForm from 'components/baseComponents/ApplyPaymentForm';

class ModalApplyPayment extends Component {
  handleClose = () => {
    this.props.closeModal();
  };

  handleSubmit = (props) => {
    const { invoiceId, closeModal, createPayment, loadInvoice } = this.props;

    // TODO: Circle back and add Feedback after #436 is merged
    createPayment({ ...props, invoiceId })
      .then(() => loadInvoice(invoiceId))
      .finally(() => closeModal());
  };

  render() {
    const modalProps = {
      handleClose: this.handleClose,
      className: 'ModalSmall',
      ...this.props,
    };

    return (
      <Modal { ...modalProps } title="Record Payment">
        <ApplyPaymentForm onSubmit={ this.handleSubmit } handleClose={ this.handleClose } />
      </Modal>
    );
  }
}

ModalApplyPayment.propTypes = {
  closeModal: PropTypes.func.isRequired,
  invoiceId: PropTypes.string.isRequired,
  createPayment: PropTypes.func.isRequired,
  loadInvoice: PropTypes.func.isRequired,
};

export default connect(null, {
  closeModal: hideModal,
  createPayment: createInvoicePayment,
  loadInvoice: queryInvoiceWithMemberEmailCollections,
})(ModalApplyPayment);
