import React from 'react';
import PropTypes from 'prop-types';
// import { linkInvoicingNew, linkInvoicingEdit, linkInvoicingMembers, linkInvoicingSend } from 'utils/links'

import StepNav from 'components/teamsnapUIExtensions/StepNav';

const navSteps = (step, division, invoicing) => {
  // TODO: This is a *temporary* setup.  Form state should be handled in redux once
  // we know more about the process.  This current implementation is more so it
  // doesn't look too broken.

  // const navigation = {
  //   infoStep: { status: 'enabled', link: linkInvoicingEdit(division, invoicing) },
  //   memberStep: { status: 'enabled', link: linkInvoicingMembers(division, invoicing) },
  //   sendStep: {}
  // }

  // if (step === 'new') {
  //   navigation.infoStep = { status: 'active', link: linkInvoicingNew(division) }
  //   navigation.memberStep = {}
  // } else if (step === 'edit') {
  //   navigation.infoStep.status = 'active'
  //   // Should step be disabled on edit?
  // } else if (step === 'membersNew') {
  //   navigation.memberStep.status = 'active'
  // } else if (step === 'membersEdit') {
  //   navigation.memberStep.status = 'active'
  //   navigation.sendStep = { status: 'enabled', link: linkInvoicingSend(division, invoicing) }
  // } else if (step === 'send') {
  //   navigation.sendStep = { status: 'active', link: linkInvoicingSend(division, invoicing) }
  // }

  // TEMP: Remove links from navigation
  const navigation = {
    infoStep: { status: null },
    paymentStep: { status: null },
    memberStep: { status: null },
    sendStep: { status: null },
  };

  if (['new', 'edit'].includes(step)) {
    navigation.infoStep.status = 'active';
  } else if (['paymentStep'].includes(step)) {
    navigation.infoStep.status = 'enabled';
    navigation.paymentStep.status = 'active';
  } else if (['membersNew', 'membersEdit'].includes(step)) {
    navigation.infoStep.status = 'enabled';
    navigation.paymentStep.status = 'enabled';
    navigation.memberStep.status = 'active';
  } else if (step === 'send') {
    navigation.infoStep.status = 'enabled';
    navigation.paymentStep.status = 'enabled';
    navigation.memberStep.status = 'enabled';
    navigation.sendStep.status = 'active';
  }

  return navigation;
};

const InvoicingFormNav = ({ title, currentStep, groupId, groupType, invoicingId }) => {
  const { infoStep, paymentStep, memberStep, sendStep } = navSteps(currentStep, groupId, invoicingId);

  const iconStyle = groupType === 'teamId' ? { marginTop: '15px' } : {};

  const formSteps = [
    {
      name: 'Invoice Info',
      icon: 'registration',
      iconStyle,
      ...infoStep,
    },
    {
      name: 'Payment Info',
      icon: 'credit-card',
      iconStyle,
      ...paymentStep,
    },
    {
      name: 'Select Members',
      icon: 'roster',
      iconStyle,
      ...memberStep,
    },
    {
      name: 'Send Invoices',
      icon: 'send',
      iconStyle,
      ...sendStep,
    },
  ];

  return <StepNav steps={ formSteps } title={ title } titleIcon="piggybank" />;
};

InvoicingFormNav.propTypes = {
  title: PropTypes.string.isRequired,
  currentStep: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  invoicingId: PropTypes.string,
  groupType: PropTypes.string.isRequired,
};

InvoicingFormNav.defaultProps = {
  invoicingId: null,
};

export default InvoicingFormNav;
