/**
 * TeamNames Selector
 *
 * Various selectors using reselect for selecting data from state
 *
 */

import { createSelector } from 'reselect';
import _values from 'lodash/values';

const stateSelector = (state) => state.teamNames;
export const selectTeamNames = (state) => stateSelector(state).items || {};

export const selectTeamNameIsFetching = (state) => stateSelector(state).isFetching;

export const selectTeamNameValues = (state) => _values(selectTeamNames(state));

export const selectTeamNamesForDropdown = createSelector(selectTeamNameValues, (teamNames) =>
  teamNames.map((team) => ({ title: team.name, value: team.id })),
);
