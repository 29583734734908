/**
 * Invoice Message Recipients
 *
 * Actions for storing and retreiving values from a transient list of recipient ids created during
 * the batch / invoice creation flow.
 *
 */

export const SAVE_INVOICE_MESSAGE_RECIPIENTS = 'SAVE_INVOICE_MESSAGE_RECIPIENTS';

export const saveInvoiceMessageRecipients = (ids) => ({
  type: SAVE_INVOICE_MESSAGE_RECIPIENTS,
  payload: {
    recipientIds: ids,
  },
});
