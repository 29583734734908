import { FETCH_CAN_ACCEPT_PAYMENT, SAVE_CAN_ACCEPT_PAYMENT, ERROR_CAN_ACCEPT_PAYMENT } from './actions';

export const initialState = {
  isFetching: false,
  isError: false,
  data: null,
  status: null
};

export function canAcceptPaymentReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CAN_ACCEPT_PAYMENT:
      return {
        ...state,
        isFetching: true,
        isError: false,
        status: 'loading',
      };

    case SAVE_CAN_ACCEPT_PAYMENT:
      return {
        ...state,
        isFetching: false,
        isError: false,
        status: 'success',
        data: action.payload
      };

    case ERROR_CAN_ACCEPT_PAYMENT:
      return {
        ...state,
        isFetching: false,
        isError: true,
        status: 'error',
      };

    default:
      return state;
  }
}

