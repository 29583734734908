import { requestParams } from 'services/api';
import { paymentsRequest } from 'services/paymentsApi';

export const CREATE_DEPOSIT_ACCOUNT = 'CREATE_DEPOSIT_ACCOUNT';
export const ERROR_CREATE_DEPOSIT_ACCOUNT = 'ERROR_CREATE_DEPOSIT_ACCOUNT';
export const FETCH_DEPOSIT_ACCOUNT = 'FETCH_DEPOSIT_ACCOUNT';
export const SAVE_DEPOSIT_ACCOUNT = 'SAVE_DEPOSIT_ACCOUNT';
export const ERROR_DEPOSIT_ACCOUNT = 'ERROR_DEPOSIT_ACCOUNT';

export const getDepositAccount = (teamId) => async (dispatch) => {
  dispatch({ type: FETCH_DEPOSIT_ACCOUNT });

  await paymentsRequest(
    requestParams('get', '/v2/payment_accounts', { scopeId: teamId, scopeType: 'team' }),
  ).then((response) => {
    dispatch({ type: SAVE_DEPOSIT_ACCOUNT, payload: response, });
  }).catch(() => {
    dispatch({ type: ERROR_DEPOSIT_ACCOUNT });
  });
  return;
};

export const createDepositAccount = (teamId) => async (dispatch) => {
  dispatch({ type: CREATE_DEPOSIT_ACCOUNT });
  const params =
  {
    'scope_id': teamId,
    'scope_type': 'team',
    'nickname': 'Team deposit account'
  };
  return await paymentsRequest({ method: 'post', url: '/v2/payment_accounts', data: { ...params }, config: {  } })
    .then((response) => {
      dispatch({ type: SAVE_DEPOSIT_ACCOUNT, payload: response });
      return response.id;
    }).catch(() => {
      dispatch({ type: ERROR_DEPOSIT_ACCOUNT });
    });
};
