import * as actions from './actions';

const initialState = {
  path: null,
  params: {},
};

const routerLocationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SAVE_LOCATION:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default routerLocationReducer;
