import { requestParams } from 'services/api';
import { paymentsRequest } from 'services/paymentsApi';

export const FETCH_CAN_UPDATE = 'FETCH_CAN_UPDATE';
export const SAVE_CAN_UPDATE = 'SAVE_CAN_UPDATE';
export const ERROR_CAN_UPDATE = 'ERROR_CAN_UPDATE';

export const fetchCanUpdate = (divisionId) => async (dispatch) => {
  dispatch({ type: FETCH_CAN_UPDATE });
  const response = await paymentsRequest(
    requestParams('get', '/payment_accounts/can_update', { division_id: divisionId }),
  );
  const error = response && response.error;
  if (error) {
    dispatch({ type: ERROR_CAN_UPDATE });
    return error;
  } else {
    return dispatch({
      type: SAVE_CAN_UPDATE,
      payload: response,
    });
  }
};
