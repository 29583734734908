import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import Root from 'components/baseComponents/Root';
import ModalRootContainer from 'components/baseComponents/ModalRoot/ModalRootContainer';
import FeedbackRootContainer from 'components/baseComponents/FeedbackRoot/FeedbackRootContainer';
import LeaguePayments from 'components/baseComponents/LeaguePayments';
import TeamPayments from 'components/baseComponents/TeamPayments';
import PayInvoice from 'components/screens/PayInvoice';
import 'styles/main.module.scss';
import styles from './App.module.scss';

import '@teamsnap/snap-ui/build/snap-ui.min.css';

class App extends Component {
  render() {
    return (
      <div className={ styles.AppContainer }>
        <FeedbackRootContainer />
        <main>
          <Route path="/" component={ Root } exact />
          <Route path="/pay_invoice/:uuid" component={ PayInvoice } />
          <Route path="/:teamId/team_payments" component={ TeamPayments } />
          <Route path="/:divisionId/league_payments" component={ LeaguePayments } />
        </main>
        <ModalRootContainer />
      </div>
    );
  }
}

export default App;
