import { SET_WEPAY_STATUS, SAVE_APP_SETTINGS } from './actions';

const initialState = {
  isWePayReady: false,
  apiv3Url: null,
  authToken: null,
  userId: null,
  isAdmin: null,
  memberId: null,
  rollouts: {},
};

function appReducer(state = initialState, action) {
  switch (action.type) {
    case SET_WEPAY_STATUS:
      return { ...state, isWePayReady: action.payload };

    case SAVE_APP_SETTINGS:
      return { ...state, ...action.payload };

    default:
      return state;
  }
}

export default appReducer;
