import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CardElement, injectStripe } from 'react-stripe-elements';
import CheckoutHeader from './CheckoutHeader';
import CheckoutMessage from './CheckoutMessage';
import { isValidEmail, isValidPayerName, isValidPayerNameLength } from 'utils/validator';
import { Input, FieldGroup, Grid, Cell } from '@teamsnap/teamsnap-ui';
import styles from './CheckoutForm.module.scss';
import { stripePropShape } from 'interfaces/financials';

class StripeCreditCard extends Component {
  state = {
    email: '',
    emailError: null,
    payerName: '',
    payerNameError: null,
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, [`${e.target.name}Error`]: null });
  };

  onTokenCreate = async (data) => {
    const payerName = this.state.payerName.trim();
    const { token, error } = await this.props.stripe.createToken({ ...data, name: payerName });

    this.props.saveCreditCardToken(
      {
        token: token && token.id,
        email: this.state.email.trim(),
      },
      error && error.message,
    );
  };

  onSubmit = (e, props = {}) => {
    e.preventDefault();

    const emailValidator = isValidEmail(this.state.email.trim());
    const payerNameValidator = isValidPayerName(this.state.payerName.trim());
    const payerNameLengthValidator = isValidPayerNameLength(this.state.payerName.trim());

    if (emailValidator.valid && payerNameValidator.valid && payerNameLengthValidator.valid) {
      this.props.createCreditCardToken(props, this.onTokenCreate);
    } else {
      this.setState({
        emailError: emailValidator.message,
        payerNameError: payerNameValidator.valid ? payerNameLengthValidator.message : payerNameValidator.message,
      });
    }
  };

  render() {
    return (
      <div style={ { marginBottom: '15px' } }>
        <form id="checkoutForm" onSubmit={ this.onSubmit }>
          <CheckoutHeader />

          <Grid isWithGutter>
            <Cell mods="u-md-size2of3">
              <FieldGroup mods={ this.state.payerNameError && 'is-notValid' }>
                <Input
                  name="payerName"
                  inputProps={ {
                    onChange: this.handleChange,
                    value: this.state.payerName,
                    placeholder: 'Cardholder Name',
                  } }
                />
                <CheckoutMessage text={ this.state.payerNameError } type="error" />
              </FieldGroup>
            </Cell>

            <Cell mods="u-md-size2of3">
              <FieldGroup mods={ this.state.emailError && 'is-notValid' }>
                <Input
                  name="email"
                  inputProps={ { onChange: this.handleChange, value: this.state.email, placeholder: 'Email Address' } }
                />
                <CheckoutMessage text={ this.state.emailError } type="error" />
              </FieldGroup>
            </Cell>

            <Cell mods="u-md-size2of3">
              <FieldGroup>
                <CardElement className={ [styles['InputGroup--stripe'], 'fs-block'].join(' ') } />
              </FieldGroup>
            </Cell>
          </Grid>

          <CheckoutMessage text={ this.props.submitError && 'The payment request did not succeed.' } type="error" />
        </form>
      </div>
    );
  }
}

StripeCreditCard.propTypes = {
  stripe: PropTypes.shape(stripePropShape).isRequired,
  createCreditCardToken: PropTypes.func.isRequired,
  saveCreditCardToken: PropTypes.func.isRequired,
  submitError: PropTypes.string,
};

StripeCreditCard.defaultProps = {
  submitError: null,
};

export default injectStripe(StripeCreditCard);
