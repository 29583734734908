/**
 * Base Actions
 *  - These are common/shared actions to help keep consistency throughout our state
 *
 * Action Type Overview
 *  - FETCH_* is used to set the 'fetching' state for a given resource
 *  - SAVE_* is used for to update the appropraite store
 *  - REMOVE_* is used for deleting or removing an item from the store
 *  - ERROR_* is used for any store updates that fail
 *
 * NOTE: Currently these collections are just returning the items via a promise from the API.
 *       We could return the entire response from the server, but it would result in some different responses
 *       related to returning state if available -vs- returning server responses.
 *
 * Example:
 *  import { getCollection } from 'state/baseActions'
 *  export const loadMembers = params => getCollection(MEMBER, 'members/search', params)
 *
 */

import _mapKeys from 'lodash/mapKeys';
import _groupBy from 'lodash/groupBy';
import { plissken } from 'utils/utils';
import api from 'services/api';

// Update Fetching state for a given action
export const fetchCollection = (type) => ({ type: `FETCH_${type}` });

// Update store Save for a given action
export const saveCollection = (type, items) => ({ type: `SAVE_${type}`, payload: items });
export const updateCollection = (type, items) => ({ type: `UPDATE_${type}`, payload: items });

// Update store error for a given action
export const errorCollection = (type) => ({ type: `ERROR_${type}` });

// Update store Removal for a given action
export const removeCollection = (type, item) => ({ type: `REMOVE_${type}`, payload: item });

// Reset collection to initialState
export const resetCollection = (actionName) => ({
  type: `RESET_${actionName}`,
});

// Get Collection by name, url and params
export const getCollection = (actionName, url, params, options = {}) => (dispatch, getState) => {
  const { replaceState, selectorCallback } = options;

  // Check if selector is passed in and check state before fetching action (should this check be elsewhere?).
  if (selectorCallback) {
    const collectionState = selectorCallback(getState());
    const isArray = Array.isArray(collectionState);

    // Check if array is not empty or value is 'truthy' and return selector items
    if ((isArray && collectionState.length) || (!isArray && collectionState)) {
      return Promise.resolve(collectionState);
    }
  }

  // Collection items did not exist in state, fetch from API
  dispatch(fetchCollection(actionName));

  return api
    .get(url, params)
    .then((response) => {
      const mappedKeys = _mapKeys(response.items, 'id');

      dispatch(replaceState ? updateCollection(actionName, mappedKeys) : saveCollection(actionName, mappedKeys));

      return Promise.resolve(response.items);
    })
    .catch((error) => {
      dispatch(errorCollection(actionName));
      return Promise.reject(error);
    });
};

/**
 * queryCollection expects both actionNames and params to be objects
 * The actionName keys will be used as the query 'type' in the request, it will also format
 * these items as camelCase for parsed collectionJSON response.
 *
 * For example:
 *  queryCollection({ BATCH_INVOICE, INVOICE }, { batchInvoice__id: 1, invoice__batchInvoiceId: 1})
 */
export const queryCollection = (actionNames, params) => (dispatch) => {
  const actions = Object.keys(actionNames).map((action) => {
    const name = actionNames[action];

    return {
      name: action,
      requestType: plissken(name, true),
      responseType: plissken(name),
    };
  });

  // Set fetching state for each query item.
  actions.map((action) => dispatch(fetchCollection(action.name)));

  // Stringify 'types' for query endpoint.
  const types = actions.map((action) => action.requestType).join(',');

  // Make API 'query' request and dispatch collections
  return api
    .get('q', { types, ...params })
    .then((response) => {
      const groupItems = _groupBy(response.items, (item) => plissken(item.type));
      actions.map((action) => dispatch(saveCollection(action.name, _mapKeys(groupItems[action.responseType], 'id'))));

      return Promise.resolve(groupItems);
    })
    .catch((error) => {
      actions.map((action) => dispatch(errorCollection(action.name)));

      return Promise.reject(error);
    });
};

// Create Collection by name, url and params
export const createCollection = (actionName, url, params, command = false) => (dispatch) => {
  dispatch(fetchCollection(actionName));
  const apiType = command ? 'cmd' : 'post';
  return api[apiType](url, params, command)
    .then((response) => {
      dispatch(saveCollection(actionName, _mapKeys(response.items, 'id')));
      return Promise.resolve(response.items);
    })
    .catch((error) => {
      dispatch(errorCollection(actionName));
      return Promise.reject(error);
    });
};

export const updateCollectionItem = (actionName, item) => ({
  type: `UPDATE_ITEM_${actionName}`,
  payload: item,
});

export const deleteCollection = (actionName, url, item) => (dispatch) =>
  api
    .delete(`${url}/${item.id}`)
    .then((_response) => {
      dispatch(removeCollection(actionName, item));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(errorCollection(actionName));
      return Promise.reject(error);
    });
