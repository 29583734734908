/**
 * Invoice Recipient Selector
 *
 * Various selectors using reselect for selecting data from state
 *
 */

import { createSelector } from 'reselect';
import _values from 'lodash/values';
import { selectInvoiceId, selectBatchInvoiceId } from 'state/routerLocation/selectors';
import { selectAndSortBy } from 'state/baseSelectors';

const stateSelector = (state) => state.invoiceRecipients;
const itemSelector = (state) => stateSelector(state).items;
const isFetchingSelector = (state) => stateSelector(state).isFetching;
const isErrorSelector = (state) => stateSelector(state).isError;

// Select invoice recipient Fetching or Error states
export const selectInvoiceRecipientIsFetching = (state) => isFetchingSelector(state);
export const selectInvoiceRecipientIsError = (state) => isErrorSelector(state);

// ReSelect - Select invoice recipient and return as array
export const selectInvoiceRecipients = createSelector(itemSelector, (invoiceRecipients) => _values(invoiceRecipients));

export const selectInvoiceRecipientByMemberType = createSelector(
  selectBatchInvoiceId,
  selectInvoiceRecipients,
  (batchInvoiceId, invoiceRecipients) =>
    invoiceRecipients &&
    invoiceRecipients.filter(
      (recipient) => recipient.batchInvoiceId === batchInvoiceId && recipient.invoiceableType === 'member',
    ),
);

export const selectInvoiceRecipentMemberIds = createSelector(
  // get all member type recipients by current batchId
  // we will eventually need to round this out when we message teams or
  // entire divisions.
  selectInvoiceRecipientByMemberType,
  (recipients) => recipients.map((r) => r.invoiceableId),
);

// ReSelect - Select invoice recipients by current invoice route
export const selectInvoiceRecipientsByInvoiceRoute = createSelector(
  selectInvoiceId,
  selectInvoiceRecipients,
  (invoiceId, invoiceRecipients) =>
    invoiceRecipients && invoiceRecipients.filter((invoiceRecipient) => invoiceRecipient.invoiceId === invoiceId),
);

// Sort all the invoice recipients by invoice id
export const selectAndSortInvoiceRecipientsByInvoiceId = createSelector(selectInvoiceRecipients, (invoiceRecipients) =>
  selectAndSortBy(invoiceRecipients, 'invoiceId'),
);

// return an array of invoice recipients given an invoice id, may be an empty array
export const selectInvoiceRecipientsByInvoiceId = (state) => (invoiceId) =>
  selectAndSortInvoiceRecipientsByInvoiceId(state)[invoiceId] || [];
