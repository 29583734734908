import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const ModalRootContainer = ({ component, props }) => {
  if (!component) {
    return null;
  }
  const ModalComponent = component;

  return <ModalComponent { ...props } />;
};

ModalRootContainer.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  props: PropTypes.object,
};

ModalRootContainer.defaultProps = {
  component: null,
  props: {},
};

export default connect((state) => state.modal)(ModalRootContainer);
