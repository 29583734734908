import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppRoutes from 'components/baseComponents/AppRoutes';
import PaymentProviderSettings from 'components/screens/PaymentProviderSettings';
import Invoicing from 'components/screens/Invoicing';
import InvoicingNew from 'components/screens/InvoicingNew';
import InvoicingPaymentInfo from 'components/screens/InvoicingPaymentInfo';
import InvoicingMembers from 'components/screens/InvoicingMembers';
import InvoicingSend from 'components/screens/InvoicingSend';
import InvoicingEdit from 'components/screens/InvoicingEdit';
import InvoicingDetails from 'components/screens/InvoicingDetails';
import InvoiceSummary from 'components/screens/InvoiceSummary';
import { matchPropShape, locationPropShape } from 'interfaces/navigation';

const routes = [
  { path: '/payment_provider_settings', component: PaymentProviderSettings, exact: true },
  { path: '/invoicing', component: Invoicing, exact: true },
  { path: '/invoicing/new', component: InvoicingNew },
  { path: '/invoicing/:invoicingId/payment-info', component: InvoicingPaymentInfo },
  { path: '/invoicing/:invoicingId/members', component: InvoicingMembers },
  { path: '/invoicing/:invoicingId/send', component: InvoicingSend },
  { path: '/invoicing/:invoicingId/edit', component: InvoicingEdit },
  { path: '/invoicing/:invoicingId/invoices', component: InvoicingDetails, exact: true },
  { path: '/invoicing/:invoicingId/invoices/:invoiceId', component: InvoiceSummary },
];

class LeaguePayments extends Component {
  componentDidMount() {
    const {
      saveAppSettings,
      saveRouteLocation,
      loadDivisionTree,
      loadTeamNames,
      loadDivisionAggregates,
      loadAdvancedDivisionMembers,
      loadGroupPreferences,
      queryRegistrationFormsWithItemOptions,
      getPaymentAccount,
      location: { pathname },
      match: {
        params: { divisionId },
      },
    } = this.props;

    loadGroupPreferences({ divisionId });
    saveAppSettings();
    saveRouteLocation(pathname);
    loadDivisionTree({ id: divisionId });

    loadDivisionAggregates({ divisionId }).then((aggregates) => {
      const totalMembers =
        aggregates[0].selfAndDescendantsUnassignedMemberCount + aggregates[0].selfAndDescendantsAssignedMemberCount;

      // Only "pre-load" members if less than 100
      if (totalMembers <= 100) {
        loadAdvancedDivisionMembers({ divisionId, isNonPlayer: false });
      }
      getPaymentAccount({ divisionId });
    });

    loadTeamNames({ ancestorDivisionId: divisionId, is_active: true });
    queryRegistrationFormsWithItemOptions(divisionId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.saveRouteLocation(this.props.location.pathname);
    }
  }

  render() {
    return <AppRoutes routes={ routes } rootPath={ this.props.match.path } />;
  }
}

LeaguePayments.propTypes = {
  saveAppSettings: PropTypes.func.isRequired,
  loadGroupPreferences: PropTypes.func.isRequired,
  saveRouteLocation: PropTypes.func.isRequired,
  loadDivisionTree: PropTypes.func.isRequired,
  loadTeamNames: PropTypes.func.isRequired,
  loadDivisionAggregates: PropTypes.func.isRequired,
  loadAdvancedDivisionMembers: PropTypes.func.isRequired,
  queryRegistrationFormsWithItemOptions: PropTypes.func.isRequired,
  match: PropTypes.shape(matchPropShape).isRequired,
  location: PropTypes.shape(locationPropShape).isRequired,
  getPaymentAccount: PropTypes.func.isRequired,
};

export default LeaguePayments;
