/**
 * Checkbox Component
 *
 * This is a 'helper' component that calls InputControl with the appropriate
 * default props for a 'checkbox'
 *
 */

import React from 'react';
import InputControl from './InputControl';

const Checkbox = (props) => <InputControl type="checkbox" { ...props } />;

export default Checkbox;
