import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { linkPaymentSettings } from 'utils/links';
import { Loader } from 'components/teamsnapUIExtensions';
import { getGroupId } from 'utils/group';
import { matchPropShape } from 'interfaces/navigation';

class PaymentProviderSettings extends Component {
  componentDidMount() {
    const { params } = this.props.match;
    window.location = linkPaymentSettings(getGroupId(params));
  }

  render() {
    return <Loader message="random" />;
  }
}

PaymentProviderSettings.propTypes = {
  match: PropTypes.shape(matchPropShape).isRequired,
};

export default PaymentProviderSettings;
