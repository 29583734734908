import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Elements, StripeProvider } from 'react-stripe-elements';
import StripeCreditCard from './StripeCreditCard';
import { currencyPropShape } from 'interfaces/financials';

class StripeForm extends Component {
  render() {
    const { saveCreditCardToken, createCreditCardToken, submitError, currency } = this.props;
    return (
      <div>
        <StripeProvider
          key={ process.env.REACT_APP_INVOICING_STRIPE_PUBLIC_KEY }
          apiKey={ process.env.REACT_APP_INVOICING_STRIPE_PUBLIC_KEY }
        >
          <Elements>
            <StripeCreditCard
              saveCreditCardToken={ saveCreditCardToken }
              createCreditCardToken={ createCreditCardToken }
              submitError={ submitError }
              currency={ currency }
            />
          </Elements>
        </StripeProvider>
      </div>
    );
  }
}

StripeForm.propTypes = {
  createCreditCardToken: PropTypes.func.isRequired,
  saveCreditCardToken: PropTypes.func.isRequired,
  currency: PropTypes.shape(currencyPropShape).isRequired,
  submitError: PropTypes.string,
};

StripeForm.defaultProps = {
  submitError: null,
};

export default StripeForm;
