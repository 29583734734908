import React, { Component } from 'react';
import PropTypes from 'prop-types';
import WepayForm from './WepayForm';
import StripeForm from './StripeForm';
import { currencyPropShape } from 'interfaces/financials';

class CheckoutForm extends Component {
  render() {
    const { activeProvider, saveCreditCardToken, createCreditCardToken, currency, submitError } = this.props;
    const providerProps = { saveCreditCardToken, createCreditCardToken, currency, submitError };

    return (
      <>
        { activeProvider === 'stripe' && <StripeForm { ...providerProps } /> }
        { activeProvider === 'wepay' && <WepayForm { ...providerProps } /> }
      </>
    );
  }
}

CheckoutForm.propTypes = {
  activeProvider: PropTypes.string.isRequired,
  createCreditCardToken: PropTypes.func.isRequired,
  saveCreditCardToken: PropTypes.func.isRequired,
  currency: PropTypes.shape(currencyPropShape).isRequired,
  submitError: PropTypes.string,
};

CheckoutForm.defaultProps = {
  submitError: null,
};

export default CheckoutForm;
