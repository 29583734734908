import { FETCH_DEPOSIT_ACCOUNT, SAVE_DEPOSIT_ACCOUNT, ERROR_DEPOSIT_ACCOUNT, CREATE_DEPOSIT_ACCOUNT } from './actions';

export const initialState = {
  isFetching: false,
  isLoaded: false,
  isError: false,
  account: null,
  isCreating: false,
};

const depositAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_DEPOSIT_ACCOUNT:
      return {
        isCreating: true,
        isError: false,
        isLoaded: state.isLoaded,
        account: null,
      };
    case FETCH_DEPOSIT_ACCOUNT:
      return {
        isFetching: true,
        isError: false,
        isLoaded: false,
        account: null,
      };
    case SAVE_DEPOSIT_ACCOUNT:
      return {
        isFetching: false,
        isLoaded: true,
        isError: false,
        account: action.payload,
      };
    case ERROR_DEPOSIT_ACCOUNT:
      return {
        isFetching: false,
        isLoaded: false,
        isError: true,
        account: null,
      };
    default:
      return state;
  }
};

export default depositAccountReducer;
