import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { linkFinancialReports, linkInvoicing, linkInvoicingAccount, linkInvoicingNew } from 'utils/links';
import { Button, ButtonGroup } from '@teamsnap/teamsnap-ui';
import { Loader } from 'components/teamsnapUIExtensions';
import { getDepositAccount } from 'state/depositAccount/actions';
import { ERROR_DEPOSIT_ACCOUNT_ONBOARDING, createDepositOnboardingLink } from 'state/depositAccountOnboarding/actions';
import { selectDepositAccountIsFetching, selectDepositAccountLoaded, selectDepositAccount, selectDepositAccountIsCreating, selectDepositAccountIsError } from 'state/depositAccount/selectors';
import { selectDepositAccountOnboardingIsError, selectDepositAccountOnboardingIsFetching } from 'state/depositAccountOnboarding/selectors';
import { userIsTeamOwner } from 'utils/window';
import AccountCard from 'components/baseComponents/AccountCard';
import AccountSetupCard from 'components/baseComponents/AccountSetupCard';
import { Banner } from '@teamsnap/snap-ui';
import { shouldShowManageAccountButton, shouldShowTeamBanner } from 'utils/teamAccount';

const _useAccount = (teamId) => {
  // any logic that is not directly related to the UI should be moved to the hook
  const dispatch = useDispatch();
  const isFetching = useSelector(selectDepositAccountIsFetching);
  const account = useSelector(selectDepositAccount);
  const isLoaded = useSelector(selectDepositAccountLoaded);
  const isError = useSelector(selectDepositAccountIsError);
  const isCreating = useSelector(selectDepositAccountIsCreating);
  const isLoadingOnboarding = useSelector(selectDepositAccountOnboardingIsFetching);
  const isErrorOnboarding = useSelector(selectDepositAccountOnboardingIsError);

  async function launchOnboarding() {
    const onboardingUrl = await dispatch(createDepositOnboardingLink(teamId, `${window.location.origin}${linkInvoicing(teamId)}`));
    
    if (onboardingUrl) {
      window.open(onboardingUrl, '_blank');
    } else {
      dispatch({ type: ERROR_DEPOSIT_ACCOUNT_ONBOARDING });
    }
  }

  React.useEffect(() => {
    if (!isLoaded) {
      dispatch(getDepositAccount(teamId));
    }
  }, []);

  // we just return all the values and callbacks we want to use in the UI
  return { 
    teamId, isFetching, isCreating, account, isLoaded, 
    isError, isTeamOwner: userIsTeamOwner, isLoadingOnboarding, isErrorOnboarding, launchOnboarding };
};


const Account = ({ match, useAccount = _useAccount }) => {
  // here we are using the default implementation of useAccount, we would only pass in a custom implementation if we were testing
  const { 
    teamId,
    isFetching, 
    isCreating, 
    account, 
    isLoaded, 
    isError, 
    isTeamOwner, 
    isLoadingOnboarding, 
    isErrorOnboarding, 
    launchOnboarding 
  } = useAccount(match?.params?.teamId);

  function getBannerText(status) {
    if (status === 'information_required' || status === 'restricted_soon' || status === 'rejected') {
      let textCopy = 'Please provide required details to enable payment processing.';
      if (status === 'rejected') textCopy += ' If you believe this account was rejected in error, please reach out to Payments@TeamSnap.com for support.';
      return textCopy;
    }
    if (status === 'under_review') return 'Wait for account verification.';
  }

  function canSendInvoice() {
    return account && (account.status === 'approved' || account.status === 'restricted_soon');
  }

  function onSendNewInvoiceClick() {
    window.location.assign(linkInvoicingNew(teamId));
  }

  return (
    <div>
      <div className='sui-mb-3 sui-mt-3 sui-flex'>
        <h1 data-testid="page-heading-account">Team Financials</h1>
        { isLoaded && account && 
          <div className='sui-contents' data-testid='invoicing-nav-buttons'>
            <ButtonGroup buttons={
              [
                {
                  label: 'Deposit Account',
                  icon: account && account?.status === 'approved' ? 'check' : 'alert',
                  isDisabled: false,
                  isActive: true,
                  onClick: () => window.location.assign(linkInvoicingAccount(teamId))
                },
                {
                  label: 'Invoices',
                  type: 'button',
                  icon: 'credit-card',
                  isDisabled: !account,
                  isActive: false,
                  onClick: () => window.location.assign(linkInvoicing(teamId))
                },
                {
                  label: 'Financial Reporting',
                  icon: 'statistics',
                  isDisabled: account? (account?.status !== 'approved' && account?.status !== 'restricted_soon') : true,
                  isActive: false,
                  onClick: () => window.location.assign(linkFinancialReports(teamId))
                }
              ]
            } style={ { marginLeft: 'auto' } } mods="u-spaceRightSm"
            />
            <Button color="primary" icon="plus" onClick={ onSendNewInvoiceClick } isDisabled={ !canSendInvoice() }>
              Send New Invoice
            </Button>
          </div>
        }
      </div>
      { (isError || isErrorOnboarding) && 
        <Banner
          title='Managing deposit account is unavailable'
          caption={ 'An issue on our end is preventing access to manage deposit account. Please try again later. If you continue to see this error please contact our support team.' }
          sentiment="negative"
        />
      }
      { isLoaded && shouldShowTeamBanner(account) &&
        <div data-testid="account-status-banner"> 
          <Banner
            title='Deposit account pending verification'
            caption={ getBannerText(account.status) }
            sentiment='negative'
            action={ shouldShowManageAccountButton(account, isTeamOwner) && {
              label: null,
              onClick: launchOnboarding,
            } }
          />
        </div>
      }
      <div className='sui-mt-6'>
        { (isFetching || isCreating) && <Loader message="random" /> }
        { (isLoaded && !account && !isCreating) &&
          <AccountSetupCard 
            isTeamOwner={ isTeamOwner }
            onSetUpAccount={ launchOnboarding } 
            isLoading={ isCreating } 
          />
        }
        { account &&
          <AccountCard 
            type={ 'Deposit Account' }
            lastFour={ account.bankAccountNumberLast4 }
            name={ account.nickname }
            status={ account.status }
            isTeamOwner={ isTeamOwner }
            onManageAccount={ launchOnboarding }
            isLoading = { isLoadingOnboarding }
          />
        }
      </div>
    </div>
  );
};

export default Account;
