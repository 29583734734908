import _get from 'lodash/get';

// TODO:
//  - Add other comparison checks as needed, date, time or any custom sort values
const parseNumberString = (value) => parseFloat(value.replace(',', '')).toFixed(2);
const stripCurrency = (value) => parseNumberString(value.substr(1));

const compare = (property, reverse = false) => (a, b) => {
  // If not an object, assume sort field is a string value
  const aValue = typeof a === 'object' ? _get(a, property) : a;
  const bValue = typeof b === 'object' ? _get(b, property) : b;

  let alpha = null;
  let bravo = null;
  let result = null;

  // If value is an object assume React Element and look for data-sort prop?
  if (typeof aValue === 'object' || typeof bValue === 'object') {
    alpha = aValue.props['data-sort'];
    bravo = bValue.props['data-sort'];
    // Force numbers to string for equal comparisons against string numbers (currency)
  } else if (typeof aValue === 'number' || typeof bValue === 'number') {
    alpha = parseNumberString(aValue.toString());
    bravo = parseNumberString(bValue.toString());
  } else {
    alpha = aValue && aValue[0] === '$' ? stripCurrency(aValue) : aValue;
    bravo = bValue && bValue[0] === '$' ? stripCurrency(bValue) : bValue;
  }

  // Sorting Comparison
  //  - This checks for null values and sorts last
  //  - This checks if number and uses a natural sorting order
  //  - This checks values of strings and sorts appropriately
  if (!alpha) {
    result = 1;
  } else if (!bravo) {
    result = -1;
  } else if (!isNaN(alpha) && !isNaN(bravo)) {
    result = alpha - bravo;
  } else if (alpha.toLowerCase() < bravo.toLowerCase()) {
    result = -1;
  } else if (alpha.toLowerCase() > bravo.toLowerCase()) {
    result = 1;
  } else {
    result = 0;
  }

  return result * (reverse ? -1 : 1);
};

// Ran some performance test on different sorting algorithms including quickSort, mergeSort, localCompare and lodash.
// quickSort and mergeSort were quick, but I didn't feel like they were much quicker than using the built
// in sort with some custom compare logic.  Lodash was on averge twice if not more slow than the others and localCompare
// was a distance last. If sorting performance becomes an issue we can look at quick/merge sorting again to re-evaluate.
const sortBy = (property, array, reverse = false) => array.sort(compare(property, reverse));

export { sortBy as default, compare };
