import { connect } from 'react-redux';
import { selectInvoiceMessageRecipientIds } from 'state/invoiceMessageRecipients/selectors';
import { selectBatchInvoiceByBatchRoute, selectBatchInvoiceIsFetching } from 'state/batchInvoices/selectors';
import { createInvoiceMessage } from 'state/invoiceMessages/actions';
import { queryBatchInvoiceWithLineItems } from 'state/batchInvoices/actions';
import {
  selectGroupName,
  selectDateFormat,
  selectNeedsRefreshToShowFinancials,
  selectGroupPreferencesIsFetching,
} from 'state/group/selectors';
import {
  selectBatchInvoiceLineItemsByBatchRoute,
  selectBatchInvoiceLineItemIsFetching,
} from 'state/batchInvoiceLineItems/selectors';
import InvoicingSend from './InvoicingSend';
import { getGroupType } from 'utils/group';

const mapDispatchToProps = {
  queryBatchInvoiceWithLineItems,
  createInvoiceMessage,
};

const mapStateToProps = (state, { match: { params } }) => ({
  batchInvoiceId: params.invoicingId,
  batchInvoice: selectBatchInvoiceByBatchRoute(state) || {},
  dateFormat: selectDateFormat(state),
  batchInvoiceLineItems: selectBatchInvoiceLineItemsByBatchRoute(state) || [],
  isFetching:
    selectBatchInvoiceIsFetching(state) ||
    selectBatchInvoiceLineItemIsFetching(state) ||
    selectGroupPreferencesIsFetching(state),
  invoiceRecipientIds: selectInvoiceMessageRecipientIds(state) || [],
  needsRefresh: selectNeedsRefreshToShowFinancials(state),
  groupName: selectGroupName(state),
  groupType: getGroupType(params),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoicingSend);
