import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import gtmMiddleware from './googleTagManager';
import reducers from './reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [thunk, gtmMiddleware];

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_SHOW_REDUX_LOGGER === 'true') {
  const { createLogger } = require('redux-logger');

  const logger = createLogger({
    collapsed: true,
    duration: true,
  });

  middleware.push(logger);
}

// Setup Browser History
export const history = createBrowserHistory();

// Setup Redux Store
export const configureStore = (initialState = {}) => {
  const store = createStore(reducers, initialState, composeEnhancers(applyMiddleware(...middleware)));

  return store;
};
