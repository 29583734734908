import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Route } from 'react-router-dom';
import { configureStore, history } from 'state/store';
import * as serviceWorker from './serviceWorker';
import { setupExceptionHandler } from 'utils/notifier';
import App from 'components/App';

const initialState = {};
const store = configureStore(initialState);
setupExceptionHandler();

window.__app_root = document.getElementById('root');

ReactDOM.render(
  <Provider store={ store }>
    <Router history={ history }>
      <Route component={ App } />
    </Router>
  </Provider>,
  window.__app_root,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
