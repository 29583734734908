/**
 * SelectField Component Wrapper
 *
 * A 'Field' is used by reduxForms to wrap our internal toolkit components,
 * in this case Input. see http://redux-form.com/6.8.0/docs/api/Field.md/
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Select from 'components/teamsnapUIExtensions/Select';
import { inputOptionPropShape } from 'interfaces/fields';

const SelectField = (props) => <Field component={ Select } { ...props } />;

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape(inputOptionPropShape)),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  size: PropTypes.string,
  componentStyles: PropTypes.shape({}),
  className: PropTypes.string,
};

SelectField.defaultProps = {
  options: [],
  label: undefined,
  placeholder: undefined,
  disabled: false,
  required: false,
  size: undefined,
  componentStyles: {},
  className: 'Select',
};

export default SelectField;
