import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@teamsnap/teamsnap-ui';
import styles from 'components/teamsnapUIExtensions/Form/Field.module.scss';
import LineItem from './LineItem';
import invoiceCategories from './lineItemCategories';

const invoiceOptions = () =>
  Object.keys(invoiceCategories).map((categoryId) => ({
    value: String(categoryId),
    title: invoiceCategories[categoryId],
  }));

class InvoicingLineItems extends Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.initializeLineItem(this.props.fields);
  }

  handleItemRemove = (index) => this.props.fields.remove(index);

  initializeLineItem = (fields) => {
    let defaultField = {};

    if (this.props.disableLineItems) {
      defaultField = {
        invoiceCategoryId: 5,
        description: 'Team Fee',
      };
    }

    return fields.push(defaultField);
  };

  renderLineItems = (items) =>
    items.map((field, index) => (
      <LineItem
        key={ field }
        options={ invoiceOptions() }
        field={ field }
        index={ index }
        handleRemove={ this.handleItemRemove }
        disableLineItems={ this.props.disableLineItems }
      />
    ));

  render() {
    const {
      fields,
      meta: { submitFailed, error },
      disableLineItems,
    } = this.props;

    return (
      <div>
        { this.renderLineItems(fields) }
        { submitFailed && error && <p className={ styles.FieldError }>{ error }</p> }
        { !disableLineItems && (
          <Button color="primary" size="small" onClick={ () => fields.push({}) }>
            Add Fee
          </Button>
        ) }
      </div>
    );
  }
}

InvoicingLineItems.propTypes = {
  fields: PropTypes.shape({
    remove: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.object.isRequired,
  disableLineItems: PropTypes.bool.isRequired,
};

export default InvoicingLineItems;
