import { connect } from 'react-redux';
import { selectDateFormat, selectGroupPreferencesIsFetching, selectGroupType } from 'state/group/selectors';
import { selectBatchInvoiceByBatchRoute } from 'state/batchInvoices/selectors';
import { selectInvoiceByInvoiceRoute, selectInvoiceIsFetching } from 'state/invoices/selectors';
import {
  selectInvoiceLineItemsByInvoiceRoute,
  selectInvoiceLineItemIsFetching,
} from 'state/invoiceLineItems/selectors';
import { selectInvoicePaymentsAndTransactions, selectInvoicePaymentIsFetching } from 'state/invoicePayments/selectors';
import { selectInvoicePaymentTransactionIsFetching } from 'state/invoicePaymentTransactions/selectors';
import { selectInvoiceRecipientsByInvoiceRoute } from 'state/invoiceRecipients/selectors';
import { selectMemberEmailsByMemberId, selectMemberEmailIsFetching } from 'state/memberEmails/selectors';
import { cancelInvoice, queryInvoiceWithMemberEmailCollections } from 'state/invoices/actions';
import { showModal } from 'state/modal/actions';
import InvoiceSummary from './InvoiceSummary';

const isFetching = (state) =>
  selectInvoiceIsFetching(state) ||
  selectInvoiceLineItemIsFetching(state) ||
  selectInvoicePaymentIsFetching(state) ||
  selectInvoicePaymentTransactionIsFetching(state) ||
  selectMemberEmailIsFetching(state) ||
  selectGroupPreferencesIsFetching(state);

const selectMemberEmails = (state) => {
  // Return first recipient from invoiceRecipients
  const invoiceRecipient = selectInvoiceRecipientsByInvoiceRoute(state)[0] || {};

  if (invoiceRecipient && invoiceRecipient.invoiceableType === 'member') {
    return selectMemberEmailsByMemberId(state, invoiceRecipient.invoiceableId);
  }

  return [];
};

const mapDispatchToProps = {
  showModal,
  cancelInvoice,
  queryInvoiceWithMemberEmailCollections,
};

const mapStateToProps = (state, ownProps) => ({
  batchInvoiceId: ownProps.match.params.invoicingId,
  invoiceId: ownProps.match.params.invoiceId,
  batchInvoice: selectBatchInvoiceByBatchRoute(state) || {},
  invoice: selectInvoiceByInvoiceRoute(state) || {},
  invoiceLineItems: selectInvoiceLineItemsByInvoiceRoute(state) || [],
  invoicePayments: selectInvoicePaymentsAndTransactions(state) || [],
  memberEmails: selectMemberEmails(state),
  isFetching: isFetching(state),
  dateFormat: selectDateFormat(state),
  invoiceRecipient: selectInvoiceRecipientsByInvoiceRoute(state)[0] || {},
  groupType: selectGroupType(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceSummary);
