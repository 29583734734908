/**
 * Invoice Actions
 *
 * Various actions using redux-thunk and axios for managing communication with API and redux store
 *
 */

import api from 'services/api';
import { queryCollection, getCollection, createCollection, deleteCollection } from 'state/baseActions';
import { BATCH_INVOICE, loadBatchInvoices } from 'state/batchInvoices/actions';
import { INVOICE_RECIPIENT, removeInvoiceRecipientsByInvoiceId } from 'state/invoiceRecipients/actions';
import { INVOICE_LINE_ITEM, removeInvoiceLineItemsByInvoiceId } from 'state/invoiceLineItems/actions';
import { INVOICE_PAYMENT } from 'state/invoicePayments/actions';
import { INVOICE_PAYMENT_TRANSACTION } from 'state/invoicePaymentTransactions/actions';
import { loadMemberEmailsFromInvoiceRecipients } from 'state/memberEmails/actions';
import { selectInvoiceById } from 'state/invoices/selectors';

export const INVOICE = 'INVOICE';

// Load all invoices by divsionId or  batchInvoiceID
export const loadInvoices = (params) => (dispatch) => dispatch(getCollection(INVOICE, 'invoices/search', params));

// Load a single invoices by ID - no selector caching
export const loadInvoice = (params) => (dispatch) => dispatch(getCollection(INVOICE, 'invoices/search', params));

// Use custom apiv3 cmd for simple invoice endpoint
export const createInvoice = (invoice) => (dispatch) =>
  dispatch(createCollection(INVOICE, 'invoices/create_from_batch_invoice', invoice, true));

const invoiceCollection = {
  INVOICE,
  INVOICE_LINE_ITEM,
  INVOICE_PAYMENT,
  INVOICE_PAYMENT_TRANSACTION,
  INVOICE_RECIPIENT,
};

const invoiceQuery = (invoiceId) => ({
  invoice__id: invoiceId,
  invoiceLineItem__invoiceId: invoiceId,
  invoicePayment__invoiceId: invoiceId,
  invoicePaymentTransaction__invoiceId: invoiceId,
  invoiceRecipient__invoiceId: invoiceId,
});

export const queryInvoiceCollections = (invoiceId) => (dispatch) =>
  dispatch(queryCollection(invoiceCollection, invoiceQuery(invoiceId)));

export const queryInvoiceWithBatchCollections = (invoiceId, batchInvoiceId) => (dispatch) =>
  dispatch(
    queryCollection(
      { BATCH_INVOICE, ...invoiceCollection },
      { batchInvoice__id: batchInvoiceId, ...invoiceQuery(invoiceId) },
    ),
  );

export const queryInvoiceWithMemberEmailCollections = (invoiceId) => (dispatch) =>
  dispatch(queryInvoiceCollections(invoiceId)).then((response) =>
    dispatch(loadMemberEmailsFromInvoiceRecipients(response.invoiceRecipient)),
  );

export const cancelInvoice = ({ id, batchInvoiceId }) => (dispatch) =>
  api
    .cmd('invoices/cancel', { id })
    .then(() => dispatch(queryInvoiceWithBatchCollections(id, batchInvoiceId)))
    .catch((error) => Promise.reject(error));

// Query Collection for Invoices and InvoiceRecipients by BatchInvoiceId
export const queryInvoicesAndRecipients = (batchInvoiceId) => (dispatch) =>
  dispatch(
    queryCollection(
      { INVOICE, INVOICE_RECIPIENT },
      {
        invoice__batchInvoiceId: batchInvoiceId,
        invoiceRecipient__batchInvoiceId: batchInvoiceId,
      },
    ),
  );

export const deleteInvoice = ({ id }) => (dispatch, getState) => {
  const item = selectInvoiceById(getState(), id);
  return dispatch(deleteCollection(INVOICE, 'invoices', item)).then(
    Promise.all([dispatch(removeInvoiceLineItemsByInvoiceId(id)), dispatch(removeInvoiceRecipientsByInvoiceId(id))]),
  );
};

export const deleteInvoiceAndReloadBatchInvoice = ({ id }) => (dispatch, getState) => {
  const item = selectInvoiceById(getState(), id);
  return dispatch(deleteInvoice({ id })).then(() => dispatch(loadBatchInvoices({ id: item.batchInvoiceId })));
};
