/**
 * Invoice Selector
 *
 * Various selectors using reselect for selecting data from state
 *
 */

import { createSelector } from 'reselect';
import _values from 'lodash/values';
import { selectBatchInvoiceId, selectInvoiceId } from 'state/routerLocation/selectors';
import { selectInvoiceRecipientByMemberType } from 'state/invoiceRecipients/selectors';

const stateSelector = (state) => state.invoices;
const itemSelector = (state) => stateSelector(state).items;
const isFetchingSelector = (state) => stateSelector(state).isFetching;
const isErrorSelector = (state) => stateSelector(state).isError;

// Select invoice Fetching or Error states
export const selectInvoiceIsFetching = (state) => isFetchingSelector(state);
export const selectInvoiceIsError = (state) => isErrorSelector(state);

// Select batch Invoice by id
export const selectInvoiceById = (state, id) => itemSelector(state)[id];

// ReSelect - Select all invoices and return as array
export const selectInvoices = createSelector(itemSelector, (invoices) => _values(invoices));

// ReSelect - Select all invoices filtered by current batchInvoice route
export const selectInvoicesByBatchRoute = createSelector(
  selectBatchInvoiceId,
  selectInvoices,
  (batchInvoiceId, invoices) => invoices && invoices.filter((invoice) => invoice.batchInvoiceId === batchInvoiceId),
);

// ReSelect - Select all invoices and recipient by current batchInvoice route
export const selectInvoicesAndRecipientsByBatchRoute = createSelector(
  selectInvoicesByBatchRoute,
  selectInvoiceRecipientByMemberType,
  (invoices, recipients) =>
    invoices &&
    recipients &&
    invoices.map((invoice) => {
      const recipient = recipients.find((r) => r.invoiceId === invoice.id);
      return {
        ...invoice,
        recipientId: recipient ? recipient.invoiceableId : '',
      };
    }),
);

// ReSelect - Select a single invoice by current invoice route
export const selectInvoiceByInvoiceRoute = createSelector(
  selectInvoiceId,
  itemSelector,
  (invoiceId, invoices) => invoices && invoices[invoiceId],
);
