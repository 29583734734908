import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'components/teamsnapUIExtensions';
import './DatePicker.module.scss';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'utils/date-service';

class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.parseDate = this.parseDate.bind(this);
  }

  parseDate(date) {
    // only take valid dates in the format
    if (this.props.dateFormat === 'MM/DD/YYYY' || this.props.dateFormat === 'MM-DD-YYYY') {
      const m = date.match(/^(\d{1,2})[/-](\d{1,2})[/-](\d{4})$/);
      return (m) ? parseDate(date) : null;
    } else {
      return parseDate(date);
    }
  }

  render() {
    const {
      dateFormat,
      placeholder,
      disabled,
      className,
      required,
      label,
      note,
      readOnly,
      input: { name, value, onBlur, onChange },
      meta: { touched, error },
    } = this.props;

    const inputProps = {
      placeholder,
      name,
      disabled,
      required,
      className: 'Input',
      ...(readOnly && { readOnly: true }),
    };

    const dayPickerProps = {
      modifiersStyles: {
        today: {
          color: '#1A6BAF',
        },
      },
      showOutsideDays: true,
    };


    return (
      <Field className={ 'Field' } name={ name } label={ label } note={ note } meta={ { touched, error } }>
        <DayPickerInput
          className={ className }
          inputProps={ inputProps }
          value={ value }
          format={ dateFormat }
          formatDate={ formatDate }
          parseDate={ this.parseDate }
          onDayChange={ onChange }
          onDayPickerHide={ onBlur }
          dayPickerProps={ dayPickerProps }
          style={ { borderRadius: '15px' } }
        />
      </Field>
    );
  }
}

DatePicker.propTypes = {
  dateFormat: PropTypes.string,
  placeholder: PropTypes.string,
  note: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  label: PropTypes.string.isRequired,
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
};

DatePicker.defaultProps = {
  note: null,
  dateFormat: 'MM/DD/YYYY',
  placeholder: 'MM/DD/YYYY',
  disabled: false,
  required: false,
  className: null,
  readOnly: false,
};

export default DatePicker;
