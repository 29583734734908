import PropTypes from 'prop-types';

import { Button } from '@teamsnap/teamsnap-ui';
import InputField from 'components/baseComponents/Fields/InputField';
import Form, { FormGroup } from 'components/teamsnapUIExtensions/Form';
import { reduxForm } from 'redux-form';
import styles from './ApplyAdjustmentForm.module.scss';
import { validate } from './validate';

const ApplyAdjustmentForm = ({ handleSubmit, handleClose }) => (
  <Form handleSubmit={ handleSubmit }>
    <div className={ styles.ModalBody }>

      <FormGroup>
        <InputField
          name="amount"
          type="text"
          label="Reduction Amount"
          note="Balances can only be reduced"
          placeholder="0.00"
          icon="$"
        />
      </FormGroup>

      <FormGroup>
        <InputField name="description" type="text" label="Description" placeholder="Description" />
      </FormGroup>
    </div>
    <div className={ styles.ModalFooter }>
      <Button
        type="button"
        onClick={ () => handleClose() }
        icon="dismiss"
        color="negative"
        style={ { marginRight: '5px' } }
      >
        Cancel
      </Button>

      <Button color="primary" type="submit" icon="check">
        Save Adjustment
      </Button>
    </div>
  </Form>
);

ApplyAdjustmentForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'applyAdjustment',
  validate,
})(ApplyAdjustmentForm);
