/**
 * Batch Invoice Line Item Selector
 *
 * Various selectors using reselect for selecting data from state
 *
 */

import { createSelector } from 'reselect';
import _values from 'lodash/values';
import { selectBatchInvoiceId } from 'state/routerLocation/selectors';
import { selectAndSortBy } from 'state/baseSelectors';

const stateSelector = (state) => state.batchInvoiceLineItems;
const itemSelector = (state) => stateSelector(state).items;
const isFetchingSelector = (state) => stateSelector(state).isFetching;
const isErrorSelector = (state) => stateSelector(state).isError;

// Select batchInvoice Fetching or Error state
export const selectBatchInvoiceLineItemIsFetching = (state) => isFetchingSelector(state);
export const selectBatchInvoiceLineItemIsError = (state) => isErrorSelector(state);

// ReSelect - Select all batch invoice line items
export const selectBatchInvoiceLineItems = createSelector(itemSelector, (batchInvoiceLineItems) =>
  _values(batchInvoiceLineItems),
);

// ReSelect - Select all batch invoice line items filtered by current batch invoice route
export const selectBatchInvoiceLineItemsByBatchRoute = createSelector(
  selectBatchInvoiceId,
  selectBatchInvoiceLineItems,
  (batchInvoiceId, batchInvoiceLineItems) =>
    batchInvoiceLineItems && batchInvoiceLineItems.filter((item) => item.batchInvoiceId === batchInvoiceId),
);

// Sort all the batch invoice line items by batch invoice id
export const selectAndSortBatchInvoiceLineItemsByBatchInvoiceId = createSelector(
  selectBatchInvoiceLineItems,
  (batchInvoiceLineItems) => selectAndSortBy(batchInvoiceLineItems, 'batchInvoiceId'),
);

// return an array of batch invoice line items given an batch invoice id, may be an empty array
export const selectBatchInvoiceLineItemsByBatchInvoiceId = (state) => (batchInvoiceId) =>
  selectAndSortBatchInvoiceLineItemsByBatchInvoiceId(state)[batchInvoiceId] || [];
