import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextLink, Button, Icon, Grid, Cell } from '@teamsnap/teamsnap-ui';
import DatePickerField from 'components/baseComponents/Fields/DatePickerField';
import styles from './InvoicingScheduledPayment.module.scss';

class InvoicingScheduledPayment extends Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    if (this.props.fields.length) return;
    this.initializeItem(this.props.fields);
  }

  initializeItem = (fields) => fields.push({});

  handlePaymentRemove = (e, index) => {
    e.preventDefault();
    this.props.fields.remove(index);
  };

  render() {
    const {
      fields,
      dateFormat,
      meta: { error, submitFailed },
    } = this.props;

    return (
      <div>
        { fields.map((field, index) => (
          <Grid isWithGutter mods="u-spaceBottomMd" key={ `${field}-${index + 1}` }>
            <Cell mods="u-size5of12">
              <span>{ `Installment ${index + 2}` }</span>
            </Cell>
            <Cell mods="u-size1of12" />
            <Cell mods="u-size5of12">
              <DatePickerField
                label=""
                name={ `${field}.paymentDate` }
                placeholder={ dateFormat }
                dateFormat={ dateFormat }
              />
            </Cell>

            { index > 0 ? (
              <Cell mods="u-size1of12 u-flex u-flexAlignItemsCenter">
                <TextLink onClick={ (e) => this.handlePaymentRemove(e, index) }>
                  <Icon name="dismiss" style={ { fontSize: '13px', color: '#e26362' } } />
                </TextLink>
              </Cell>
            ) : null }
          </Grid>
        )) }
        { submitFailed && error && <p className={ `${styles.FieldError} u-spaceBottomMd` }>{ error }</p> }

        <Button color="primary" size="small" onClick={ () => fields.push({}) } isDisabled={ fields.length >= 23 }>
          Add Installment
        </Button>
      </div>
    );
  }
}

InvoicingScheduledPayment.propTypes = {
  fields: PropTypes.shape({
    remove: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired,
    push: PropTypes.func.isRequired,
    map: PropTypes.func.isRequired,
  }).isRequired,
  dateFormat: PropTypes.string,
  meta: PropTypes.object.isRequired,
};

InvoicingScheduledPayment.defaultProps = {
  dateFormat: 'MM/DD/YYYY',
};

export default InvoicingScheduledPayment;
