/**
 * Division Aggregate Actions
 *
 * Various actions using redux-thunk and axios for managing communication with API and redux store
 *
 */

import { getCollection } from 'state/baseActions';

export const DIVISION_AGGREGATE = 'DIVISION_AGGREGATES';

export const loadDivisionAggregates = (params) => (dispatch) =>
  dispatch(getCollection(DIVISION_AGGREGATE, 'division_aggregates/search', params));
