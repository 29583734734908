import { requestParams } from 'services/api';
import { paymentsRequest } from 'services/paymentsApi';

export const FETCH_PAYMENT_ACCOUNT = 'FETCH_PAYMENT_ACCOUNT';
export const SAVE_PAYMENT_ACCOUNT = 'SAVE_PAYMENT_ACCOUNT';
export const ERROR_PAYMENT_ACCOUNT = 'ERROR_PAYMENT_ACCOUNT';

export const getPaymentAccount = (groupParams) => async (dispatch) => {
  dispatch({ type: FETCH_PAYMENT_ACCOUNT });
  const response = await paymentsRequest(
    requestParams('get', '/payment_accounts', { ...groupParams, is_active: true }),
  );
  const error = (response && response.error) || (response[0] && response[0].error);
  if (error) {
    dispatch({ type: ERROR_PAYMENT_ACCOUNT });
    return error;
  } else {
    return dispatch({
      type: SAVE_PAYMENT_ACCOUNT,
      payload: response[0],
    });
  }
};
