"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Stepper = void 0;
var React = __importStar(require("react"));
var teamsnap_ui_1 = require("@teamsnap/teamsnap-ui");
var PropTypes = __importStar(require("prop-types"));
var react_1 = require("react");
var validator_1 = require("./validator");
var _1 = require("./");
var elementsType = PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.oneOf([
            'checkbox',
            'currency',
            'date',
            'input',
            'radio',
            'select',
        ]).isRequired,
        name: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        id: PropTypes.number,
        selected: PropTypes.bool,
        isRequired: PropTypes.bool,
        isLocked: PropTypes.bool,
        onRequiredToggleClick: PropTypes.func,
        callback: PropTypes.func.isRequired,
        options: PropTypes.array
    })),
    PropTypes.element
]);
var propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    drawer: PropTypes.node,
    customHeader: PropTypes.node,
    summaryFirst: PropTypes.bool,
    onSubmit: PropTypes.func,
    numbered: PropTypes.bool,
    labeledButtons: PropTypes.bool,
    showProgress: PropTypes.bool,
    steps: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        heading: PropTypes.string.isRequired,
        subheading: PropTypes.string,
        touched: PropTypes.bool.isRequired,
        submitCallback: PropTypes.func,
        preventNext: PropTypes.bool,
        disableNext: PropTypes.bool,
        groupElementsBy: PropTypes.number,
        multipleSelectionCallback: PropTypes.func,
        allSelected: PropTypes.bool,
        stepCallback: PropTypes.func,
        stepBackCallback: PropTypes.func,
        elements: elementsType.isRequired,
        customStepStyle: PropTypes.object,
        customNextButtonText: PropTypes.string,
        customBackButtonText: PropTypes.string,
        validation: PropTypes.objectOf(PropTypes.shape({
            value: PropTypes.any,
            error: PropTypes.string,
        })),
        hideIfBlank: PropTypes.bool,
        onStepFocus: PropTypes.func,
    })).isRequired,
    activeStep: PropTypes.number,
    excludeSummary: PropTypes.bool,
    displayBackOnFirst: PropTypes.bool,
    customStepStyles: PropTypes.object,
    currency: PropTypes.shape({
        name: PropTypes.string,
        symbol: PropTypes.string,
    }),
    handleUrl: PropTypes.func
};
var Stepper = function (_a) {
    var _b, _c, _d;
    var onClose = _a.onClose, show = _a.show, drawer = _a.drawer, summaryFirst = _a.summaryFirst, _e = _a.numbered, numbered = _e === void 0 ? false : _e, _f = _a.labeledButtons, labeledButtons = _f === void 0 ? false : _f, steps = _a.steps, _g = _a.showProgress, showProgress = _g === void 0 ? true : _g, customHeader = _a.customHeader, activeStep = _a.activeStep, _h = _a.excludeSummary, excludeSummary = _h === void 0 ? false : _h, _j = _a.displayBackOnFirst, displayBackOnFirst = _j === void 0 ? true : _j, customStepStyles = _a.customStepStyles, currency = _a.currency, handleUrl = _a.handleUrl;
    var _k = (0, react_1.useState)(0), step = _k[0], setStep = _k[1];
    var _l = (0, react_1.useState)([]), childElements = _l[0], setChildElements = _l[1];
    var _m = (0, react_1.useState)(0), saveAndExitFromStep = _m[0], setSaveAndExitFromStep = _m[1];
    var _o = (0, react_1.useState)(null), errors = _o[0], setErrors = _o[1];
    // Add 1 for the summary page
    var childrenCount = !excludeSummary ? steps.length + 1 : steps.length;
    var maxWidth = 620;
    (0, react_1.useEffect)(function () {
        setStep(function (prevStep) { return summaryFirst && prevStep === 0 ? -1 : prevStep; });
    }, [summaryFirst]);
    (0, react_1.useEffect)(function () {
        if (activeStep != null) {
            setStep(activeStep);
        }
    }, [activeStep]);
    (0, react_1.useEffect)(function () {
        var _a;
        var indexOfFirstSubmitScreen = steps.findIndex(function (step) { return step.submitCallback; });
        setSaveAndExitFromStep(indexOfFirstSubmitScreen);
        var stepStyle = !((_a = steps[step]) === null || _a === void 0 ? void 0 : _a.customStepStyle) ? {
            maxWidth: '620px',
            marginTop: '64px',
        } : steps[step].customStepStyle;
        var currentStep = step;
        var elements = steps.map(function (step, i) { return (React.createElement(_1.Step, { onStepFocus: step.onStepFocus, visible: currentStep === i, style: step.groupElementsBy > 0
                ? __assign(__assign({}, stepStyle), { display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }) : stepStyle },
            React.createElement("h2", { "data-testid": "stepper-heading-".concat(step.title.toLowerCase().replace(/\s/g, "-")), className: step.subheading ? 'u-spaceBottomXs' : 'u-spaceBottomLg' }, step.heading),
            step.subheading && (React.createElement("p", { className: "u-spaceBottomXl u-fontSizeLg" }, step.subheading)),
            step.multipleSelectionCallback && (React.createElement("button", { className: "u-spaceBottomSm u-textRight u-sizeFull u-colorPrimary u-padNone u-borderNone", style: { cursor: 'pointer', marginTop: '-16px', fontSize: '16px', backgroundColor: 'transparent' }, onClick: function (e) { return step.multipleSelectionCallback(e, step.allSelected); } }, step.allSelected ? 'Clear all' : 'Select all')),
            Array.isArray(step.elements) ?
                step.elements.map(function (el, idx) { return (React.createElement(_1.FormPill, { style: step.groupElementsBy > 0 ? { width: maxWidth / step.groupElementsBy - 10 + 'px' } : {}, key: idx, id: el.id || idx, type: el.type, name: el.name, text: el.text, currency: currency, onChange: function (event, data) {
                        el.callback(event, data);
                        var step = steps[currentStep];
                        validateStep(__assign(__assign({}, step), { value: event.target.value }));
                    }, options: el === null || el === void 0 ? void 0 : el.options, selected: el === null || el === void 0 ? void 0 : el.selected, isRequired: el === null || el === void 0 ? void 0 : el.isRequired, isLocked: el === null || el === void 0 ? void 0 : el.isLocked, errors: errors, onRequiredToggleClick: el === null || el === void 0 ? void 0 : el.onRequiredToggleClick })); })
                : step.elements)); });
        var fontSize = '18px';
        var editButtonStyle = {
            fontSize: fontSize,
            textDecoration: 'underline',
            position: 'relative',
            top: '-5px',
        };
        var formatCurrency = function (amount) { return (!amount ?
            "".concat((currency === null || currency === void 0 ? void 0 : currency.symbol) ? currency.symbol : '$', "0.00") :
            "".concat((currency === null || currency === void 0 ? void 0 : currency.symbol) ? currency.symbol : '$').concat(amount)); };
        var handleEdit = function (step) {
            if (handleUrl) {
                handleUrl(step);
            }
            setStep(step);
        };
        var summaryStep = (React.createElement(_1.Step, { visible: step === steps.length || step === -1, style: stepStyle },
            React.createElement("div", { className: "Summary" },
                React.createElement("h2", null, "Registration Summary")),
            steps.map(function (step, idx) {
                var _a;
                return (React.createElement(React.Fragment, { key: "steper-step-".concat(idx) }, ((step.hideIfBlank && step.value) || !step.hideIfBlank) &&
                    React.createElement("div", { className: "Summary-row", key: step.title + idx },
                        React.createElement("div", { className: "Summary-row-item u-size1of12 u-spaceLeftLg" },
                            React.createElement(teamsnap_ui_1.Icon, { name: "task-alt", style: {
                                    top: 0,
                                    fontSize: fontSize,
                                    color: steps[idx].touched ? '#199a50' : '#cbd0d6',
                                } })),
                        React.createElement("div", { className: "Summary-row-item u-size10of12" },
                            React.createElement("span", { className: "u-size1of1", style: { fontSize: fontSize } },
                                React.createElement("div", { className: "u-flex" },
                                    React.createElement("div", { className: "u-flex u-size7of24" },
                                        React.createElement("strong", null,
                                            step.title,
                                            ":")),
                                    React.createElement("div", { className: 'u-flex u-size17of24 u-padLeftSm' }, ((_a = step.elements[0]) === null || _a === void 0 ? void 0 : _a.type) === 'currency' ? formatCurrency(step.value) : step.value)))),
                        React.createElement("div", { className: "Summary-row-item u-size1of12 u-flexJustifyEnd" },
                            React.createElement(teamsnap_ui_1.Button, { type: "link", style: editButtonStyle, onClick: function () { return handleEdit(idx); } }, "edit")))));
            })));
        if (!excludeSummary) {
            elements.push(summaryStep);
        }
        setChildElements(elements);
    }, [steps, step, errors, excludeSummary, currency, handleUrl]);
    var progressPercent = function () { return ((step + 1) / childrenCount) * 100; };
    var displayStepButtonText = function (direction) {
        var prevIndex = step - 1;
        var nextIndex = step + 1;
        switch (direction) {
            case 'Back':
                return (prevIndex >= 0) ? steps[prevIndex].title : direction;
            case 'Next':
                if (nextIndex === childrenCount - 1) {
                    return 'Summary';
                }
                return (nextIndex < steps.length) ? steps[nextIndex].title : direction;
            default:
                return direction;
        }
    };
    var validateStep = function (step) {
        var errors = (0, validator_1.validate)(step);
        setErrors(errors);
        return errors;
    };
    var handleBackStep = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    if (!!errors) return [3 /*break*/, 5];
                    if (!((_a = steps[step]) === null || _a === void 0 ? void 0 : _a.submitCallback)) return [3 /*break*/, 2];
                    return [4 /*yield*/, ((_b = steps[step]) === null || _b === void 0 ? void 0 : _b.submitCallback())];
                case 1:
                    _e.sent();
                    _e.label = 2;
                case 2:
                    if (!((_c = steps[step]) === null || _c === void 0 ? void 0 : _c.stepBackCallback)) return [3 /*break*/, 4];
                    return [4 /*yield*/, ((_d = steps[step]) === null || _d === void 0 ? void 0 : _d.stepBackCallback())];
                case 3:
                    _e.sent();
                    _e.label = 4;
                case 4:
                    step === 0 ? closeStepper() : setStep(step - 1);
                    _e.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleNextStep = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    if (!!errors) return [3 /*break*/, 3];
                    if (!((_a = steps[step]) === null || _a === void 0 ? void 0 : _a.submitCallback)) return [3 /*break*/, 2];
                    return [4 /*yield*/, ((_b = steps[step]) === null || _b === void 0 ? void 0 : _b.submitCallback())];
                case 1:
                    _e.sent();
                    _e.label = 2;
                case 2:
                    if (step < childrenCount) {
                        // Prevent us from moving to the next step if we have custom errors to handle
                        // on the parent/ smart component calling the Stepper
                        if (!steps[step].preventNext) {
                            // Make sure we update step before we mark as touched
                            setStep(step + 1);
                            // Ensure we mark the current step as touched
                            (_d = (_c = steps[step]) === null || _c === void 0 ? void 0 : _c.stepCallback) === null || _d === void 0 ? void 0 : _d.call(_c, { touched: true });
                        }
                    }
                    _e.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var closeStepper = function () {
        onClose();
        if (!activeStep) {
            setStep(0);
        }
    };
    var saveAndExitStepper = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 4, , 5]);
                    if (!!errors) return [3 /*break*/, 3];
                    if (!((_a = steps[step]) === null || _a === void 0 ? void 0 : _a.submitCallback)) return [3 /*break*/, 2];
                    return [4 /*yield*/, ((_b = steps[step]) === null || _b === void 0 ? void 0 : _b.submitCallback())];
                case 1:
                    _c.sent();
                    _c.label = 2;
                case 2:
                    closeStepper();
                    _c.label = 3;
                case 3: return [3 /*break*/, 5];
                case 4:
                    error_1 = _c.sent();
                    console.error("Stepper::saveAndExitStepper(): ".concat(error_1));
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var renderCloseButton = function () {
        if (step < saveAndExitFromStep || step === steps.length) {
            return React.createElement("span", null);
        }
        return (React.createElement("div", { className: "Modal-close u-spaceSm" },
            React.createElement(teamsnap_ui_1.Button, { size: "large", onClick: function () {
                    saveAndExitStepper();
                }, testId: "stepper-save-and-exit-button" }, "Save and Exit")));
    };
    return (React.createElement(teamsnap_ui_1.Modal, { heading: "", show: show, showClose: true, closeButton: renderCloseButton(), allowOverlayClose: false, closeFn: function () { return closeStepper(); }, fullscreen: true },
        (step !== steps.length && step !== -1) &&
            React.createElement(React.Fragment, null, customHeader),
        React.createElement("div", { className: "u-padMd", style: {
                flex: '1',
                overflow: 'auto',
            } },
            React.createElement("div", { style: (step !== steps.length && step !== -1) ? customStepStyles : null }, step === -1 ? childElements[steps.length] : childElements[step])),
        (step !== steps.length && step !== -1) &&
            React.createElement(React.Fragment, null, drawer),
        React.createElement("div", { style: {
                flex: '0 0 100px',
            } },
            showProgress &&
                React.createElement(teamsnap_ui_1.ProgressBar, { animate: true, color: "primary", progress: progressPercent(), isSquared: true, size: "small" }),
            React.createElement("div", { className: "u-flex u-flexJustifyBetween u-flexAlignItemsCenter u-padSidesLg ".concat(!showProgress ? 'u-borderTop u-borderNeutral4' : ''), style: {
                    height: '100%',
                } },
                React.createElement("div", null, (displayBackOnFirst || step > 0) &&
                    React.createElement(teamsnap_ui_1.Button, { mods: "u-padLeftLg u-padRightXl", icon: "left", iconPosition: "left", size: "large", onClick: function () { handleBackStep(); }, style: step === -1 ? { visibility: 'hidden' } : {}, testId: "stepper-back-button" }, labeledButtons
                        ? displayStepButtonText('Back')
                        : ((_b = steps[step]) === null || _b === void 0 ? void 0 : _b.customBackButtonText) ? steps[step].customBackButtonText : 'Back')),
                numbered && step !== -1 &&
                    React.createElement("p", { className: "u-colorNeutral8" },
                        React.createElement("strong", null,
                            step + 1,
                            " / ",
                            childrenCount)),
                (excludeSummary ? step + 1 : step) === steps.length || step === -1 ? (React.createElement(teamsnap_ui_1.Button, { mods: "u-padRightLg u-padLeftXl", icon: "right", iconPosition: "right", size: "large", color: "primary", onClick: function () { return saveAndExitStepper(); }, testId: "stepper-done-button" }, "Done")) : (React.createElement(teamsnap_ui_1.Button, { mods: "u-padRightLg u-padLeftXl", color: "primary", icon: "right", iconPosition: "right", size: "large", isDisabled: (_c = steps[step]) === null || _c === void 0 ? void 0 : _c.disableNext, onClick: function () {
                        handleNextStep();
                    }, testId: "stepper-next-button" }, labeledButtons
                    ? displayStepButtonText('Next')
                    : ((_d = steps[step]) === null || _d === void 0 ? void 0 : _d.customNextButtonText) ? steps[step].customNextButtonText : 'Next'))))));
};
exports.Stepper = Stepper;
