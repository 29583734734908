import { isSameOrBeforeCurrentDate, isDateValid } from 'utils/date-service';

export const validate = (values, props) => {
  const errors = { scheduled: [] };

  // installments active
  if (values.installments) {
    // scheduled payments FieldArray is initialized
    if (values.scheduled && values.scheduled.length) {
      values.scheduled.forEach((value) => {
        if (!value || !value.paymentDate) {
          errors.scheduled = { _error: 'You must have at least one additional payment date to make a schedule.' };
        }

        if (value.paymentDate && !isDateValid(value.paymentDate, props.dateFormat)) {
          errors.scheduled = { _error: `Date format must be ${props.dateFormat}` };
        } else if (value.paymentDate && isSameOrBeforeCurrentDate(value.paymentDate)) {
          errors.scheduled = { _error: 'All dates must be set in the future.' };
        }
      });

      if (values.scheduled.length >= 24) {
        errors.scheduled = { _error: 'There is a maximum of 24 installment dates.' };
      }
    }
  }
  return errors;
};
