import {
  isRequired,
  hasMinLength,
  hasMaxLength,
  hasLength,
  isAlphaPlus,
  isNumeric,
  isValidEmail,
  isValidZip,
  isValidCreditCard,
} from 'utils/validator';

export const validate = (values) => {
  const errors = {};

  const fields = ['ccNumber', 'userName', 'userEmail', 'cvv', 'expMonth', 'expYear', 'email', 'postalCode'];

  fields.forEach((field) => {
    let validation = [isRequired(values[field])];

    if (field === 'ccNumber') {
      validation = [...validation, isValidCreditCard(values.ccNumber)];
    }

    if (field === 'userName') {
      validation = [...validation, hasMinLength(values.userName, 2), isAlphaPlus(values.userName)];
    }

    if (field === 'cvv') {
      const correctLength = hasLength(values.cvv, 3) && hasMaxLength(values.cvv, 4);
      validation = [...validation, isNumeric(values.cvv), correctLength];
    }

    if (field === 'userEmail') {
      validation = [...validation, isValidEmail(values.userEmail)];
    }

    if (field === 'postalCode') {
      validation = [...validation, isValidZip(values.postalCode)];
    }

    const validator = validation.find((v) => !v.valid);
    if (validator) errors[field] = validator.message;
  });

  return errors;
};
