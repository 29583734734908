import PropTypes from 'prop-types';

export const inputOptionPropShape = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
};

export const memberFilterFieldPropShape = {
  remove: PropTypes.func,
  push: PropTypes.func,
  category: PropTypes.string,
  length: PropTypes.number,
};
