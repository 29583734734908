export function randomStartupMessage() {
  const phrases = [
    'It takes two to tango. It only takes one to do the chicken dance.',
    'Nice shirt! Is that velour?',
    'Why do birds suddenly appear every time you are near? Birdseed underwear!',
    'Since England uses meters instead of feet, shouldn’t they call soccer “third-of-a-meter-ball”?',
    'Go ahead and yodel – nobody’s listening.',
    'What do you get when you cross a duck with a dairy cow? Cheese and quackers.',
    'Today is Talk Like a Penguin day. Go on!',
    'One moment... configurating the thingamajig...',
    'If you have a spring in your step, you might be a robot.',
    'LET’S GET READY TO TEAMSNAP!',
    'Fun fact: TeamSnap is operated entirely by elves.',
    'TeamSnap Facts: Our CEO (Dave) bikes to work every day, even in the snow.',
    'Today is “Bring Your Parakeet to Work Day” at TeamSnap.',
    'Thank you for spending a few minutes with us today!',
    'Please take a moment to give yourself a high five.',
    'Shoo-bop sha wadda wadda yippity boom de boom',
    'This would be an excellent time for a dance break.',
    'Pretty great team you’ve got going there!',
    'Tickle fight on three!',
    'Our team is lovingly configuring your TeamSnap experience.',
    'TeamSnap loves you.',
    'We’ve been your #1 fan since the beginning.',
    'Don’t tell anyone, but you’re our favorite customer.',
    'Thank you for being awesome.',
    'Give thanks everyday. It’s a renewable resource.',
    'TeamSnap can be used as a flotation device.',
    'The best things in life aren’t things.',
    'We made TeamSnap just for you.',
    'Play with honor. Also, remember your pants.',
    'You come here often?',
  ];
  return phrases[Math.floor(Math.random() * phrases.length)];
}
