import { connect } from 'react-redux';
import { selectDateFormat } from 'state/group/selectors';
import { selectBatchInvoiceByBatchRoute, selectBatchInvoiceIsFetching } from 'state/batchInvoices/selectors';
import {
  selectBatchInvoiceLineItemsByBatchRoute,
} from 'state/batchInvoiceLineItems/selectors';
import { loadBatchInvoices } from 'state/batchInvoices/actions';
import { loadBatchInvoiceLineItems } from 'state/batchInvoiceLineItems/actions';
import { updateBatchInvoice } from 'state/batchInvoices/actions';
import { showFeedback } from 'state/feedback/actions';
import InvoicingEdit from './InvoicingEdit';

const mapDispatchToProps = {
  loadBatchInvoiceLineItems,
  loadBatchInvoices,
  updateBatchInvoice,
  showFeedback
};

const mapStateToProps = (state) => ({
  batchInvoice: selectBatchInvoiceByBatchRoute(state) || {},
  batchInvoiceLineItems: selectBatchInvoiceLineItemsByBatchRoute(state) || [],
  isFetching: selectBatchInvoiceIsFetching(state),
  dateFormat: selectDateFormat(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoicingEdit);
