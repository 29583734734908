import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Cell } from '@teamsnap/teamsnap-ui';
import styles from './CheckoutForm.module.scss';

class CheckoutMessage extends Component {
  renderMessage = () => (
    <Cell mods="u-size1of1">
      <span className={ this.props.type === 'error' ? styles.CheckoutError : null }>{ this.props.text }</span>
    </Cell>
  );

  render() {
    return this.props.text ? this.renderMessage() : null;
  }
}

CheckoutMessage.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
};

CheckoutMessage.defaultProps = {
  text: null,
  type: 'error',
};

export default CheckoutMessage;
