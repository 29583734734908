import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Button, Grid, Cell } from '@teamsnap/teamsnap-ui';
import { ImgCreditCardLogos } from 'components/baseComponents/Img';
import InputField from 'components/baseComponents/Fields/InputField';
import SelectField from 'components/baseComponents/Fields/SelectField';
import { trimInput } from 'utils/utils';

const LineItem = ({ field, index, options, handleRemove, disableLineItems }) => (
  <Grid isWithGutter>
    { !disableLineItems && (
      <SelectField
        size="5of24"
        name={ `${field}.invoiceCategoryId` }
        label="Category"
        placeholder="Select Category"
        options={ options }
      />
    ) }

    { !disableLineItems && (
      <InputField
        size="Fill"
        name={ `${field}.description` }
        type="text"
        label="Description"
        normalize={ (value) => trimInput(value) }
      />
    ) }

    <InputField size="4of24" name={ `${field}.amount` } type="text" label="Price" icon="$" />

    { !disableLineItems && (
      <Cell mods="u-size1of24 u-flexAlignSelfStart">
        <Button color="negative" size="small" style={ { marginTop: '22px' } } onClick={ () => handleRemove(index) }>
          <Icon name="dismiss" style={ { fontSize: '13px' } } />
        </Button>
      </Cell>
    ) }

    { disableLineItems && (
      <Cell mods="u-size3of24 u-flexAlignSelfCenter u-spaceTopSm">
        <ImgCreditCardLogos />
      </Cell>
    ) }

    { disableLineItems && (
      <Cell mods="u-sizeFill u-padTopMd u-fontSizeSm">
        <span style={ { color: '#7A7A7A' } }>3.25% + $1.50 fee per online transaction</span>
        <a href="https://www.teamsnap.com/terms-app" className="u-block" target="_blank" rel="noopener noreferrer">
          Terms and Conditions
        </a>
      </Cell>
    ) }
  </Grid>
);

LineItem.propTypes = {
  disableLineItems: PropTypes.bool.isRequired,
  field: PropTypes.string,
  index: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  handleRemove: PropTypes.func,
};

LineItem.defaultProps = {
  field: null,
  index: null,
  options: [],
  handleRemove: null,
};

export default LineItem;
