import React from 'react';
import { stringifyArray } from 'utils/utils';
import { Icon, Button, TextLink } from '@teamsnap/teamsnap-ui';
import styles from './InvoicingMembers.module.scss';

const memberNameAndRoleColumn = (member) => {
  const memberRoles = [
    member.isCommissioner ? 'Commissioner' : null,
    member.isManager ? 'Manager' : null,
    member.isCoach ? 'Coach' : null,
  ];

  return (
    <div>
      <h5 className={ styles.TextPrimary }>{ member.fullName }</h5>
      <span className={ styles.TextSecondary }>{ stringifyArray(memberRoles) }</span>
    </div>
  );
};

const memberTeamAndDivisionColumn = (teamName, divisionName) => (
  <div>
    <h5 className={ styles.TextPrimary }>{ teamName }</h5>
    <span className={ styles.TextSecondary }>{ divisionName }</span>
  </div>
);

const memberAddButtonColumn = (member, isInvoiced, isSelected, selectedMembers, reduxFieldChange) => {
  const isDisabled = isInvoiced || isSelected;

  let iconName = 'plus';
  if (isInvoiced) {
    iconName = 'lock';
  } else if (isSelected) {
    iconName = 'check';
  }

  return (
    <Button
      color="primary"
      isDisabled={ isDisabled }
      size="small"
      onClick={ isDisabled ? null : () => reduxFieldChange('members', [...selectedMembers, member]) }
    >
      <Icon name={ iconName } style={ { fontSize: '10px' } } />
    </Button>
  );
};

export const setupMemberRecipientRows = (members, invoiceRecipients, selectedMembers, reduxFieldChange) => {
  const recipientsArray = invoiceRecipients.map((r) => r.invoiceableId);
  const selectedArray = selectedMembers.map((s) => s.id);

  return members.map((member) => {
    const isInvoiced = recipientsArray.includes(member.id);
    const isSelected = selectedArray.includes(member.id);
    const classModifier = isInvoiced || isSelected ? 'Panel-cell--muted' : '';

    const teamName = member.teamName || 'Not Rostered';
    const divisionName = member.divisionName || 'un-assigned';

    return {
      ...member,
      isInvoiced,
      isSelected,
      nameAndRole: {
        value: memberNameAndRoleColumn(member),
        sortValue: member.fullName,
        className: `Panel-cell u-size3of12 ${classModifier}`,
      },
      gender: {
        value: member.gender || '',
        className: `Panel-cell u-size2of12 ${classModifier}`,
      },
      position: {
        value: member.position || '',
        className: `Panel-cell u-size2of12 ${classModifier}`,
      },
      teamAndDivision: {
        value: memberTeamAndDivisionColumn(teamName, divisionName),
        sortValue: `${teamName} ${divisionName}`,
        filterDivision: divisionName,
        filterTeam: teamName,
        className: `Panel-cell u-size3of12 ${classModifier}`,
      },
      addMember: {
        value: memberAddButtonColumn(member, isInvoiced, isSelected, selectedMembers, reduxFieldChange),
        className: 'Panel-cell u-size2of12 u-textCenter',
      },
    };
  });
};

export const setupMemberRecipientColumns = (members, addAllCurrentMembers, groupType = 'divisionId') => {
  const memberColumns = [
    {
      label: 'Name',
      key: 'nameAndRole.value',
      sortKey: 'nameAndRole.sortValue',
      sortable: true,
      filterable: false,
      className: 'Panel-cell u-size3of12',
    },
    { label: 'Gender', key: 'gender.value', sortable: true, filterable: false, className: 'Panel-cell u-size2of12' },
    {
      label: 'Position',
      key: 'position.value',
      sortable: true,
      filterable: false,
      className: 'Panel-cell u-size2of12',
    },
  ];

  if (groupType === 'divisionId') {
    memberColumns.push({
      label: 'Team/Division',
      key: 'teamAndDivision.value',
      sortKey: 'teamAndDivision.sortValue',
      sortable: true,
      filterable: false,
      className: 'Panel-cell u-size3of12',
    });
  }

  if (members.length) {
    memberColumns.push({
      key: 'addMember.value',
      className: 'Panel-cell u-size2of12 u-textCenter',
      label: (
        <TextLink location={ null } className="TextLinkColumn" onClick={ () => addAllCurrentMembers(members) }>
          Add All
        </TextLink>
      ),
    });
  }

  return memberColumns;
};
