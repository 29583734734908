import _camelCase from 'lodash/camelCase';
import api from 'services/api';
import { fetchCanAcceptPayments } from 'state/canAcceptPayment/actions';
import { authenticationAppId } from 'utils/window';
import { FeatureFlags, isAllowedInCurrentEnvironment } from 'utils/feature-flags';

export const FETCH_INVOICE_PAYMENT_DETAILS = 'FETCH_INVOICE_PAYMENT_DETAILS';
export const SAVE_INVOICE_PAYMENT_DETAILS = 'SAVE_INVOICE_PAYMENT_DETAILS';
export const ERROR_INVOICE_PAYMENT_DETAILS = 'ERROR_INVOICE_PAYMENT_DETAILS';
export const INVOICE_PAYMENT_SUBMITTED = 'INVOICE_PAYMENT_SUBMITTED';
export const INVOICE_PAYMENT_UPDATE_SUCCESS = 'INVOICE_PAYMENT_UPDATE_SUCCESS';
export const INVOICE_PAYMENT_UPDATE_ERROR = 'INVOICE_PAYMENT_UPDATE_ERROR';
export const INVOICE_PAYMENT_ERROR = 'INVOICE_PAYMENT_ERROR';

// const fetchInvoicePaymentDetails = () => ({ type: FETCH_INVOICE_PAYMENT_DETAILS })

const saveInvoicePaymentDetails = (details) => ({
  type: SAVE_INVOICE_PAYMENT_DETAILS,
  payload: details,
});
const errorInvoiceDetails = () => ({ type: ERROR_INVOICE_PAYMENT_DETAILS });

const invoicePaymentSubmitted = () => ({ type: INVOICE_PAYMENT_SUBMITTED });

const invoicePaymentError = (message) => ({ type: INVOICE_PAYMENT_ERROR, payload: message });

const paymentUpdateSuccess = () => ({ type: INVOICE_PAYMENT_UPDATE_SUCCESS });

const paymentUpdateError = () => ({ type: INVOICE_PAYMENT_UPDATE_ERROR });

const apiConfig = {
  headers: {
    'X-TEAMSNAP-CLIENT-ID': `${authenticationAppId}`,
  },
};

const formatItems = (collection) => {
  const items = {
    invoicePayment: [],
    invoiceLineItems: [],
    invoiceSchedulePaymentsPaymentOptionAmounts: [],
  };

  collection.items.forEach((item) => {
    if (item.type === 'invoice_line_item') {
      return items.invoiceLineItems.push(item);
    } else if (item.type === 'invoice_schedule_payments_payment_option_amounts') {
      return items.invoiceSchedulePaymentsPaymentOptionAmounts.push(item);
    } else if (item.type === 'invoice_payment') {
      return items.invoicePayment.push(item);
    }

    // TODO: Temporarily added to force 'string' booleans into actual booleans until API is updated.
    let newItem = item;
    if (item.type === 'invoice') {
      newItem = {
        ...item,
        canPayByPaymentSchedule: item.canPayByPaymentSchedule.toString() === 'true',
        hasPaymentSchedule: item.hasPaymentSchedule.toString() === 'true',
        hasPayments: item.hasPayments.toString() === 'true',
        isCancelable: item.isCancelable.toString() === 'true',
        isPayable: item.isPayable.toString() === 'true',
        isPayableOffline: item.isPayableOffline.toString() === 'true',
      };
    }

    items[_camelCase(item.type)] = newItem;
    return item;
  });

  return items;
};

export const loadInvoicePaymentDetails = (uuid) => async (dispatch) => {
  try {
    const response = await api.get(`pay_invoice_details/${uuid}`, null, apiConfig);
    const items = formatItems(response);
    dispatch(saveInvoicePaymentDetails(items));

    const isAccountCheckingEnabled = isAllowedInCurrentEnvironment(FeatureFlags.DISABLE_PAYMENT);
    
    if (items?.team && isAccountCheckingEnabled) {
      dispatch(fetchCanAcceptPayments({ scopeId: items.team.id, scopeType: 'team' }));
    }
    return Promise.resolve(items);
  } catch (error) {
    dispatch(errorInvoiceDetails());
    return Promise.reject(error);
  }
};

const getPaymentUrl = (activeProvider, isInstallment) => {
  switch (`${activeProvider}&${isInstallment}`) {
    case 'stripe&true':
      return 'invoice_payments/pay_with_payment_schedule_stripe_credit_card';
    case 'stripe&false':
      return 'invoice_payments/pay_stripe_credit_card';
    case 'wepay&true':
      return 'invoice_payments/pay_with_payment_schedule_wepay_credit_card';
    default:
      return 'invoice_payments/pay_wepay_credit_card';
  }
};

export const savePaymentMethod = (payment) => (dispatch) => {
  const { invoiceId, creditCardId, email, invoiceBalance, installments, activeProvider } = payment;
  const isInstallment = !!installments;

  const paymentOptions = {
    invoice_id: invoiceId,
    credit_card_id: creditCardId,
    ...(email && { email_address: email }),
    ...(!isInstallment && { amount: invoiceBalance }),
    ...(isInstallment && {
      confirm_dates_amounts: installments.map((installment) => ({
        payment_date: installment.paymentDate,
        payment_total: installment.paymentTotal,
        is_deposit: installment.isDeposit,
      })),
    }),
  };

  const paymentUrl = getPaymentUrl(activeProvider, isInstallment);

  return api
    .cmd(paymentUrl, paymentOptions, apiConfig)
    .then((response) => Promise.resolve(response).then(() => dispatch(invoicePaymentSubmitted())))
    .catch((error) => {
      if (error.message) {
        dispatch(invoicePaymentError(error.message));
        return error;
      }
      return Promise.reject(error);
    });
};

const getUpdateUrl = (paymentProvider) => {
  if (paymentProvider === 'stripe') {
    return 'update_stripe_default_payment_method';
  } else {
    return 'update_default_payment_method';
  }
};

// eslint-disable-next-line max-len
export const updatePaymentMethod = (invoicePaymentScheduleId, creditCardId, email, paymentProvider) => (dispatch) => {
  const data = { invoicePaymentScheduleId, creditCardId };
  if (email) data.email_address = email;

  return api
    .cmd(`invoice_payment_schedules/${getUpdateUrl(paymentProvider)}`, data, apiConfig)
    .then((response) => {
      dispatch(paymentUpdateSuccess());
      return Promise.resolve(response);
    })
    .catch((error) => {
      dispatch(paymentUpdateError());
      return Promise.reject(error);
    });
};
