import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { selectMemberTeamAndDivision, selectMemberIsLoading, selectTeamMembers } from 'state/members/selectors';
import { selectInvoicesByBatchRoute } from 'state/invoices/selectors';
import { selectTeamNameIsFetching, selectTeamNamesForDropdown } from 'state/teamNames/selectors';
import { selectDivisionsForDropdown, selectDivisionIsFetching } from 'state/divisions/selectors';
import { loadAdvancedDivisionMembers, loadMembers } from 'state/members/actions';
import {
  selectInvoiceRecipientByMemberType,
  selectInvoiceRecipientIsFetching,
} from 'state/invoiceRecipients/selectors';
import { loadBatchInvoices } from 'state/batchInvoices/actions';
import { createInvoice, queryInvoicesAndRecipients } from 'state/invoices/actions';
import { saveInvoiceMessageRecipients } from 'state/invoiceMessageRecipients/actions';
import InvoicingMembers from './InvoicingMembers';
import { getGroupId, getGroupType } from 'utils/group';
import { selectNeedsRefreshToShowFinancials } from 'state/group/selectors';

const mapDispatchToProps = {
  loadAdvancedDivisionMembers,
  queryInvoicesAndRecipients,
  createInvoice,
  loadBatchInvoices,
  saveInvoiceMessageRecipients,
  loadMembers,
};

const memberFilterFormSelector = formValueSelector('memberFilters');
const invoiceRecipientFormSelector = formValueSelector('invoiceRecipients');

const mapStateToProps = (state, { match: { params } }) => ({
  batchInvoiceId: params.invoicingId,
  groupId: getGroupId(params),
  isPageLoading: selectTeamNameIsFetching(state) || selectDivisionIsFetching(state),
  isMemberLoading:
    selectMemberIsLoading(state) || (selectMemberIsLoading(state) && selectInvoiceRecipientIsFetching(state)),
  teams: selectTeamNamesForDropdown(state),
  divisions: selectDivisionsForDropdown(state),
  invoices: selectInvoicesByBatchRoute(state) || [],
  members: getGroupType(params) === 'divisionId' ? selectMemberTeamAndDivision(state) : selectTeamMembers(state),
  invoiceRecipients: selectInvoiceRecipientByMemberType(state),
  formSelectorFilters: memberFilterFormSelector(state, 'filters'),
  formSelectorRecipients: invoiceRecipientFormSelector(state, 'members'),
  needsRefresh: selectNeedsRefreshToShowFinancials(state),
  groupType: getGroupType(params),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoicingMembers);
