import React from 'react';
import PropTypes from 'prop-types';
import { Toggle } from 'components/teamsnapUIExtensions';
import { Cell } from '@teamsnap/teamsnap-ui';
import styles from '../PayInvoice.module.scss';

const InstallmentNote = ({ total }) => (
  <span style={ { paddingLeft: '10px', fontSize: '14px' } }>
    Pay by Installment
    <span style={ { paddingLeft: '10px', fontSize: '12px', color: '#aaa' } }>
      * total is divided into ({ total }) payments
    </span>
  </span>
);

InstallmentNote.propTypes = {
  total: PropTypes.number.isRequired,
};

const InstallmentToggle = ({ isInstallment, handleChange, installmentsTotal }) => (
  <Cell mods="u-size1of1 u-spaceBottomLg">
    <hr className={ styles.Divider } style={ { marginBottom: '12px' } } />
    <Toggle
      input={ { name: 'installmentPlan', value: isInstallment, onChange: handleChange } }
      note={ <InstallmentNote total={ installmentsTotal } /> }
    />
  </Cell>
);

InstallmentToggle.propTypes = {
  handleChange: PropTypes.func.isRequired,
  isInstallment: PropTypes.bool,
  installmentsTotal: PropTypes.number,
};

InstallmentToggle.defaultProps = {
  isInstallment: false,
  installmentsTotal: 0,
};

export default InstallmentToggle;
