import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, FieldArray } from 'redux-form';
import { SectionHeading, Form } from 'components/teamsnapUIExtensions';
import RadioButtonField from 'components/baseComponents/Fields/RadioButtonField';
import MemberFilterFields from 'components/baseComponents/MemberFilterFields';
import { stringifyArray } from 'utils/utils';
import styles from './MemberFilter.module.scss';

const RADIO_OPTIONS = [
  { title: 'Players', value: 'isPlayer' },
  { title: 'Non-Players', value: 'isNonPlayer' },
  { title: 'Managers', value: 'isManager' },
  { title: 'All Members', value: 'isAll' },
];

class MemberFilter extends Component {
  handleFormSubmit = (formProps) => {
    const { memberType, filters } = formProps;
    const { groupId, onFormSubmit } = this.props;

    // Reduce Filter to groups by category
    const { division, team, firstName, lastName } = filters.reduce((groups, field) => {
      const newGroups = groups;
      newGroups[field.category] = stringifyArray([newGroups[field.category], field.value]);
      return newGroups;
    }, {});

    // Format params for apiv3 loadAdvancedMembers search
    // operator:contains,value:Comm
    const params = {
      divisionId: division || groupId,
      ...(team && { teamId: team }),
      ...(firstName && { firstName: `operator:contains,value:${firstName}` }),
      ...(lastName && { lastName: `operator:contains,value:${lastName}` }),
      ...(memberType === 'isManager' && { isManager: true }),
      ...(memberType === 'isNonPlayer' && { isNonPlayer: true }),
      ...(memberType === 'isPlayer' && { isNonPlayer: false }),
    };

    return onFormSubmit(params);
  };

  handleCategoryChange = (index) => {
    this.props.change(`filters[${index}]`, { value: null });
  };

  render() {
    const { teams, divisions, formSelectorFilters, handleSubmit } = this.props;

    return (
      <Form handleSubmit={ handleSubmit(this.handleFormSubmit) } componentStyles={ styles }>
        <SectionHeading
          title="Search Members"
          component={
            <RadioButtonField
              name="memberType"
              options={ RADIO_OPTIONS }
              className="InputControlInline"
              componentStyles={ styles }
            />
          }
        />

        <FieldArray
          name="filters"
          component={ MemberFilterFields }
          onFilterChange={ this.handleCategoryChange }
          formSelectorFilters={ formSelectorFilters }
          teams={ teams }
          divisions={ divisions }
        />
      </Form>
    );
  }
}

MemberFilter.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  teams: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  divisions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  formSelectorFilters: PropTypes.arrayOf(PropTypes.shape({})),
};

MemberFilter.defaultProps = {
  formSelectorFilters: [],
};

export default reduxForm({
  form: 'memberFilters',
  initialValues: { memberType: 'isPlayer' },
})(MemberFilter);
