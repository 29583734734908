import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Button, Grid, Cell } from '@teamsnap/teamsnap-ui';
import sortBy from 'utils/sorter';
import InputField from 'components/baseComponents/Fields/InputField';
import SelectField from 'components/baseComponents/Fields/SelectField';
import styles from './MemberFilterFields.module.scss';
import { memberFilterFieldPropShape } from 'interfaces/fields';

const SELECT_OPTIONS = [
  { title: 'Team Name', value: 'team' },
  { title: 'Division Name', value: 'division' },
  { title: 'First Name', value: 'firstName' },
  { title: 'Last Name', value: 'lastName' },
];

class MemberFilterFields extends Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { fields } = this.props;
    if (!fields.length) fields.push({ category: 'lastName' });
  }

  renderFilterValue = (name, category) => {
    const { teams, divisions } = this.props;

    let FilterInput = null;
    const isTeam = category === 'team';
    const isDivision = category === 'division';

    const fieldProps = {
      name,
      size: '11of24',
      label: '',
      ...(isTeam && { placeholder: 'Select Team', options: sortBy('title', teams) }),
      ...(isDivision && { placeholder: 'Select Division', options: sortBy('title', divisions) }),
    };

    if (isTeam || isDivision) {
      FilterInput = <SelectField { ...fieldProps } />;
    } else {
      FilterInput = <InputField { ...fieldProps } />;
    }

    return FilterInput;
  };

  renderField = (field, index) => {
    const { fields, formSelectorFilters, onFilterChange } = this.props;
    const filterField = formSelectorFilters[index];

    const selectedCategories = formSelectorFilters
      .filter((f) => ['lastName', 'firstName'].includes(f.category))
      .map((f) => f.category);

    // Disable options for first and last name if already selected
    const availableOptions = SELECT_OPTIONS.map((option) =>
      selectedCategories.includes(option.value) ? { disabled: true, ...option } : option,
    );

    return (
      <Grid key={ index } isWithGutter>
        <SelectField
          name={ `${field}.category` }
          onChange={ () => onFilterChange(index) }
          options={ availableOptions }
          label=""
          placeholder="Select Category"
          size={ filterField.category ? '11of24' : '22of24' }
        />

        { filterField.category && this.renderFilterValue(`${field}.value`, filterField.category) }

        <Cell mods="u-size1of24 u-flexAlignSelfStart">
          <Button color="negative" size="small" mods="u-spaceTopXs" onClick={ () => fields.remove(index) }>
            <Icon name="dismiss" style={ { fontSize: '13px' } } />
          </Button>
        </Cell>
      </Grid>
    );
  };

  renderFilterFields = (fields) => fields.map((field, index) => this.renderField(field, index));

  render() {
    const { fields } = this.props;

    return (
      <div>
        <div className={ fields.length && styles.Filters }>{ this.renderFilterFields(fields) }</div>

        <Grid>
          <Cell mods="u-sizeFit">
            <Button onClick={ () => fields.push({}) } size="small">
              Add Search Criteria
            </Button>
          </Cell>
          <Cell mods="u-sizeFit u-flexExpandLeft">
            <Button type="submit" color="primary" size="small">
              Search Members
            </Button>
          </Cell>
        </Grid>
      </div>
    );
  }
}

MemberFilterFields.propTypes = {
  fields: PropTypes.shape(memberFilterFieldPropShape).isRequired,
  divisions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  teams: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  formSelectorFilters: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
    }),
  ),
};

MemberFilterFields.defaultProps = {
  formSelectorFilters: [],
};

export default MemberFilterFields;
