import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@teamsnap/teamsnap-ui';
import styles from '../PayInvoice.module.scss';

const InvoiceAlert = ({ title, message, icon, color, hasDivider, offset }) => {
  const classes =
    color === 'primary' ? styles.PaymentStatus : `${styles.PaymentStatus} ${styles['PaymentStatus--secondary']}`;

  return (
    <header className={ classes }>
      { hasDivider && <hr className={ styles['Divider--dashed'] } /> }
      <h3 style={ { marginTop: '50px' } }>
        <span className={ styles['Icon-border'] }>
          <Icon name={ icon } style={ { fontSize: '25px', top: offset } } />
        </span>
        { title }
      </h3>
      <p>
        <small>{ message }</small>
      </p>
    </header>
  );
};

InvoiceAlert.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
  hasDivider: PropTypes.bool,
  offset: PropTypes.string,
};

InvoiceAlert.defaultProps = {
  color: 'primary',
  hasDivider: true,
  offset: null,
};

export default InvoiceAlert;
