export const validate = (values) => {
  const errors = {};

  // Add any Validaton rules here and add to errors object.
  if (!values.subject) {
    errors.subject = 'Field can not be blank';
  }

  if (!values.body) {
    errors.body = 'Field can not be blank';
  }

  return errors;
};
