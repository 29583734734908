export const selectInvoicePaymentLoading = (state) => state.payInvoice.isFetching;

export const selectInvoicePaymentGroup = (state) => state.payInvoice.items.division || state.payInvoice.items.team;

export const selectInvoicePaymentDivisionPreferences = (state) => state.payInvoice.items.divisionPreferences;

export const selectInvoicePaymentTeamPreferences = (state) => state.payInvoice.items.teamPreferences;

export const selectInvoicePaymentDateFormat = (state) => {
  const divisionPreferences = selectInvoicePaymentDivisionPreferences(state);
  const teamPreferences = selectInvoicePaymentTeamPreferences(state);
  let isInternational = false;
  if (divisionPreferences) {
    isInternational = divisionPreferences.hasInternationalDate;
  } else if (teamPreferences) {
    isInternational = teamPreferences.globalUseInternationalDate;
  }
  return isInternational ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
};

export const selectInvoicePaymentCurrency = (state) => state.payInvoice.items.currency;

export const selectInvoicePayments = (state) => state.payInvoice.items.invoicePayment;

export const selectInvoicePaymentSchedule = (state) => state.payInvoice.items.invoicePaymentSchedule;

export const selectInvoicePaymentInvoice = (state) => state.payInvoice.items.invoice;

export const selectInvoicePaymentLineItems = (state) => state.payInvoice.items.invoiceLineItems;

export const selectInvoicePaymentOptionAmounts = (state) => state.payInvoice.items.invoicePaymentOptionAmounts;

export const selectInvoiceSchedulePaymentOptionAmounts = (state) =>
  state.payInvoice.items.invoiceSchedulePaymentOptionAmounts;

export const selectInvoiceSchedulePaymentsPaymentOptionAmounts = (state) =>
  state.payInvoice.items.invoiceSchedulePaymentsPaymentOptionAmounts;

export const selectInvoicePaymentSubmitted = (state) => state.payInvoice.submitted;

export const selectInvoicePaymentUpdateStatus = (state) => state.payInvoice.paymentUpdateStatus;

export const selectInvoicePaymentError = (state) => state.payInvoice.payErrorMessage;
