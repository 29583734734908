import { FETCH_PAYMENT_ACCOUNT, SAVE_PAYMENT_ACCOUNT, ERROR_PAYMENT_ACCOUNT } from './actions';

const initialState = {
  isFetching: false,
  isLoaded: false,
  isError: false,
  items: null,
};

const paymentAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PAYMENT_ACCOUNT:
      return {
        isFetching: true,
        isError: false,
        isLoaded: false,
        items: null,
      };
    case SAVE_PAYMENT_ACCOUNT:
      return {
        isFetching: false,
        isLoaded: true,
        isError: false,
        items: action.payload,
      };
    case ERROR_PAYMENT_ACCOUNT:
      return {
        isFetching: false,
        isLoaded: false,
        isError: true,
        items: null,
      };
    default:
      return state;
  }
};

export default paymentAccountReducer;
