/**
 * Batch Invoice Aggregate Selectors
 *
 * Various selectors using reselect for selecting data from state
 *
 */

import { createSelector } from 'reselect';
import _values from 'lodash/values';
import { selectResourceId, selectResourceType } from 'state/routerLocation/selectors';

const stateSelector = (state) => state.batchInvoiceAggregates;
const itemSelector = (state) => stateSelector(state).items;

export const selectBatchInvoiceAggregates = createSelector(itemSelector, (items) => _values(items));

export const selectBatchInvoiceAggregatesByGroupId = createSelector(
  selectBatchInvoiceAggregates,
  selectResourceId,
  selectResourceType,
  (items, resourceId, resourceType) => items.find((item) => item[resourceType] === resourceId),
);
