import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CheckoutForm from 'components/baseComponents/CheckoutForm';
import ModalCheckout from 'components/baseComponents/ModalCheckout';
import ModalPaymentLog from 'components/baseComponents/ModalPaymentLog';
import PageLayout from './components/PageLayout';
import CheckoutSummary from './components/CheckoutSummary';
import CheckoutAlert from './components/CheckoutAlert';
import PaymentFeedback from './components/PaymentFeedback';
import InvoiceHeader from './components/InvoiceHeader';
import InvoiceFooter from './components/InvoiceFooter';
import InvoiceDetails from './components/InvoiceDetails';
import InstallmentSummary from './components/InstallmentSummary';
import ScheduledPayments from './components/ScheduledPayments';
import InstallmentToggle from './components/InstallmentToggle';
import InvoiceSummary from './components/InvoiceSummary';
import PaymentSummary from './components/PaymentSummary';
import { shouldComponentShow } from './invoicePaymentLogic';
import { linkInvoicePaymentSubmitted } from 'utils/links';
import styles from './PayInvoice.module.scss';
import { historyPropShape } from 'interfaces/navigation';
import {
  currencyPropShape,
  invoicePaymentOptionAmountsPropShape,
  invoiceSchedulePaymentOptionAmountsPropShape,
} from 'interfaces/financials';
import { invoicePropShape } from 'interfaces/invoice';
import { groupPropShape } from 'interfaces/group';
import { COUNTRY_NAME_CANADA } from 'utils/constants';
import { Banner } from '@teamsnap/snap-ui';

class PayInvoice extends Component {
  state = {
    isInstallmentPlan: null,
    isSubmitting: false,
    error: null,
    serverError: null,
    payments: {},
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { loadInvoicePaymentDetails, uuid } = this.props;
    loadInvoicePaymentDetails(uuid).then(this.groupPayments).catch(this.catchInvoiceNotFound);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { paymentSubmitted, uuid, loadInvoicePaymentDetails } = this.props;

    if (nextProps.paymentSubmitted !== paymentSubmitted) {
      loadInvoicePaymentDetails(uuid).then(this.groupPayments).catch(this.catchInvoiceNotFound);
    }

    const { canPayByPaymentSchedule } = nextProps.invoice;
    this.setState({
      isInstallmentPlan: this.state.isInstallmentPlan === null ? canPayByPaymentSchedule : this.state.isInstallmentPlan,
    });
  }

  redirectToSubmitted = (uuid) => this.props.history.push(linkInvoicePaymentSubmitted(uuid));

  groupPayments = (response) => {
    const groups = {};

    const groupedPayments = response.invoicePayment.reduce((obj, item) => {
      if (item.status === 'scheduled' || item.status === 'failed') {
        groups.scheduled = obj.scheduled || [];
        groups.scheduled.push(item);
      } else {
        groups.log = obj.log || [];
        groups.log.push(item);
      }
      return groups;
    }, {});

    this.setState({ payments: groupedPayments });
  };

  catchInvoiceNotFound = (error) => {
    let serverError = {
      title: 'We dropped the ball',
      message: 'Try again later, or contact your commissioner and support.',
      icon: 'alert',
      offset: '-4px',
      color: 'secondary',
    };

    if (error.statusText === 'Not Found') {
      serverError = {
        ...serverError,
        title: 'Invoice Not Found',
        message: 'This invoice cannot be found, please contact your org director for further information.',
      };
    }

    this.setState({ serverError });
  };

  showPaymentLogModal = () => {
    const { showModal, invoice, dateFormat } = this.props;
    const { payments } = this.state;

    return showModal(ModalPaymentLog, {
      invoice,
      dateFormat,
      payments: payments.log,
    });
  };

  showCreditCardModal = () => {
    const { currency, showModal, invoice, invoicePaymentSchedule, group } = this.props;

    return showModal(ModalCheckout, {
      invoiceId: invoice.id,
      invoicePaymentScheduleId: invoicePaymentSchedule.id,
      currency,
      updateCreditCardToken: this.props.updatePaymentMethod,
      activePaymentProvider: group.activePaymentProvider,
    });
  };

  createCreditCardToken = (creditCard, cardTokenizer) => {
    this.setState({ isSubmitting: true, error: null }, () => cardTokenizer(creditCard));
  };

  saveCreditCardToken = (params, error = null) => {
    const {
      invoice,
      invoicePaymentOptionAmounts,
      invoiceSchedulePaymentsPaymentOptionAmounts,
      savePaymentMethod,
      group,
    } = this.props;

    if (error) {
      this.setState({
        isSubmitting: false,
        error: error,
      });
    } else {
      const paymentOptions = {
        invoiceId: invoice.id,
        invoiceBalance: invoicePaymentOptionAmounts.invoiceBalance,
        creditCardId: params.token,
        activeProvider: group.activePaymentProvider,
        ...(params.email && { email: params.email }),
        ...(this.state.isInstallmentPlan && { installments: invoiceSchedulePaymentsPaymentOptionAmounts }),
      };

      // Save Payment to apiv3 and trigger a full reload for native clients to reload invoices
      savePaymentMethod(paymentOptions).then((response) => {
        if (response.message) {
          this.setState({
            isSubmitting: false,
            error: response.message,
          });
        } else {
          return this.redirectToSubmitted(invoice.uuid);
        }
      });
    }
  };

  handleCheckboxChange = () => this.setState({ isInstallmentPlan: !this.state.isInstallmentPlan });

  showComponent = (componentName) => {
    const { invoice, invoicePaymentSchedule, paymentSubmitted, paymentUpdateStatus } = this.props;
    const { isInstallmentPlan, payments } = this.state;

    return shouldComponentShow(componentName, {
      invoice,
      invoicePaymentSchedule,
      state: {
        paymentSubmitted,
        isInstallmentPlan,
        paymentUpdateStatus,
        payments,
      },
    });
  };

  render() {
    const {
      invoice,
      currency,
      invoiceLineItems,
      invoicePaymentOptionAmounts,
      invoiceSchedulePaymentOptionAmounts,
      invoiceSchedulePaymentsPaymentOptionAmounts,
      isFetching,
      dateFormat,
      paymentUpdateStatus,
      group,
      isTeamBlockedFromPayments,
    } = this.props;

    const { serverError, payments, error } = this.state;
    const isCanadian = group.country === COUNTRY_NAME_CANADA;

    // If general server error or no invoice found for UUID, return error message
    if (serverError) {
      return (
        <PageLayout isFetching={ isFetching }>
          <CheckoutAlert { ...serverError } hasDivider={ false } />
        </PageLayout>
      );
    }

    return (
      <>
      <PageLayout
        isFetching={ isFetching }
        paymentsDisabled={ this.showComponent('PaymentNotSetup') || false }
        isCanadian={ isCanadian }
        subHeader={ isTeamBlockedFromPayments ? (
          <Banner 
            sentiment="negative"
            title="Checkout unavailable" 
            caption="Checkout cannot be completed at this time. Please try again later or contact your team administrator."
          />
          ) : null }
      >
        { /* Render Invoice Header */ }
        { this.showComponent('InvoiceHeader') && (
          <InvoiceHeader invoice={ invoice } billingAddress={ group.billingAddress } />
        ) }

        { /* Render Invoice Overview & Status */ }
        { this.showComponent('InvoiceDetails') && <InvoiceDetails dateFormat={ dateFormat } invoice={ invoice } /> }

        { /* Render Payment Submitted 'Alert' Message */ }
        { this.showComponent('PaymentSubmitted') && (
          <CheckoutAlert
            title="Payment Submitted!"
            message="You will receive an email shortly with confirmation of your payment."
            icon="check"
          />
        ) }

        { /* Render InvoiceSummary  */ }
        { this.showComponent('InvoiceSummary') && (
          <InvoiceSummary
            invoiceLineItems={ invoiceLineItems }
            invoice={ this.showComponent('PaymentSummary') ? null : invoice }
          />
        ) }

        { /* Render Payment Summary */ }
        { this.showComponent('PaymentSummary') && (
          <PaymentSummary invoice={ invoice } handleClick={ this.showPaymentLogModal } />
        ) }

        { /* Render Credit Card Form */ }
        { this.showComponent('CreditCardFormInline') && (
          <section className={ [styles.Component, 'u-padBottomLg'].join(' ') }>
            <CheckoutForm
              activeProvider={ group.activePaymentProvider }
              currency={ currency }
              saveCreditCardToken={ this.saveCreditCardToken }
              createCreditCardToken={ this.createCreditCardToken }
              submitError={ this.state.error }
            />
          </section>
        ) }

        { /* Render InstallmentToggle */ }
        { this.showComponent('InstallmentToggle') && (
          <InstallmentToggle
            installmentsTotal={ invoiceSchedulePaymentsPaymentOptionAmounts.length }
            isInstallment={ this.state.isInstallmentPlan }
            handleChange={ this.handleCheckboxChange }
          />
        ) }

        { /* Render InstallmentSummary for Predictive Payments  */ }
        { this.showComponent('InstallmentSummary') && (
          <InstallmentSummary
            dateFormat={ dateFormat }
            installments={ invoiceSchedulePaymentsPaymentOptionAmounts }
            total={ invoiceSchedulePaymentOptionAmounts.paymentScheduleTotalWithProcessingFeeTotalWithCurrency }
          />
        ) }

        { /* Render InstallmentSummary for All Scheduled Payments  */ }
        { this.showComponent('PaymentLogScheduled') && (
          <ScheduledPayments payments={ payments.scheduled } dateFormat={ dateFormat } />
        ) }

        { /* Render CheckoutSummary  */ }
        { this.showComponent('CheckoutSummary') && (
          <CheckoutSummary
            invoice={ invoice }
            invoicePaymentOptionAmounts={ invoicePaymentOptionAmounts }
            invoiceSchedulePaymentsPaymentOptionAmounts={ invoiceSchedulePaymentsPaymentOptionAmounts }
            isInstallmentPlan={ this.state.isInstallmentPlan }
          />
        ) }

        { /* Render Feedback after credit card has been updated (with success || error) */ }
        { this.showComponent('PaymentFeedback') && <PaymentFeedback status={ paymentUpdateStatus } /> }

        { /* Render InvoiceFooter for Credit Card Form */ }
        { this.showComponent('CreditCardFormInline') && (
          <InvoiceFooter
            buttonLabel={ this.state.isInstallmentPlan ? 'Checkout & Schedule Installments' : 'Checkout' }
            isSubmitting={ this.state.isSubmitting }
            isDisabled={ isTeamBlockedFromPayments }
          />
        ) }

        { /* Render InvoiceFooter for Credit Card Modal */ }
        { this.showComponent('CreditCardFormModal') && (
          <InvoiceFooter
            icon="edit"
            buttonLabel="Update Credit Card"
            handleModalClick={ this.showCreditCardModal }
            note="* Update the credit card used for scheduled installment payments"
          />
        ) }
      </PageLayout>
      </>
    );
  }
}

PayInvoice.propTypes = {
  history: PropTypes.shape(historyPropShape).isRequired,
  loadInvoicePaymentDetails: PropTypes.func.isRequired,
  savePaymentMethod: PropTypes.func.isRequired,
  updatePaymentMethod: PropTypes.func.isRequired,
  reduxFormSubmit: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  uuid: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  paymentSubmitted: PropTypes.bool.isRequired,
  paymentUpdateStatus: PropTypes.string,
  invoice: PropTypes.shape(invoicePropShape),
  group: PropTypes.shape(groupPropShape),
  dateFormat: PropTypes.string,
  currency: PropTypes.shape(currencyPropShape),
  invoiceLineItems: PropTypes.arrayOf(PropTypes.object),
  invoicePaymentOptionAmounts: PropTypes.shape(invoicePaymentOptionAmountsPropShape),
  invoicePayments: PropTypes.arrayOf(PropTypes.object),
  invoicePaymentSchedule: PropTypes.shape({ id: PropTypes.string }),
  invoiceSchedulePaymentsPaymentOptionAmounts: PropTypes.arrayOf(PropTypes.object),
  invoiceSchedulePaymentOptionAmounts: PropTypes.shape(invoiceSchedulePaymentOptionAmountsPropShape),
  invoicePaymentError: PropTypes.string,
  isTeamBlockedFromPayments: PropTypes.bool,
};

PayInvoice.defaultProps = {
  paymentUpdateStatus: null,
  invoice: {},
  group: {},
  dateFormat: null,
  currency: {},
  invoiceLineItems: [],
  invoicePaymentOptionAmounts: {},
  invoicePayments: [],
  invoicePaymentSchedule: {},
  invoiceSchedulePaymentsPaymentOptionAmounts: [],
  invoiceSchedulePaymentOptionAmounts: {},
  invoicePaymentError: '',
  isTeamBlockedFromPayments: false,
};

export default PayInvoice;
