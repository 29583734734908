/**
 * Invoice Payment Actions
 *
 * Various actions using redux-thunk and axios for managing communication with API and redux store
 *
 */
import api from 'services/api';
import { getCollection } from 'state/baseActions';

export const INVOICE_PAYMENT = 'INVOICE_PAYMENT';

// TODO: Some naming 'issues' have occured.  We need to separate out invoicePayment && invoicePaymentTransaction

// Load Invoice Payments
export const loadInvoicePayments = (params) => (dispatch) =>
  dispatch(getCollection(INVOICE_PAYMENT, 'invoice_payments/search', params));

// Create Invoice Payments
export const createInvoicePayment = (invoicePayment) => () => {
  const paymentURL = invoicePayment.kind === 'check' ? 'pay_offline_check' : 'pay_offline_cash';

  return api
    .cmd(`invoice_payments/${paymentURL}`, invoicePayment)
    .then((response) => Promise.resolve(response.items))
    .catch((error) => Promise.reject(error));
};

// Create Invoice Refund
export const createInvoiceRefund = (invoiceRefund) => () => {
  let refundUrl = 'refund_online_payment';

  if (invoiceRefund.kind === 'check') {
    refundUrl = 'refund_offline_check';
  } else if (invoiceRefund.kind === 'cash') {
    refundUrl = 'refund_offline_cash';
  }

  const refund = {
    amount: invoiceRefund.amount,
    detail: invoiceRefund.detail,
    invoice_payment_id: invoiceRefund.id,
  };

  return api
    .cmd(`invoice_payments/${refundUrl}`, refund)
    .then((response) => Promise.resolve(response.items))
    .catch((error) => Promise.reject(error));
};
