import PropTypes from 'prop-types';

export const currencyPropShape = {
  countryCode: PropTypes.string,
};

export const stripePropShape = {
  createToken: PropTypes.func.isRequired,
};

export const invoicePaymentPropShape = {
  id: PropTypes.string,
  grossAmount: PropTypes.number,
  grossAmountWithCurrency: PropTypes.string,
  amountRefunded: PropTypes.number,
  amount: PropTypes.number,
  scheduledAt: PropTypes.string,
  amountWithProcessingFeeWithCurrency: PropTypes.string,
  transactions: PropTypes.arrayOf(PropTypes.shape({})),
};

export const paymentPropShape = {
  scheduledAt: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  lastTransactedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  status: PropTypes.string,
  grossAmountWithCurrency: PropTypes.string,
  id: PropTypes.string,
};

export const invoicePaymentOptionAmountsPropShape = {
  invoiceBalance: PropTypes.number,
  invoiceBalanceWithCurrency: PropTypes.string,
  processingFee: PropTypes.number,
  processingFeeWithCurrency: PropTypes.string,
  paymentTotal: PropTypes.number,
  paymentTotalWithCurrency: PropTypes.string,
};

export const invoiceSchedulePaymentOptionAmountsPropShape = {
  paymentScheduleTotalWithProcessingFeeTotalWithCurrency: PropTypes.string,
};
