import PropTypes from 'prop-types';
import { Component } from 'react';
import { Link } from 'react-router-dom';

import { getGroupId, getGroupParams, getGroupType } from 'utils/group';
import {
  linkFinancialAccounts,
  linkFinancialReports,
  linkInvoice,
  linkInvoices, linkInvoicing, linkInvoicingAccount, linkInvoicingNew, linkPaymentIndexList, linkPaymentSettings
} from 'utils/links';

import ExportButton from 'components/baseComponents/ExportButton';
import InvoiceStatus from 'components/baseComponents/InvoiceStatus';
import InvoicingOverview from 'components/baseComponents/InvoicingOverview';
import InvoicingPlaceholder from 'components/baseComponents/InvoicingPlaceholder';
import CancelInvoice from 'components/baseComponents/ModalCancelInvoice';
import DeleteBatchInvoice from 'components/baseComponents/ModalDeleteBatchInvoice';
import DeleteInvoice from 'components/baseComponents/ModalDeleteInvoice';
import ModalSendBatch from 'components/baseComponents/ModalSendBatch';
import SendInvoice from 'components/baseComponents/ModalSendInvoice';
import Tabs from 'components/baseComponents/Tabs';

import { Header, Loader, Popover } from 'components/teamsnapUIExtensions';
import Banner from 'components/teamsnapUIExtensions/Banner';
import Table from 'components/teamsnapUIExtensions/Table';

import { Button, ButtonGroup, Icon, TextLink } from '@teamsnap/teamsnap-ui';
import { Icon as SnapUIIcon, ModalContainer, LabelButton, Banner as SnapUIBanner } from '@teamsnap/snap-ui';
import { formatDate } from 'utils/date-service';
import styles from './Invoicing.module.scss';

import { batchInvoicePropShape } from 'interfaces/batchInvoice';
import { invoicePropShape } from 'interfaces/invoice';
import { historyPropShape, locationPropShape, matchPropShape } from 'interfaces/navigation';

import { StripeIdentityBanner } from 'frontend-toolkit';

import { featureRollout, isTeam, organizationId, organizationJsUrl, programId, seasonId, userIsTeamOwner } from 'utils/window';
import AccountSetupModal from 'components/baseComponents/AccountSetupModal';
import { shouldShowManageAccountButton, shouldShowTeamBanner } from 'utils/teamAccount';

class Invoicing extends Component {
  state = {
    invoicingType: 'batchInvoices',
    batchInvoices: {
      columns: [],
      rows: [],
    },
    invoices: {
      columns: [],
      rows: [],
    },
    exportInvoices: {
      columns: [],
      rows: [],
    },
    isFirstFetching: true,
    accountSetupRequired: true,
    showModal: false,
    showDepositAccountModal: false,
  };

  componentDidMount() {
    const { match: { params: { divisionId } } } = this.props;
    this.props.fetchCanUpdate(divisionId);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const {
      batchInvoices,
      invoices,
      loadBatchInvoiceAggregates,
      loadBatchInvoices,
      match: { params },
    } = this.props;

    const groupParams = getGroupParams(params);

    this.setState({
      batchInvoices: {
        columns: this.batchInvoiceColumns,
        rows: this.mapBatchInvoiceRows(batchInvoices),
      },
      invoices: {
        columns: this.invoiceColumns,
        rows: this.mapInvoiceRows(invoices),
      },
      exportInvoices: {
        columns: this.invoiceColumns.filter((__, i, arr) => i < arr.length - 1),
        rows: this.formatRowsForExport(invoices),
      }
    });

    Promise.all([
      loadBatchInvoices(groupParams),
      loadBatchInvoiceAggregates(groupParams),
    ]).then(() => this.setState({ ...this.state, isFirstFetching: false }));
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { batchInvoices, invoices } = nextProps;

    this.setState({
      batchInvoices: { ...this.state.batchInvoices, rows: this.mapBatchInvoiceRows(batchInvoices) },
      invoices: { ...this.state.invoices, rows: this.mapInvoiceRows(invoices) },
      exportInvoices: { ...this.state.exportInvoices, rows: this.formatRowsForExport(invoices) }
    });
  }

  invoiceColumns = [
    { label: 'Recipient', key: 'recipient', sortable: true, filterable: true, className: 'Panel-cell u-size3of24' },
    { label: 'Batch Title', key: 'batch', sortable: true, filterable: true, className: 'Panel-cell u-size3of24' },
    { label: 'Due Date', key: 'dueDate', sortKey: 'sortDueDate', sortable: true, className: 'Panel-cell u-size3of24' },
    { label: 'Status', key: 'status', sortable: true, filterable: true, className: 'Panel-cell u-size3of24' },
    { label: 'Total', key: 'total', sortable: true, className: 'Panel-cell u-textRight' },
    { label: 'Balance', key: 'balance', sortable: true, className: 'Panel-cell u-textRight' },
    { label: 'Actions', key: 'actions', className: 'Panel-cell u-textCenter' },
  ];

  batchInvoiceColumns = [
    { label: 'Created', key: 'createdAt', sortKey: 'sortCreatedAt', sortable: true, className: 'Panel-cell' },
    { label: 'Batch Title', key: 'titleLink', sortable: true, filterable: true, className: 'Panel-cell u-size3of24' },
    { label: 'Status', key: 'batchStatus', sortable: true, filterable: true, className: 'Panel-cell u-size2of24' },
    { label: 'Total', key: 'amountInvoicedWithCurrency', sortable: true, className: 'Panel-cell u-textRight' },
    { label: 'Payments', key: 'amountPaidWithCurrency', sortable: true, className: 'Panel-cell u-textRight' },
    // eslint-disable-next-line
    {
      label: 'Refunds',
      key: 'paymentAdjustmentsAmountWithCurrency',
      sortable: true,
      className: 'Panel-cell u-size1of24 u-textRight',
    },
    { label: 'Balance', key: 'amountDueWithCurrency', sortable: true, className: 'Panel-cell u-textRight' },
    { label: 'Paid', key: 'invoicesPaidCount', sortable: true, className: 'Panel-cell u-textRight' },
    { label: 'Open', key: 'invoicesUnpaidCount', sortable: true, className: 'Panel-cell u-textRight' },
    { label: 'Actions', key: 'actions', className: 'Panel-cell u-textCenter' },
  ];

  showCancelModal = (id) =>
    this.props.showModal(CancelInvoice, { id, handleConfirm: this.props.cancelBatchInvoice, isBatch: true });

  showDeleteModal = (id) =>
    this.props.showModal(DeleteBatchInvoice, { id, handleConfirm: this.props.deleteBatchInvoice });

  showSendBatchModal = (id) => this.props.showModal(ModalSendBatch, { batchId: id });

  showSendInvoiceModal = (invoice) => {
    const { showModal } = this.props;
    const { batchInvoiceId: batchId, invoiceableId: invoiceRecipientIds } = invoice;
    return showModal(SendInvoice, { invoiceRecipientIds, batchId });
  };

  showDeleteInvoiceModal = (id) => {
    const { showModal, deleteInvoice } = this.props;
    return showModal(DeleteInvoice, { id, handleConfirm: deleteInvoice });
  };

  showCancelInvoiceModal = (id, batchInvoiceId) => {
    const { showModal, cancelInvoice } = this.props;
    return showModal(CancelInvoice, { id, batchInvoiceId, handleConfirm: cancelInvoice });
  };

  handleViewChange = (view) => {
    this.setState({ invoicingType: view });
  };

  mapInvoiceRows = (invoices) =>
    invoices.map((invoice) => ({
      ...invoice,
      recipient: (
        <Link
          to={ linkInvoice(invoice[getGroupType(this.props.match.params)], invoice.batchInvoiceId, invoice.id) }
          data-sort={ invoice.invoiceTo || '' }
        >
          { invoice.invoiceTo || '' }
        </Link>
      ),
      batch: invoice.title,
      dueDate: formatDate(invoice.dueAt, this.props.dateFormat),
      sortDueDate: invoice.dueAt.valueOf(),
      status: this.renderInvoiceStatus(invoice),
      total: invoice.subTotalWithCurrency,
      balance: invoice.balanceWithCurrency,
      actions: this.renderActionPopoverInvoiceList(invoice),
    }));

  mapBatchInvoiceRows = (batchInvoices) =>
    batchInvoices.map((batchInvoice) => ({
      ...batchInvoice,
      createdAt: formatDate(batchInvoice.createdAt, this.props.dateFormat),
      sortCreatedAt: batchInvoice.createdAt.valueOf(),
      titleLink: (
        <Link
          to={ linkInvoices(batchInvoice[getGroupType(this.props.match.params)], batchInvoice.id) }
          data-sort={ batchInvoice.title }
        >
          { batchInvoice.title }
        </Link>
      ),
      batchStatus: this.renderStatus(batchInvoice),
      // delete total from amountInvoiced when api updates
      amountInvoicedWithCurrency: batchInvoice.amountInvoicedWithCurrency || batchInvoice.amountTotalWithCurrency,
      paymentAdjustmentsAmountWithCurrency: batchInvoice.paymentAdjustmentsAmountWithCurrency,
      invoicesUnpaidCount: batchInvoice.invoicesUnpaidCount,
      actions: this.renderActionPopover(batchInvoice),
    }));

    subTextStatus = (invoice) => {
      const { status, hasPaymentSchedule, failedInvoicePaymentsCount } = invoice;

      if (failedInvoicePaymentsCount > 0) {
        const errorText = failedInvoicePaymentsCount === 1 ? 'error' : 'errors';
        return `(${errorText})`;
      }

      if (status === 'open' && hasPaymentSchedule === 'true') {
        return '(scheduled)';
      }
      return '';
    };

    formattedStatus = (invoice) => {
      const { status } = invoice;
      const subText = this.subTextStatus(invoice);

      return `${status} ${subText}`;
    };

   formatRowsForExport = (invoices) =>
     invoices.map((invoice) => ({
       ...invoice,
       recipient: invoice.invoiceTo.replace(/,/g, ''),
       batch: invoice.title.replace(/,/g, ''),
       dueDate: formatDate(invoice.dueAt, this.props.dateFormat),
       sortDueDate: invoice.dueAt.valueOf(),
       status: this.formattedStatus(invoice),
       total: invoice.subTotalWithCurrency.replace(/,/g, ''),
       balance: invoice.balanceWithCurrency.replace(/,/g, ''),
     }));

  renderStatus = (batchInvoice) => (
    <div data-sort={ batchInvoice.status } data-filter={ batchInvoice.status }>
      <InvoiceStatus
        status={ batchInvoice.status }
        pastDueCount={ batchInvoice.invoicesPastDueCount }
        errorCount={ batchInvoice.failedInvoicesCount }
        currentValue={ batchInvoice.amountDue }
        totalValue={ batchInvoice.amountInvoiced }
        includeCounts
      />
    </div>
  );

  renderInvoiceStatus = (invoice) => (
    <div data-sort={ invoice.status } data-filter={ invoice.status }>
      <InvoiceStatus
        status={ invoice.status }
        hasPaymentSchedule={ invoice.hasPaymentSchedule }
        errorCount={ invoice.failedInvoicePaymentsCount }
        currentValue={ invoice.balance }
        totalValue={ invoice.subTotal }
      />
    </div>
  );

  renderActionPopover = (batchInvoice) => {
    const {
      match: { params },
    } = this.props;

    const actionItems = [
      <Link key={ 'view' } to={ linkInvoices(batchInvoice[getGroupType(params)], batchInvoice.id) }>
        View Invoice Batch
      </Link>,
    ];

    if (batchInvoice.isCancelable) {
      actionItems.push(
        <TextLink location={ null } key={ 'resend' } onClick={ () => this.showSendBatchModal(batchInvoice.id) }>
          Resend Invoice
        </TextLink>,
        <TextLink location={ null } key={ 'cancel' } onClick={ () => this.showCancelModal(batchInvoice.id) }>
          Cancel Invoice Batch
        </TextLink>,
      );
    }

    if (batchInvoice.isDeletable) {
      actionItems.push(
        <TextLink location={ null } key={ 'delete' } onClick={ () => this.showDeleteModal(batchInvoice.id) }>
          Delete Invoice Batch
        </TextLink>,
      );
    }

    return <Popover items={ actionItems } />;
  };

  renderActionPopoverInvoiceList = (invoice) => {
    const {
      match: { params },
    } = this.props;

    const actionItems = [
      <Link className="GI-snapView" key={ 'view' } to={ linkInvoice(invoice[getGroupType(params)], invoice.batchInvoiceId, invoice.id) }>
        View Invoice
      </Link>,
    ];

    if (invoice.status === 'open') {
      actionItems.push(
        <TextLink className="GI-snapResend" location={ null } key={ 'message' } onClick={ () => this.showSendInvoiceModal(invoice) }>
          Resend Invoice
        </TextLink>,
      );
    }

    if (invoice.isCancelable === 'true') {
      actionItems.push(
        <TextLink
          className="GI-snapCancel"
          location={ null }
          key={ 'cancel' }
          onClick={ () => this.showCancelInvoiceModal(invoice.id, invoice.batchInvoiceId) }
        >
          Cancel Invoice
        </TextLink>,
      );
    }
    if (invoice.isDeletable) {
      actionItems.push(
        <TextLink className="GI-snapDelete" location={ null } key={ 'delete' } onClick={ () => this.showDeleteInvoiceModal(invoice.id) }>
          Delete Invoice
        </TextLink>,
      );
    }
    return <Popover items={ actionItems } />;
  };

  replaceLocationUrl = (url) => window.location.assign(url);

  renderButtonGroup = () => {
    const {
      isPaymentProviderWePay,
      isPaymentProviderStripe,
      paymentAccountIsLoaded,
      paymentAccountSetupComplete,
      depositAccountIsFetching,
      depositAccount,
      hasActiveClassicPayments,
      match: { params },
      history,
    } = this.props;

    const groupId = getGroupId(params);
    const groupType = getGroupType(params);
    const promptStageTwo = paymentAccountIsLoaded && !paymentAccountSetupComplete;
    let buttonGroups = [];

    if (isPaymentProviderWePay && groupType === 'divisionId') {
      buttonGroups.push({
        label: 'Payment Settings',
        icon: 'settings',
        iconPosition: 'left',
        onClick: () => this.replaceLocationUrl(linkPaymentSettings(groupId)),
      });
      buttonGroups.push({
        label: 'Invoicing',
        icon: 'money',
        iconPosition: 'left',
        isActive: true,
        onClick: () => history.push(linkInvoicing(groupId)),
      });
    } else if (isPaymentProviderStripe && groupType === 'teamId') {
      buttonGroups = [
        {
          label: 'Deposit Account',
          icon: promptStageTwo ? 'alert' : 'check',
          onClick: () => {
            if (this.isVisaMandateTeam() && !depositAccountIsFetching && depositAccount) {
              this.replaceLocationUrl(linkInvoicingAccount(groupId));
            }
            else {
              this.replaceLocationUrl(linkFinancialAccounts(groupId));
            }
          },
        },
        {
          label: 'Invoices',
          isActive: true,
          icon: 'credit-card',
          onClick: () => history.push(linkInvoicing(groupId)),
        },
        {
          label: 'Financial Reporting',
          icon: 'statistics',
          onClick: () => this.replaceLocationUrl(linkFinancialReports(groupId)),
        },
      ];
    }

    if (hasActiveClassicPayments) {
      buttonGroups.unshift({
        label: 'Classic Payments',
        icon: 'money',
        onClick: () => this.replaceLocationUrl(linkPaymentIndexList(groupId)),
      });
    }

    return buttonGroups.length ? (
      <ButtonGroup buttons={ buttonGroups } style={ { marginLeft: 'auto' } } mods="u-spaceRightSm" />
    ) : (
      <span className={ styles.Filler } />
    );
  };

  showSetUpPaymentAccountBanner = () => {
    const {
      paymentAccountCurrentlyDueRequirements,
      paymentAccountCurrentDeadline,
      paymentAccountDisabledReason,
      paymentAccount,
      depositAccount,
    } = this.props;

    if ( this.isVisaMandateTeam() && shouldShowTeamBanner(depositAccount)) {
      return true;
    }

    const hasRequirements =
      (paymentAccountCurrentlyDueRequirements && paymentAccountCurrentlyDueRequirements.length > 0) ||
      paymentAccountCurrentDeadline !== null ||
      paymentAccountDisabledReason !== null;


    return (this.isLeague()) && (hasRequirements || !paymentAccount);
  }

  isUnderReview = () => {
    const {
      paymentAccountDisabledReason,
    } = this.props;

    const underReviewReasons = [
      'under_review',
      'requirements.pending_verification',
      'platform_paused',
      'listed'
    ];

    return (this.isLeague() && underReviewReasons.includes(paymentAccountDisabledReason));
  }

  isLeague = () => window.location.href.indexOf('league_payments') !== -1; // This should only show for leagues not teams.

  isVisaMandateTeam = () => isTeam && featureRollout('invoicing_frontend_visa_mandate_standalone')

  launchDepositAccountOnboarding = async () => {
    const onboardingUrl =
        await this.props.createDepositOnboardingLink(getGroupId(this.props.match.params), window.location.href);
    if (onboardingUrl) {
      window.open(onboardingUrl, '_blank');
    }
  }

  onBannerClick = async () => {
    if (this.isLeague() && organizationId && programId && seasonId) {
      this.replaceLocationUrl(`${organizationJsUrl}/organizations/${organizationId}/programs/${programId}/seasons/${seasonId}/financials`);
    }
    else if (this.isVisaMandateTeam()) {
      this.launchDepositAccountOnboarding();
    }
    else {
      const {
        match: { params },
        location: { pathname },
        analyticEvent,
      } = this.props;

      analyticEvent({
        url: pathname,
        eventCategory: 'Payments',
        eventAction: 'Complete Account',
      });

      this.replaceLocationUrl(linkFinancialAccounts(getGroupId(params)));
    }
  };

  onSendNewInvoiceClick = () => {
    const {
      paymentAccountChargesEnabled,
      paymentAccountPayoutsEnabled,
      paymentAccount,
      depositAccount
    } = this.props;

    if(this.isVisaMandateTeam() && paymentAccount && !depositAccount) {
      // for a non-migrated payment account, don't show the modal, continue as before
      this.setState({ ...this.state, showDepositAccountModal: false });
    }
    else if(this.isVisaMandateTeam() && (!depositAccount || (depositAccount.status !== 'approved' && depositAccount.status !== 'restricted_soon')) ) {
      this.setState({ ...this.state, showDepositAccountModal: true });
      return;
    }

    const {
      match: { params },
      history,
    } = this.props;

    if (
        (this.isLeague()) &&
        (!paymentAccountChargesEnabled || !paymentAccountPayoutsEnabled || !paymentAccount)
      ) {
      this.setState({
        ...this.state,
        showModal: true
      });
    } else {
      history.push(linkInvoicingNew(getGroupId(params)));
    }
  }

  getBannerText = (status) => {
    if (!this.isVisaMandateTeam() || !status){
      return `Account setup needs to be completed before you can receive ${this.isLeague() ? 'payments and ' : ''}deposits.`;
    }
    if (status === 'information_required' || status === 'restricted_soon' || status === 'rejected') {
      return 'Please provide required details to enable payment processing.';
    }
    if (status === 'under_review'){
      return 'Wait for account verification.';
    }
  }

  render() {
    const {
      invoicesIsFetching,
      batchInvoiceAggregates,
      match: { params },
      paymentAccount,
      paymentAccountIsLoaded,
      paymentAccountSetupComplete,
      depositAccountIsFetching,
      depositAccount,
      isPaymentProviderStripe,
      isPaymentProviderWePay,
      loadInvoices,
      canUpdate,
      history
    } = this.props;
    const groupParams = getGroupParams(params);
    const currentBatchInvoices = this.state.batchInvoices;
    const currentInvoices = this.state.invoices;
    const promptStageTwo = paymentAccountIsLoaded && !paymentAccountSetupComplete && isPaymentProviderStripe;
    const formattedExportRows = this.state.exportInvoices.rows;
    const formattedExportColumns = this.state.exportInvoices.columns;
    const showCompleteAccountBanner = promptStageTwo && !this.isLeague();
    const groupId = getGroupId(params);

    // Return Loader if still fetching items
    if (
      this.state.isFirstFetching ||
      (this.isVisaMandateTeam() && (!paymentAccountIsLoaded || depositAccountIsFetching))
      ) {
      return <Loader message="random" />;
    }
    if (this.isVisaMandateTeam() && paymentAccountIsLoaded && !paymentAccount) {
      // for no payment account, redirect to account setup
      history.push(linkInvoicingAccount(groupId));
    }

    return (
      <section>
        { paymentAccountSetupComplete && isPaymentProviderStripe &&
          !this.isLeague() && !depositAccount &&
          <StripeIdentityBanner/>
        }
        <Header size={ 1 } componentStyles={ styles } title="Invoices">
          { getGroupType(params) === 'divisionId' && (
            <a
              className={ styles.HelpIcon }
              target="_blank"
              rel="noopener noreferrer"
              title="learn more about invoicing"
              href={ 'https://helpme.teamsnap.com/article/597-invoicing-overview' }
            >
              <Icon name={ 'info' } />
            </a>
          ) }
          { this.renderButtonGroup() }
          <Button color="primary" icon="plus" onClick={ () => this.onSendNewInvoiceClick() }
            isDisabled={ !paymentAccountIsLoaded || (this.isVisaMandateTeam() && depositAccountIsFetching) }
          >
            Send New Invoice
          </Button>
          { formattedExportRows.length ? (
            <ExportButton
              rows={ formattedExportRows }
              columns={ formattedExportColumns }
            />
          ) : (
            <div />
          ) }
        </Header>

        { canUpdate && paymentAccountIsLoaded &&
          (showCompleteAccountBanner || this.showSetUpPaymentAccountBanner()) && (
            <>
              { this.isVisaMandateTeam() ?
              (
              <SnapUIBanner
                  className="sui-my-3"
                  sentiment="negative"
                  title="Deposit account pending verification"
                  caption={ this.getBannerText(depositAccount? depositAccount.status : null) }
                  action={ shouldShowManageAccountButton(depositAccount, userIsTeamOwner) && {
                    onClick: this.onBannerClick,
                  } }
              />
              ) : (
                <Banner
                  color="highlight"
                  icon="notifications"
                  title={ this.isUnderReview() ? 'Deposit Account Setup Under Review' : 'Deposit Account Setup Pending' }
                  children={ this.isUnderReview() ?
                    <>
                      Account may be under review for multiple days, during this time, payouts may be disabled.
                    </>
                    :
                    <>
                      Account setup needs to be completed before you can receive { this.isLeague() ? 'payments and ' : '' }deposits.
                      {
                        <TextLink onClick={ () => this.onBannerClick() } location={ null } style={ { marginLeft: '10px' } }>
                          Complete Account
                        </TextLink>
                      }
                    </>
                  }
                />
              ) }
            </>
        ) }

        <InvoicingOverview aggregateData={ batchInvoiceAggregates } onViewChange={ this.handleViewChange } />

        <Tabs>
          <div label="Batches" data-amp-b2b-click-event='Season Invoice Click' data-amp-b2b-event-props='{"season_invoice_tab_clicked": "Batch"}'>
            { currentBatchInvoices.rows.length ? (
              <Table { ...currentBatchInvoices } defaultSort={ 'sortCreatedAt' } reverseSort />
            ) : (
              <InvoicingPlaceholder
                title="No Invoices Sent"
                bodyText="Easily send invoices to members of your organization and collect
                payments online. Send to as many people as you'd like and we will batch them
                together for you for easy management."
                buttonText="Send New Invoice"
                link={ () => this.onSendNewInvoiceClick() }
              />
            ) }
          </div>
          <div label="Invoices" data-amp-b2b-click-event='Season Invoice Click' data-amp-b2b-event-props='{"season_invoice_tab_clicked": "Invoice"}' onClick={ () => {
            if (currentInvoices.rows.length === 0) {
              loadInvoices(groupParams);
            }
          } }
          >

            { currentInvoices.rows.length || invoicesIsFetching ? (
              <Table { ...currentInvoices }
                loadingMessage={ invoicesIsFetching ? 'Loading...' : null }
                defaultSort={ 'recipient' }
              />
            ) : (
              <InvoicingPlaceholder
                title="No Invoices Sent"
                bodyText="Easily send invoices to members of your organization and collect
                payments online. Send to as many people as you'd like and we will batch them
                together for you for easy management."
                buttonText="Send New Invoice"
                link={ () => this.onSendNewInvoiceClick() }
              />
            ) }
          </div>
        </Tabs>
        <ModalContainer
          data-testid="ConnectAccountModal--container"
          disableOverlayClose={ true }
          closeButton={ false }
          isOpen={ this.state.showModal }
          onClose={ () => this.setState({ ...this.state, showModal: false }) }
          className={ 'sui-max-w-screen-t' }
        >
          <div>
            <div className="sui-flex sui-items-center">
              <SnapUIIcon name="report" size="l" filled className="sui-text-red-40" />
              <h3 className="sui-heading-sm sui-ml-2">Connect merchant account</h3>
            </div>
            <div className="sui-mt-3">
              <p className="sui-body">This invoice uses a restricted merchant account and cannot be sent until the merchant account is connected.</p>
            </div>
            <div className="sui-flex sui-justify-end sui-mt-5">
              <LabelButton
                labelText="Close"
                variantType="secondary"
                onClick={ () => this.setState({ ...this.state, showModal: false }) }
                data-testid="ConnectAccountModal--cancel"
                className="sui-mr-1 sui-cursor-pointer sui-h-6"
              />
              <LabelButton
                labelText="Connect now"
                variantType="primary"
                onClick={ () => {
                  if (this.isLeague() && organizationId && programId && seasonId) {
                    this.replaceLocationUrl(`${organizationJsUrl}/organizations/${organizationId}/programs/${programId}/seasons/${seasonId}/financials`);
                  } else {
                    this.replaceLocationUrl(linkFinancialAccounts(groupId));
                  }
                } }
                data-testid="ConnectAccountModal--continue"
                className="sui-cursor-pointer sui-h-6"
              />
            </div>
          </div>
        </ModalContainer>
        <AccountSetupModal
          isOpen={ this.state.showDepositAccountModal }
          onClose={ () => this.setState({ ...this.state, showDepositAccountModal: false }) }
          onAction={ shouldShowManageAccountButton(depositAccount, userIsTeamOwner) && (async () => {
            await this.launchDepositAccountOnboarding();
            this.setState({ ...this.state, showDepositAccountModal: false });
          }) }
        />
      </section>
    );
  }
}

Invoicing.propTypes = {
  match: PropTypes.shape(matchPropShape).isRequired,
  location: PropTypes.shape(locationPropShape).isRequired,
  history: PropTypes.shape(historyPropShape).isRequired,
  isFetching: PropTypes.bool.isRequired,
  batchInvoices: PropTypes.arrayOf(PropTypes.shape(batchInvoicePropShape)),
  loadBatchInvoiceAggregates: PropTypes.func.isRequired,
  loadBatchInvoices: PropTypes.func.isRequired,
  invoices: PropTypes.arrayOf(PropTypes.shape(invoicePropShape)),
  loadInvoices: PropTypes.func.isRequired,
  invoicesIsFetching: PropTypes.bool.isRequired,
  batchInvoiceAggregates: PropTypes.shape({}).isRequired,
  showModal: PropTypes.func.isRequired,
  cancelBatchInvoice: PropTypes.func.isRequired,
  cancelInvoice: PropTypes.func.isRequired,
  deleteBatchInvoice: PropTypes.func.isRequired,
  dateFormat: PropTypes.string.isRequired,
  isPaymentProviderWePay: PropTypes.bool.isRequired,
  isPaymentProviderStripe: PropTypes.bool.isRequired,
  needsRefresh: PropTypes.bool.isRequired,
  paymentAccountIsLoaded: PropTypes.bool.isRequired,
  paymentAccountSetupComplete: PropTypes.bool,
  paymentAccount: PropTypes.shape({}).isRequired,
  depositAccount: PropTypes.shape({}).isRequired,
  depositAccountIsFetching: PropTypes.bool.isRequired,
  createDepositOnboardingLink: PropTypes.func.isRequired,
  hasActiveClassicPayments: PropTypes.bool,
  analyticEvent: PropTypes.func.isRequired,
  canUpdate: PropTypes.bool,
  teamState: PropTypes.any,
  isTeam: PropTypes.bool
};

Invoicing.defaultProps = {
  batchInvoices: [],
  invoices: [],
  paymentAccountSetupComplete: false,
  hasActiveClassicPayments: false,
  canUpdate: false,
  teamState: null,
  isTeam: false,
};

export default Invoicing;
