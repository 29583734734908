import React from 'react';
import { ImgTeamSnapIcon } from 'components/baseComponents/Img';
import styles from '../PayInvoice.module.scss';

const PageHeader = () => (
  <header className={ styles.PageHeader }>
    <ImgTeamSnapIcon width="50" />
    <h2>TeamSnap Payments</h2>
  </header>
);

export default PageHeader;
