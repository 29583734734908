import { FETCH_DEPOSIT_ACCOUNT_ONBOARDING, SAVE_DEPOSIT_ACCOUNT_ONBOARDING, ERROR_DEPOSIT_ACCOUNT_ONBOARDING } from './actions';

export const initialState = {
  isFetching: false,
  isLoaded: false,
  isError: false,
  link: null,
};

const depositAccountOnboardingReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DEPOSIT_ACCOUNT_ONBOARDING:
      return {
        isFetching: true,
        isError: false,
        isLoaded: false,
        link: null,
      };
    case SAVE_DEPOSIT_ACCOUNT_ONBOARDING:
      return {
        isFetching: false,
        isError: false,
        isLoaded: true,
        link: action.payload.url,
      };
    case ERROR_DEPOSIT_ACCOUNT_ONBOARDING:
      return {
        isFetching: false,
        isError: true,
        isLoaded: false,
        link: null,
      };
    default:
      return state;
  }
};

export default depositAccountOnboardingReducer;
