/**
 * Batch Invoice Line Item Actions
 *
 * Various actions using redux-thunk and axios for managing communication with API and redux store
 *
 */

import { getCollection, createCollection, removeCollection } from 'state/baseActions';
import { formatUSD } from 'utils/currency';
import { selectBatchInvoiceLineItemsByBatchRoute, selectBatchInvoiceLineItemsByBatchInvoiceId } from './selectors';

export const BATCH_INVOICE_LINE_ITEM = 'BATCH_INVOICE_LINE_ITEM';

// Load Batch Invoice Line Items
export const loadBatchInvoiceLineItems = (params) => (dispatch) =>
  dispatch(
    getCollection(BATCH_INVOICE_LINE_ITEM, 'batch_invoice_line_items/search', params, {
      selectorCallback: selectBatchInvoiceLineItemsByBatchRoute,
    }),
  );

// Create Batch Invoice Line Item
export const createBatchInvoiceLineItem = (lineItem) => (dispatch) => {
  const formatted = {
    ...lineItem,
    amount: formatUSD(lineItem.amount),
  };
  return dispatch(
    createCollection(BATCH_INVOICE_LINE_ITEM, 'batch_invoice_line_items', {
      ...formatted,
      type: 'batchInvoiceLineItem',
    }),
  );
};

export const removeBatchInvoiceLineItemsByBatchInvoiceId = (batchInvoiceId) => (dispatch, getState) => {
  selectBatchInvoiceLineItemsByBatchInvoiceId(getState())(batchInvoiceId).forEach((batchInvoiceLineItem) =>
    dispatch(removeCollection(BATCH_INVOICE_LINE_ITEM, batchInvoiceLineItem)),
  );
  return Promise.resolve();
};
