/**
 * Batch Invoice Aggregate Actions
 *
 * Various actions using redux-thunk and axios for managing communication with API and redux store
 *
 */

import { getCollection } from 'state/baseActions';

export const BATCH_INVOICES_AGGREGATE = 'BATCH_INVOICES_AGGREGATE';

export const loadBatchInvoiceAggregates = (params) => (dispatch) =>
  dispatch(getCollection(BATCH_INVOICES_AGGREGATE, 'batch_invoices_aggregates/search', params));
