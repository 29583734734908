import { isDateValid } from 'utils/date-service';

/**
 * Field Validator
 *
 * Validates common field values and return an object containing the valid status
 * and appropriate message.
 * { valid: false, message: 'Field can not be blank'} || { valid: true, message: null }
 *
 * TODO:
 *  -> Add other validators as needed (isLessThan, betweenDates, isGreaterThan, isValidIP, isValidURL, etc)
 *
 */

// Set basic REGEX checks to help with validation rules
const REGEX = {
  alpha: /^[A-Z]+$/i,
  alphaPlus: /^[A-Z_'-\s]+$/i,
  alphaNumeric: /^[A-Z0-9]+$/i,
  alphaNumericDash: /^[A-Z0-9_-\s]+$/i,
  numeric: /^[0-9]+$/,
  numericDash: /^[\d\-\s]+$/,
  integer: /^-?[0-9]+$/,
  decimal: /^-?[0-9]*\.?[0-9]+$/,
  currency: /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$/,
  zipCode: /(^\d{5}(-\d{4})?$)|(^[A-Z]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$)/,
  zipCodeUs: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
  zipCodeCa: /^[A-Z]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/,
  // eslint-disable-next-line
  email: /^[a-z0-9!\#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*\.[a-z]{2,}$/i,
  fullName: /^[a-z-'’.]+\s+([a-z-'’.]*\s)*[a-z-'’.]+$/i,
};

// Luhn Algorithm - https://en.wikipedia.org/wiki/Luhn_algorithm
// A checksum formula used to validate a variety of identification numbers (including credit cards)
// Valid: 4003830171874018, 4539148803436467, Invalid: 8273123273520569, 5496198584584769
//
// The calculation:
//  1) From the rightmost digit, double the value of every second digit.
//  2) If the product of doubling is greater than 9, substract 9 from the product
//  3) Sum up all the digits
//  4) If the sum total modulo 10 is equal to 0 then the number is valid, else invalid
const checkLuhn = (numbers) => {
  const numberArray = numbers.replace(/\D/g, '').split('').reverse();
  let odd = true;

  const sum = numberArray.reduce((total, number) => {
    let digit = parseInt(number, 10);
    odd = !odd;

    if (odd) digit *= 2;
    if (digit > 9) digit -= 9;

    return total + digit;
  }, 0);

  return !(sum % 10);
};

// Response object returned with validity and error message
const isValid = (value, message) => {
  const valid = !!value;
  return { valid, message: valid ? null : message };
};

const isRequired = (value, message = 'Field can not be blank') => isValid(value && value.trim(), message);

const hasMinLength = (value, min, message = `Must be at least ${min} characters`) =>
  isValid(value && value.trim().length >= min, message);

const hasMaxLength = (value, max, message = `Must be ${max} characters or less`) =>
  isValid(value && value.trim().length <= max, message);

const hasLength = (value, length, message = `Must be ${length} characters`) =>
  isValid(value && value.trim().length === length, message);

const isAlpha = (value, message = 'Field must contain alphabetical characters only') =>
  isValid(value && REGEX.alpha.test(value), message);

const isAlphaPlus = (value, message = 'Field must contain alphabet, dashes or space characters only') =>
  isValid(value && REGEX.alphaNumericDash.test(value), message);

const isAlphaNumeric = (value, message = 'Field must contain alpha-numeric characters only') =>
  isValid(value && REGEX.alphaNumeric.test(value), message);

const isAlphaNumericDash = (value, message = 'Field must contain alpha-numeric, dashes or space characters only') =>
  isValid(value && REGEX.alphaNumericDash.test(value), message);

const isNumeric = (value, message = 'Field must contain numeric characters only') =>
  isValid(value && REGEX.numeric.test(value), message);

const isNumericDash = (value, message = 'Field must contain numeric or dash characters only') =>
  isValid(value && REGEX.numericDash.test(value), message);

const isInteger = (value, message = 'Field must contain integer characters only') =>
  isValid(value && REGEX.integer.test(value), message);

const isDecimal = (value, message = 'Field must contain decimal characters only') =>
  isValid(value && REGEX.decimal.test(value), message);

const isCurrency = (value, message = 'Field must contain numbers , and . only') =>
  isValid(value && REGEX.currency.test(value), message);

// eslint-disable-next-line
const isMaxLength = (max) => (value) => (value && value.length > max ? `Must be ${max} characters or less` : undefined);

// eslint-disable-next-line
const isValidDate = (dateFormat) => (value) =>
  value && !isDateValid(value, dateFormat) ? 'Date format must be MM/DD/YYYY' : undefined;

const isValidZip = (value, message = 'Field must contain a valid postal code') =>
  isValid(value && REGEX.zipCode.test(value), message);

const isValidZipUs = (value, message = 'Field must contain a valid postal code') =>
  isValid(value && REGEX.zipCodeUs.test(value), message);

const isValidZipCa = (value, message = 'Field must contain a valid postal code') =>
  isValid(value && REGEX.zipCodeCa.test(value), message);

const isValidEmail = (value, message = 'Field must contain a valid email address') =>
  isValid(value && REGEX.email.test(value.trim()), message);

const isValidCreditCard = (value, message = 'Field must contain a valid credit card number') =>
  isValid(value && REGEX.numericDash.test(value) && checkLuhn(value), message);

const isValidPayerName = (value, message = 'Field must contain a valid first and last name') =>
  isValid(value && REGEX.fullName.test(value), message);

const isValidPayerNameLength = (value, message = 'Name on card should be 50 characters or less') =>
  isValid(value && value.length <= 50, message);

export {
  isRequired,
  hasMinLength,
  hasMaxLength,
  hasLength,
  isAlpha,
  isAlphaPlus,
  isAlphaNumeric,
  isAlphaNumericDash,
  isNumeric,
  isNumericDash,
  isInteger,
  isDecimal,
  isCurrency,
  isMaxLength,
  isValidDate,
  isValidZip,
  isValidZipUs,
  isValidZipCa,
  isValidEmail,
  isValidCreditCard,
  isValidPayerName,
  isValidPayerNameLength,
};
