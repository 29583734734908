import React from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'utils/utils';
import { formatDate } from 'utils/date-service';
import { Grid, Cell } from '@teamsnap/teamsnap-ui';
import styles from '../PayInvoice.module.scss';
import { invoicePropShape } from 'interfaces/invoice';

const statusColors = {
  open: '#383838',
  paid: '#7FC243',
  pending: '#383838',
  submitted: '#7FC243',
  canceled: '#383838',
};

const InvoiceDetails = ({ invoice, dateFormat }) => {
  const status = invoice.status ? capitalize(invoice.status) : null;

  return (
    <div className={ styles.StatusHeading }>
      <Grid>
        <Cell mods="u-size1of2">
          <h3>{ invoice.title }</h3>
          <p>
            { invoice.invoiceTo && <span className={ styles.Label }>Recipient:</span> }
            <span className="fs-block">{ invoice.invoiceTo }</span>
          </p>
          <p>
            <span className={ styles.Label }>Date Issued:</span>
            { formatDate(invoice.createdAt, dateFormat) }
          </p>
          <p>
            <span className={ styles.Label }>Due Date:</span>
            { formatDate(invoice.dueAt, dateFormat) }
          </p>
        </Cell>

        <Cell mods="u-size1of2 u-textRight">
          <div className={ styles['StatusHeading-status'] }>
            Status: <span style={ { color: statusColors[invoice.status || 'open'] } }>{ status || 'N/A' }</span>
            { invoice.status === 'open' && invoice.hasPaymentSchedule && (
              <span className={ styles.HeadingInstallments }>
                * Payments Scheduled
                { /* * { remainingPayments(installments) } Installments */ }
              </span>
            ) }
          </div>
        </Cell>

        <Cell mods="u-size1of1">
          <p className={ styles.InvoiceDescription }>{ invoice.description }</p>
        </Cell>
      </Grid>
    </div>
  );
};

InvoiceDetails.propTypes = {
  invoice: PropTypes.shape(invoicePropShape).isRequired,
  dateFormat: PropTypes.string.isRequired,
};

export default InvoiceDetails;
