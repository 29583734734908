import React from 'react';
import { getYear } from 'utils/date-service';
import styles from '../PayInvoice.module.scss';

const PageFooter = () => (
  <footer className={ styles.InvoiceFooter }>
    <p>Copyright { getYear() } TeamSnap. All rights reserved</p>
    <p>
      <a href="https://www.teamsnap.com/privacy-policy">Privacy</a>
      &nbsp; | &nbsp;
      <a href="https://helpme.teamsnap.com/article/828-submit-a-team-invoice-payment">Need Help?</a>
    </p>
  </footer>
);

export default PageFooter;
