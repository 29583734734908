import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import ApplyAdjustmentForm from 'components/baseComponents/ApplyAdjustmentForm';
import Modal from 'components/teamsnapUIExtensions/Modal';
import { createInvoiceAdjustment } from 'state/invoiceLineItems/actions';
import { queryInvoiceWithMemberEmailCollections } from 'state/invoices/actions';
import { hideModal } from 'state/modal/actions';

class ModalInvoiceAdjustment extends Component {
  handleClose = () => {
    this.props.closeModal();
  };

  handleSumbit = (formProps) => {
    const { invoiceId, closeModal, createInvoiceAdjustment, loadInvoice } = this.props;

    createInvoiceAdjustment(invoiceId, formProps.description, -formProps.amount)
      .then(() => loadInvoice(invoiceId))
      .finally(() => closeModal());
  };

  render() {
    const modalProps = {
      handleClose: this.handleClose,
      className: 'ModalSmall',
      ...this.props,
    };

    return (
      <Modal { ...modalProps } title="Adjust Balance">
        <ApplyAdjustmentForm onSubmit={ this.handleSumbit } handleClose={ this.handleClose } />
      </Modal>
    );
  }
}

ModalInvoiceAdjustment.propTypes = {
  closeModal: PropTypes.func.isRequired,
  invoiceId: PropTypes.string.isRequired,
  createInvoiceAdjustment: PropTypes.func.isRequired,
  loadInvoice: PropTypes.func.isRequired,
};


export default connect(null, {
  closeModal: hideModal,
  createInvoiceAdjustment: createInvoiceAdjustment,
  loadInvoice: queryInvoiceWithMemberEmailCollections,
})(ModalInvoiceAdjustment);
