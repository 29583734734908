import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Cell, Button, SummaryList } from '@teamsnap/teamsnap-ui';
import { hideModal } from 'state/modal/actions';
import { formatDate } from 'utils/date-service';
import Modal from 'components/teamsnapUIExtensions/Modal';
import styles from './ModalInstallments.module.scss';

class ModalInstallments extends Component {
  handleClose = () => {
    this.props.closeModal();
  };

  render() {
    const paymentScheduleItems = this.props.paymentSchedule.map((item, index) => ({
      description: `Installment ${index + 2}`,
      value: formatDate(item.paymentDate, this.props.dateFormat),
      ...(item.paymentDate < Date.now() && { style: { color: '#999' } }),
    }));

    paymentScheduleItems.unshift({
      description: 'Installment 1',
      value: 'Collected at Checkout',
    });

    return (
      <Modal
        overlay
        title="Installments Schedule"
        handleClose={ this.handleClose }
        componentStyles={ styles }
        className="Modal"
        { ...this.props }
      >
        <div className={ styles.ModalBody }>
          <SummaryList hasBorder={ false } items={ paymentScheduleItems } />
        </div>

        <div className={ styles.ModalFooter }>
          <Grid>
            <Cell mods="u-size1of1 u-textRight">
              <Button
                type="button"
                onClick={ this.handleClose }
                label="Close"
                icon="dismiss"
                size="medium"
                color="negative"
              />
            </Cell>
          </Grid>
        </div>
      </Modal>
    );
  }
}

ModalInstallments.propTypes = {
  closeModal: PropTypes.func.isRequired,
  dateFormat: PropTypes.string.isRequired,
  paymentSchedule: PropTypes.array,
};

ModalInstallments.defaultProps = {
  paymentSchedule: [],
};

export default connect(null, {
  closeModal: hideModal,
})(ModalInstallments);
