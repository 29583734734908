/**
 * Batch Invoice Actions
 *
 * Various actions using redux-thunk and axios for managing communication with API and redux store
 *
 */

import api from 'services/api';
import {
  getCollection,
  createCollection,
  queryCollection,
  updateCollectionItem,
  deleteCollection,
} from 'state/baseActions';
import {
  BATCH_INVOICE_LINE_ITEM,
  removeBatchInvoiceLineItemsByBatchInvoiceId,
} from 'state/batchInvoiceLineItems/actions';
import { BATCH_INVOICE_PAYMENT_SCHEDULE_DETAIL } from 'state/batchInvoicePaymentScheduleDetails/actions';
import { INVOICE } from 'state/invoices/actions';
import { INVOICE_RECIPIENT } from 'state/invoiceRecipients/actions';
import { loadMemberEmailsFromInvoiceRecipients } from 'state/memberEmails/actions';
import { selectBatchInvoiceById } from 'state/batchInvoices/selectors';

export const BATCH_INVOICE = 'BATCH_INVOICE';

// Load Batch Invoices
export const loadBatchInvoices = (params) => (dispatch) =>
  dispatch(getCollection(BATCH_INVOICE, 'batch_invoices/search', params));

// Create Batch Invoice
export const createBatchInvoice = (batchInvoice) => (dispatch) =>
  dispatch(createCollection(BATCH_INVOICE, 'verified_batch_invoices', { ...batchInvoice, type: 'batchInvoice' }));

// Update Batch Invoice
export const updateBatchInvoice = (params) => (dispatch) =>
  api
    .patch(`batch_invoices/${params.id}`, params)
    .then((batchInvoice) => dispatch(updateCollectionItem(BATCH_INVOICE, batchInvoice.items[0])))
    .catch((error) => Promise.reject(error));

// Query BatchInvoices and BatchInvoiceAggregates
//  TODO - Research - This doesn't appear to work, 'type' is not returned with the aggregates...
// export const queryBatchInvoicesWithAggregates = divisionId => dispatch => dispatch(
//   queryCollection(
//     { BATCH_INVOICE, BATCH_INVOICES_AGGREGATE },
//     {
//       batchInvoice__divisionId: divisionId,
//       batchInvoicesAggregate__divisionId: divisionId
//     }
//   )
// )

// Query a Batch Invoice, batchInvoiceLineItems, Invoice, and InvoiceRecipients
export const queryBatchInvoiceCollections = (batchInvoiceId) => (dispatch) =>
  dispatch(
    queryCollection(
      {
        BATCH_INVOICE,
        BATCH_INVOICE_LINE_ITEM,
        BATCH_INVOICE_PAYMENT_SCHEDULE_DETAIL,
        INVOICE,
        INVOICE_RECIPIENT,
      },
      {
        batchInvoice__id: batchInvoiceId,
        batchInvoiceLineItem__batchInvoiceId: batchInvoiceId,
        batchInvoicePaymentScheduleDetail__batchInvoiceId: batchInvoiceId,
        invoice__batchInvoiceId: batchInvoiceId,
        invoiceRecipient__batchInvoiceId: batchInvoiceId,
      },
    ),
  );

export const queryBatchInvoiceWithLineItems = (batchInvoiceId) => (dispatch) =>
  dispatch(
    queryCollection(
      { BATCH_INVOICE, BATCH_INVOICE_LINE_ITEM, INVOICE_RECIPIENT },
      {
        batchInvoice__id: batchInvoiceId,
        batchInvoiceLineItem__batchInvoiceId: batchInvoiceId,
        invoiceRecipient__batchInvoiceId: batchInvoiceId,
      },
    ),
  );

export const queryBatchInvoiceWithMemberEmailCollections = (batchInvoiceId) => (dispatch) =>
  dispatch(queryBatchInvoiceCollections(batchInvoiceId)).then((response) =>
    dispatch(loadMemberEmailsFromInvoiceRecipients(response.invoiceRecipient)),
  );

export const cancelBatchInvoice = ({ id }) => (dispatch) =>
  api
    .cmd('batch_invoices/cancel', { id })
    .then(() => dispatch(queryBatchInvoiceCollections(id)))
    .catch((error) => Promise.reject(error));

export const deleteBatchInvoice = ({ id }) => (dispatch, getState) => {
  const item = selectBatchInvoiceById(getState(), id);
  return dispatch(deleteCollection(BATCH_INVOICE, 'batch_invoices', item)).then(() =>
    dispatch(removeBatchInvoiceLineItemsByBatchInvoiceId(id)),
  );
};
