import React from 'react';
import PropTypes from 'prop-types';
import styles from '../PayInvoice.module.scss';
import { invoicePropShape } from 'interfaces/invoice';

const InvoiceHeader = ({ invoice, billingAddress }) => (
  <div>
    <header className={ styles.InvoiceHeader }>
      <figure className={ styles.InvoiceLeagueInfo }>
        <figcaption>
          <h4>{ invoice.invoiceFrom }</h4>
          { billingAddress && (
            <p className="fs-block">
              <small>{ billingAddress }</small>
            </p>
          ) }
        </figcaption>
      </figure>
      <div>
        <p>
          <small>#{ invoice.id }</small>
        </p>
      </div>
    </header>

    <hr className={ styles.Divider } />
  </div>
);

InvoiceHeader.propTypes = {
  invoice: PropTypes.shape(invoicePropShape).isRequired,
  billingAddress: PropTypes.string,
};

InvoiceHeader.defaultProps = {
  billingAddress: null,
};

export default InvoiceHeader;
