/**
 * StepNav Component
 *
 * MultiStep Navigation for 'wizard' like components
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router'
import { Header } from 'components/teamsnapUIExtensions';
import { Icon } from '@teamsnap/teamsnap-ui';
import defaultStyles from './StepNav.module.scss';

// Navigation Step Classes
const navClasses = {
  enabled: 'StepNavStepIsEnabled',
  active: 'StepNavStepIsEnabledIsActive',
  default: 'StepNavStep',
};

class StepNav extends PureComponent {
  renderStep = (step, styles) => {
    const stepClass = navClasses[step.status || 'default'];
    // TEMP: Remove links from navigation
    const StepWrapper = 'span';
    // const StepWrapper = step.link ? Link : 'span'

    return (
      <li key={ step.name } className={ styles[stepClass] }>
        <StepWrapper to={ step.link } className={ styles.StepNavStepLink }>
          <div className={ styles.StepNavStepIcon }>
            <Icon name={ step.icon } style={ { fontSize: '1.5em', ...step.iconStyle } } />
          </div>

          <span className={ styles.StepNavStepTitle }>{ step.name }</span>
        </StepWrapper>
      </li>
    );
  };

  render() {
    const { title, steps, componentStyles } = this.props;
    const styles = { ...defaultStyles, ...componentStyles };
    const className = this.props.className || (title ? 'StepNavTitled' : 'StepNav');

    return (
      <section className={ styles[className] }>
        { title && <Header componentStyles={ styles } size={ 2 } title={ title } /> }

        <ol className={ styles.StepNavSteps }>{ steps.map((step) => this.renderStep(step, styles)) }</ol>
      </section>
    );
  }
}

StepNav.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      link: PropTypes.string,
      status: PropTypes.string,
    }),
  ).isRequired,
  title: PropTypes.string,
  componentStyles: PropTypes.shape({}),
  className: PropTypes.string,
};

StepNav.defaultProps = {
  title: null,
  componentStyles: {},
  className: null,
};

export default StepNav;
