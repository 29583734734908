import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@teamsnap/teamsnap-ui';
import { Modal, Loader } from 'components/teamsnapUIExtensions';
import { connect } from 'react-redux';
import { hideModal } from 'state/modal/actions';
import { showFeedback } from 'state/feedback/actions';
import { SUCCESS, ERROR } from 'components/teamsnapUIExtensions/Feedback/Feedback';
import styles from './ModalCancelInvoice.module.scss';

class ModalCancelInvoice extends PureComponent {
  state = {
    disableButton: false,
  };

  handleClose = () => {
    this.props.hideModal();
  };

  handleConfirm = () => {
    const { isBatch, handleConfirm } = this.props;

    this.setState({
      disableButton: true,
    });

    const feedbackSuccessProps = {
      title: 'Success!',
      message: isBatch ? 'The invoices have been canceled.' : 'The invoice has been canceled.',
      type: SUCCESS,
    };

    const feedbackErrorProps = {
      title: 'Oops!',
      // eslint-disable-next-line
      message:
        'Something went wrong on our end. Please try again, and if the error persists let us know at https://helpme.teamsnap.com/?solvvy=true so we can see what\'s going on.',
      type: ERROR,
    };

    handleConfirm(this.props)
      .then(() => this.props.showFeedback(feedbackSuccessProps))
      .catch(() => this.props.showFeedback(feedbackErrorProps))
      .finally(() => this.handleClose());
  };

  render() {
    const { disableButton } = this.state;
    const { isBatch } = this.props;
    const modalProps = {
      handleClose: this.handleClose,
      className: styles.ModalSmall,
      ...this.props,
    };

    return (
      <Modal
        { ...modalProps }
        title={ isBatch ? 'Cancel Invoices' : 'Cancel Invoice' }
        componentStyles={ styles }
        headerIcon="alert"
      >
        <div className={ styles.ModalBody }>
          { !disableButton && <h4 className="u-padBottomMd">If you cancel this invoice, then (cue dramatic music):</h4> }
          { disableButton && <Loader icon="spin" text={ isBatch ? 'Canceling Open Invoices' : 'Canceling Invoice' } /> }
          { !disableButton && (
            <ul className="u-padLeftMd">
              <li>You will no longer be able to collect payment on { isBatch ? 'these invoices' : 'this invoice' }.</li>
              <li>An adjustment will be added to bring the balance to zero.</li>
              <li>If there were any payments, they will still be shown on the invoice and the status will be paid.</li>
              <li>You can do a refund if you do not want to keep those payments.</li>
            </ul>
          ) }
        </div>

        <footer className={ styles.ModalFooter }>
          <Button
            color="negative"
            icon="dismiss"
            onClick={ this.handleClose }
            mods="u-spaceRightMd"
            isDisabled={ disableButton }
          >
            Don't Cancel
          </Button>
          <Button color="primary" onClick={ this.handleConfirm } isDisabled={ disableButton }>
            Yes, Cancel Invoice
          </Button>
        </footer>
      </Modal>
    );
  }
}

ModalCancelInvoice.propTypes = {
  hideModal: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  showFeedback: PropTypes.func,
  isBatch: PropTypes.bool,
};

ModalCancelInvoice.defaultProps = {
  showFeedback: null,
  isBatch: false,
};

export default connect(null, {
  hideModal,
  showFeedback,
})(ModalCancelInvoice);
