const stateSelector = (state) => state.group;

export const selectPaymentProvider = (state) => stateSelector(state).paymentProvider;
export const selectHasActivePaymentProvider = (state) => stateSelector(state).hasActivePaymentProvider;

export const selectActivePaymentProvider = (state) => stateSelector(state).activePaymentProvider;
export const selectDefaultPaymentProvider = (state) => stateSelector(state).defaultPaymentProvider;

export const selectIsPaymentProviderStripe = (state) => stateSelector(state).isPaymentProviderStripe;
export const selectIsPaymentProviderWePay = (state) => stateSelector(state).isPaymentProviderWePay;

export const selectNeedsRefreshToShowFinancials =
  (state) => selectIsPaymentProviderStripe(state) && !selectHasActivePaymentProvider(state);

export const selectHasActiveClassicPayments = (state) => stateSelector(state).hasActiveClassicPayments;

export const selectGroupName = (state) => stateSelector(state).name;
export const selectDateFormat = (state) => stateSelector(state).dateFormat;
export const selectGroupPreferencesIsFetching = (state) => stateSelector(state).isFetching;

export const selectGroupId = (state) => stateSelector(state).groupId;
export const selectGroupType = (state) => stateSelector(state).groupType;
export const selectGroupParams = (state) => stateSelector(state).groupParams;
