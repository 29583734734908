import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(utc);
dayjs.extend(isSameOrBefore);

const defaultDateFormat = (isInternational) => (isInternational ? 'DD/MM/YYYY' : 'MM/DD/YYYY');

const isDateValid = (date) => dayjs(date).isValid();

const formatLocalDate = (date, format) => dayjs(date).format(format);

const getDate = (date) => dayjs.utc(date);

const formatDate = (date, format) => dayjs.utc(date).format(format);

const getYear = (date) => dayjs.utc(date).year();

const isSameOrBeforeCurrentDate = (date) => dayjs.utc(date).isSameOrBefore(dayjs.utc());

const parseDate = (date) => {
  if (isDateValid(date)) {
    return new Date(date);
  } else {
    return null;
  }
};

export {
  defaultDateFormat,
  isDateValid,
  formatLocalDate,
  getDate,
  formatDate,
  getYear,
  isSameOrBeforeCurrentDate,
  parseDate,
};
