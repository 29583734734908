// This action is for pushing new events into the GTM data layer.
// By creating a dedicated event we have flexibility to map this action to a prop
// or nest it within a thunk.

export const ANALYTIC_EVENT = 'ANALYTIC_EVENT';

export const analyticEvent = ({ url, eventCategory, eventAction, eventLabel }) => ({
  type: ANALYTIC_EVENT,
  payload: {
    url,
    eventCategory,
    eventAction,
    eventLabel,
  },
});
