/**
 * Member Email Actions
 *
 * Various actions using redux-thunk and axios for managing communication with API and redux store
 *
 */

import { getCollection } from 'state/baseActions';

export const MEMBER_EMAIL = 'MEMBER_EMAIL';

export const loadMemberEmails = (params) => (dispatch) =>
  dispatch(getCollection(MEMBER_EMAIL, 'member_email_addresses/search', params));

export const loadMemberEmailsFromInvoiceRecipients = (invoiceRecipients) => (dispatch) => {
  if (!invoiceRecipients) {
    return;
  }

  const memberIds = invoiceRecipients.filter((ir) => ir.invoiceableType === 'member').map((ir) => ir.invoiceableId);
  dispatch(loadMemberEmails({ memberId: memberIds }));
};
