import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, reduxForm } from 'redux-form';
import { setWePayStatus } from 'state/app/actions';
import { loadWePayScript, wepayCreateToken } from 'utils/wepay';
import CheckoutHeader from './CheckoutHeader';
import CheckoutMessage from './CheckoutMessage';
import WepayCreditCard from './WepayCreditCard';
import { validate } from './validate';
import { currencyPropShape } from 'interfaces/financials';

class WepayForm extends Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    loadWePayScript(this.props.setWePayStatus);
  }

  onTokenCallback = (data) => {
    const submitError =
      data.submitError === 'timeout'
        ? 'It looks like something went wrong, please check your information and try again.'
        : data.error_description;
    this.props.saveCreditCardToken({ token: data.credit_card_id }, submitError);
  };

  onTokenCreate = (creditCard) => {
    wepayCreateToken(creditCard, this.onTokenCallback);
  };

  onSubmit = (props) => {
    const {
      currency: { countryCode },
    } = this.props;

    this.props.createCreditCardToken({ ...props, countryCode }, this.onTokenCreate);
  };

  render() {
    return (
      <div>
        <Form id="checkoutForm" onSubmit={ this.props.handleSubmit(this.onSubmit) }>
          <CheckoutHeader />
          <WepayCreditCard />
          <CheckoutMessage text={ this.props.submitError } type="error" />
        </Form>
      </div>
    );
  }
}

WepayForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  setWePayStatus: PropTypes.func.isRequired,
  createCreditCardToken: PropTypes.func.isRequired,
  saveCreditCardToken: PropTypes.func.isRequired,
  currency: PropTypes.shape(currencyPropShape).isRequired,
  submitError: PropTypes.string,
};

WepayForm.defaultProps = {
  submitError: null,
};

export default connect(null, { setWePayStatus })(
  reduxForm({
    form: 'creditCardForm',
    validate,
  })(WepayForm),
);
