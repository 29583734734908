import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Header, Heading, Panel } from 'components/teamsnapUIExtensions';
import { Cell } from '@teamsnap/teamsnap-ui';
import styles from './InvoicingOverview.module.scss';

class InvoicingOverview extends Component {
  state = {
    selectedInput: 'batchInvoices',
  };

  onInputChange = (e) => {
    const currentView = e.target.value;

    this.props.onViewChange(currentView);
    this.setState({ selectedInput: currentView });
  };

  renderOverviewText = (title, details) => (
    <Heading size={ 2 } componentStyles={ styles } className="HeadingOverview">
      { title }
      { details && <span>{ details }</span> }
    </Heading>
  );

  renderColumnHeader = (title, icon, color) => (
    <Header componentStyles={ styles } size={ 4 } title={ title } iconLeftName={ icon } iconSize="15px" iconColor={ color } />
  );

  render() {
    const {
      aggregateData: { amountCollectedWithCurrency, amountDueWithCurrency, openBatchInvoicesCount },
    } = this.props;
    const { selectedInput } = this.state;
    const isBatch = selectedInput === 'batchInvoices';

    // const options = [
    //   { title: 'view batches', value: 'batchInvoices' },
    //   { title: 'view invoices', value: 'invoices' }
    // ]

    return (
      <Panel title={ isBatch ? 'Invoicing Overview' : 'Invoices Overview' } isGrid>
        <Cell mods="u-size1of3 u-textCenter u-spaceEndsLg" style={ { borderRight: '1px solid #d6d6d6' } }>
          { this.renderColumnHeader(isBatch ? 'Open Invoices' : 'Invoices', 'piggybank', '#7FC243') }
          { this.renderOverviewText(openBatchInvoicesCount) }
        </Cell>

        <Cell mods="u-size1of3 u-textCenter u-spaceEndsLg" style={ { borderRight: '1px solid #d6d6d6' } }>
          { this.renderColumnHeader('Amount Collected', 'money', '#7FC243') }
          { this.renderOverviewText(amountCollectedWithCurrency) }
        </Cell>

        <Cell mods="u-size1of3 u-textCenter u-spaceEndsLg">
          { this.renderColumnHeader('Amount Due', 'money', '#e26362') }
          { this.renderOverviewText(amountDueWithCurrency) }
        </Cell>

        { /* <Cell mods='u-textCenter' componentStyles={ styles } styleName='GridCellLast'>
          <RadioButton
            className='InputControlPadded'
            input={{ name: 'viewSelect', value: selectedInput, onChange: this.onInputChange }}
            options={ options } />
          </Cell> */ }
      </Panel>
    );
  }
}

InvoicingOverview.propTypes = {
  onViewChange: PropTypes.func.isRequired,
  aggregateData: PropTypes.shape({
    amountCollectedWithCurrency: PropTypes.string,
    amountDueWithCurrency: PropTypes.string,
    openBatchInvoicesCount: PropTypes.string,
  }),
};

InvoicingOverview.defaultProps = {
  aggregateData: {
    amountCollectedWithCurrency: '-',
    openBatchInvoicesCount: '-',
    amountDueWithCurrency: '-',
  },
};

export default InvoicingOverview;
