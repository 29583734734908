/**
 * TableRow Component
 *
 * The table row component is used to generate a table row. It uses an object of
 * row data that corresponds to an array of column keys.
 *
 * For Example:
 *  row = { title: 'Test Title' }
 *  columns = [{ label: 'Title', key: 'title' }]
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _isArray from 'lodash/isArray';
import TableCell from 'components/teamsnapUIExtensions/TableCell';
import { getClassNameStyles } from 'utils/utils';

const baseClassNames = {
  PanelExpandableRow: 'Panel-expandableRow',
  PanelRow: 'Panel-row Panel-row--withCells',
  PanelRowParent: 'Panel-row Panel-row--withCells Panel-row--parent',
  PanelChildRows: 'Panel-childRows',
  PanelChildRowsIsExpanded: 'Panel-childRows is-expanded',
};

class TableRow extends PureComponent {
  state = {
    showNestedRow: false,
  };

  onRowClick = () => {
    this.setState({
      showNestedRow: !this.state.showNestedRow,
    });
  };

  getClassName = (key) => {
    const { componentClassNames, componentStyles } = this.props;
    return getClassNameStyles(key, baseClassNames, componentClassNames, componentStyles);
  };

  renderColumns = (row) => {
    const { columns, componentClassNames, componentStyles } = this.props;

    return columns.map((column) => (
      <TableCell
        key={ column.key }
        column={ column }
        row={ row }
        handleRowClick={ row.nestedRows ? this.onRowClick : null }
        showNestedRow={ this.state.showNestedRow }
        componentStyles={ componentStyles }
        componentClassNames={ componentClassNames }
      />
    ));
  };

  renderChildRows = (nestedRows) => {
    const { showNestedRow } = this.state;
    // Default children to nested row, assume string or component
    let children = nestedRows;

    // If Array, assume same structure as parent rows/columns
    if (_isArray(nestedRows)) {
      children = nestedRows.map((row) => this.renderRow(row));
    }

    return (
      <div className={ this.getClassName(showNestedRow ? 'PanelChildRowsIsExpanded' : 'PanelChildRows') }>{ children }</div>
    );
  };

  renderRow = (row, isParent) => (
    <div key={ row.id } className={ this.getClassName(isParent ? 'PanelRowParent' : 'PanelRow') }>
      { this.renderColumns(row) }
    </div>
  );

  renderNestedRows = (row) => (
    <div className={ this.getClassName('PanelExpandableRow') }>
      { this.renderRow(row, true) }
      { this.renderChildRows(row.nestedRows) }
    </div>
  );

  render() {
    const { row } = this.props;
    return row.nestedRows ? this.renderNestedRows(row) : this.renderRow(row);
  }
}

TableRow.propTypes = {
  row: PropTypes.shape({}),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
    }),
  ),
  componentClassNames: PropTypes.shape({}),
  componentStyles: PropTypes.shape({}),
};

TableRow.defaultProps = {
  row: {},
  columns: [],
  componentStyles: {},
  componentClassNames: {},
};

export default TableRow;
