/**
 * Registration Form Actions
 *
 * Various actions using redux-thunk and axios for managing communication with API and redux store
 *
 */

import { getCollection, queryCollection } from 'state/baseActions';
import { REGISTRATION_FORM_LINE_ITEM } from 'state/registrationFormLineItems/actions';
import { REGISTRATION_FORM_LINE_ITEM_OPTION } from 'state/registrationFormLineItemOptions/actions';

export const REGISTRATION_FORM = 'REGISTRATION_FORM';

// Load Registration Forms
export const loadRegistrationForms = (params) => (dispatch) =>
  dispatch(getCollection(REGISTRATION_FORM, 'registration_forms/search', params));

export const queryRegistrationFormsWithItemOptions = (divisionId) => (dispatch) =>
  dispatch(
    queryCollection(
      { REGISTRATION_FORM, REGISTRATION_FORM_LINE_ITEM, REGISTRATION_FORM_LINE_ITEM_OPTION },
      {
        registrationForm__division_id: divisionId,
        registrationFormLineItem__division_id: divisionId,
        registrationFormLineItemOption__division_id: divisionId,
      },
    ),
  );
