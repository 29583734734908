import PropTypes from 'prop-types';

export const invoicePropShape = {
  amountCollected: PropTypes.number,
  amountCollectedWithCurrency: PropTypes.string,
  amountDue: PropTypes.number,
  amountDueWithCurrency: PropTypes.string,
  amountInvoiced: PropTypes.number,
  amountInvoicedWithCurrency: PropTypes.string,
  amountPaid: PropTypes.number,
  amountPaidWithCurrency: PropTypes.string,
  amountRefunded: PropTypes.number,
  amountRefundedWithCurrency: PropTypes.string,
  amountRefundedWithProcessingFeesRefunded: PropTypes.number,
  amountRefundedWithProcessingFeesRefundedWithCurrency: PropTypes.string,
  adjustments: PropTypes.number,
  adjustmentsWithCurrency: PropTypes.string,
  balance: PropTypes.number,
  balanceWithCurrency: PropTypes.string,
  batchInvoiceId: PropTypes.string,
  canPayByPaymentSchedule: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  currencyId: PropTypes.string,
  description: PropTypes.string,
  divisionId: PropTypes.string,
  failedInvoicePaymentsCount: PropTypes.number,
  hasOnlinePaymentOptions: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  hasPaymentSchedule: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  hasPayments: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  id: PropTypes.string,
  invoiceFrom: PropTypes.string,
  invoiceTo: PropTypes.string,
  invoiceableId: PropTypes.string,
  invoiceableType: PropTypes.string,
  isCancelable: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isDeletable: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isPayable: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isPayableOffline: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  paymentAdjustmentsAmount: PropTypes.number,
  paymentAdjustmentsAmountWithCurrency: PropTypes.string,
  processingFeesBilled: PropTypes.number,
  processingFeesBilledWithCurrency: PropTypes.string,
  processingFeesCanceled: PropTypes.number,
  processingFeesCanceledWithCurrency: PropTypes.string,
  processingFeesPaid: PropTypes.number,
  processingFeesPaidWithCurrency: PropTypes.string,
  processingFeesSubTotal: PropTypes.number,
  processingFeesSubTotalWithCurrency: PropTypes.string,
  status: PropTypes.string,
  subTotal: PropTypes.number,
  subTotalWithCurrency: PropTypes.string,
  teamId: PropTypes.string,
  title: PropTypes.string,
  total: PropTypes.number,
  totalWithCurrency: PropTypes.string,
  uuid: PropTypes.string,
};
