import AXIOS from 'axios';
import { onError } from 'services/request';
import { objectKeysToCase } from 'utils/utils';
import { authenticationToken, paymentsApiUrl } from 'utils/window';

// Setup local instance of axios with default url and headers
const axios = AXIOS.create({
  baseURL: `${paymentsApiUrl || 'http://localhost:5501'}`,
  headers: {
    Accept: 'application/vnd.api.v1.0.0+json',
    authorization: `Bearer ${authenticationToken}`,
  },
});

// Add start and end times to axios request and response objects
axios.interceptors.request.use((config) => ({
  ...config,
  startTime: new Date().getTime(),
}));

axios.interceptors.response.use((response) => ({
  ...response,
  data: objectKeysToCase(response.data),
  config: {
    ...response.config,
    endTime: new Date().getTime(),
  },
}));

const onSuccess = (result) => {
  const {
    config: { url, params, method, headers, startTime, endTime },
    data,
    status,
    statusText,
  } = result;
  console.log('Success - Payments API', {
    request: { time: `${endTime - startTime} ms`, url, params, method, headers, status, statusText },
    response: data,
    data: data.data,
  });

  return data.data;
};

// Main request wrapper for axios
export const paymentsRequest = (options) =>
  axios(options)
    .then((data) => onSuccess(data))
    .catch(onError);
