/**
 * Member Selector
 *
 * Various selectors using reselect for selecting data from state
 *
 */

import { createSelector } from 'reselect';
import _values from 'lodash/values';
import { selectTeamNames } from 'state/teamNames/selectors';
import { selectDivisions } from 'state/divisions/selectors';
import { stringifyArray } from 'utils/utils';

const stateSelector = (state) => state.members;
const isFetchingSelector = (state) => stateSelector(state).isFetching;
const isErrorSelector = (state) => stateSelector(state).isError;
const itemSelector = (state) => stateSelector(state).items;

// Select members Fetching or Error states
export const selectMemberIsFetching = (state) => isFetchingSelector(state);
export const selectMemberIsError = (state) => isErrorSelector(state);

export const selectMemberIsLoading = (state) => stateSelector(state).status === 'loading';

// ReSelect - Select all members and return as array
export const selectMembers = (state) => itemSelector(state) || {};
export const selectMemberValues = (state) => _values(itemSelector(state));

// ReSelect - Select all members add team and division name return as array
export const selectMemberTeamAndDivision = createSelector(
  [selectMemberValues, selectTeamNames, selectDivisions],
  (members, teamNames, divisions) =>
    members.map((member) => ({
      ...member,
      fullName: stringifyArray([member.lastName, member.firstName]),
      teamName: teamNames[member.teamId] ? teamNames[member.teamId].name : '',
      divisionName: divisions[member.divisionId] ? divisions[member.divisionId].name : '',
    })),
);

export const selectTeamMembers = createSelector([selectMemberValues], (members) =>
  members.map((member) => ({
    ...member,
    fullName: stringifyArray([member.lastName, member.firstName]),
  })),
);
