import { getCollection, queryCollection } from 'state/baseActions';

export const TEAM = 'TEAM';
export const TEAM_PREFERENCES = 'TEAM_PREFERENCES';
export const TEAM_FEE = 'TEAM_FEE';

export const loadTeam = (params) => (dispatch) => dispatch(getCollection(TEAM, 'teams/search', params));

export const loadTeamWithPreferences = (params) => (dispatch) =>
  dispatch(
    queryCollection(
      {
        TEAM,
        TEAM_PREFERENCES,
        TEAM_FEE,
      },
      {
        team__id: params.teamId,
        team_preferences__team_id: params.teamId,
        team_fee__team_id: params.teamId,
      },
    ),
  );
