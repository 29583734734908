/**
 * TextArea Component Wrapper
 *
 * A 'Field' is used by reduxForms to wrap our internal toolkit components,
 * in this case Input. see http://redux-form.com/6.8.0/docs/api/Field.md/
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import TextArea from 'components/teamsnapUIExtensions/TextArea';

const TextAreaField = (props) => <Field component={ TextArea } { ...props } />;

TextAreaField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  size: PropTypes.string,
  componentStyles: PropTypes.shape({}),
  className: PropTypes.string,
};

TextAreaField.defaultProps = {
  label: undefined,
  placeholder: undefined,
  disabled: false,
  required: false,
  size: undefined,
  componentStyles: {},
  className: 'TextArea',
};

export default TextAreaField;
