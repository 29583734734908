import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { SummaryList } from '@teamsnap/teamsnap-ui';
import { capitalize } from 'utils/utils';
import { formatDate } from 'utils/date-service';
import { paymentPropShape } from 'interfaces/financials';

class PaymentLog extends PureComponent {
  renderPayments = () => {
    const { payments, dateFormat } = this.props;

    return payments.map((payment) => {
      const paymentDate = payment.scheduledAt || payment.lastTransactedAt;

      return {
        key: payment.id,
        description: `${capitalize(payment.status)}, ${formatDate(paymentDate, dateFormat)}`,
        value: payment.grossAmountWithCurrency,
      };
    });
  };

  render() {
    const { heading, subHeading, hasBorder } = this.props;

    return (
      <SummaryList
        hasBorder={ hasBorder }
        heading={ heading }
        subHeading={ subHeading }
        items={ this.renderPayments() }
      />
    );
  }
}

PaymentLog.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.shape(paymentPropShape)).isRequired,
  dateFormat: PropTypes.string.isRequired,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  hasBorder: PropTypes.bool,
};

PaymentLog.defaultProps = {
  heading: null,
  subHeading: null,
  hasBorder: true,
};

export default PaymentLog;
