/**
 * CheckboxField Component Wrapper
 *
 * A 'Field' is used by reduxForms to wrap our internal toolkit components,
 * in this case Toggle. see http://redux-form.com/6.8.0/docs/api/Field.md/
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Checkbox } from 'components/teamsnapUIExtensions/InputControl';

const CheckboxField = (props) => <Field component={ Checkbox } { ...props } />;

CheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  size: PropTypes.string,
  componentStyles: PropTypes.shape({}),
  className: PropTypes.string,
};

CheckboxField.defaultProps = {
  label: undefined,
  disabled: false,
  required: false,
  size: undefined,
  componentStyles: {},
  className: 'InputControl',
};

export default CheckboxField;
