/**
 * Invoice Payment Selector
 *
 * Various selectors using reselect for selecting data from state
 *
 */

import { createSelector } from 'reselect';
import _values from 'lodash/values';
import { selectInvoiceId } from 'state/routerLocation/selectors';
import { selectInvoicePaymentTransactionItems } from 'state/invoicePaymentTransactions/selectors';

const stateSelector = (state) => state.invoicePayments;
const itemSelector = (state) => stateSelector(state).items;
const isFetchingSelector = (state) => stateSelector(state).isFetching;
const isErrorSelector = (state) => stateSelector(state).isError;

// Select invoice payment Fetching or ERror states
export const selectInvoicePaymentIsFetching = (state) => isFetchingSelector(state);
export const selectInvoicePaymentIsError = (state) => isErrorSelector(state);

// ReSelect - Select invoice payments and return as array
export const selectInvoicePayments = (state) => _values(itemSelector(state));

// ReSelect - Select invoice payments for current invoice route
export const selectInvoicePaymentsByInvoiceRoute = createSelector(
  selectInvoiceId,
  selectInvoicePayments,
  (invoiceId, invoicePayments) =>
    invoicePayments && invoicePayments.filter((invoicePayment) => invoicePayment.invoiceId === invoiceId),
);

export const selectInvoicePaymentsAndTransactions = createSelector(
  [selectInvoiceId, selectInvoicePaymentsByInvoiceRoute, selectInvoicePaymentTransactionItems],
  (invoiceId, invoicePayments, invoiceTransactions) =>
    invoicePayments.map((payment) => ({
      ...payment,
      transactions: invoiceTransactions.filter((transaction) => transaction.invoicePaymentId === payment.id),
    })),
);
