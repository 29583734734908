/**
 * Batch Invoice Selector
 *
 * Various selectors using reselect for selecting data from state
 *
 */
import { createSelector } from 'reselect';
import _values from 'lodash/values';
import { selectBatchInvoiceId } from 'state/routerLocation/selectors';

const stateSelector = (state) => state.batchInvoices;
const itemSelector = (state) => stateSelector(state).items;
const isFetchingSelector = (state) => stateSelector(state).isFetching;
const isErrorSelector = (state) => stateSelector(state).isError;

// Select batchInvoice Fetching or Error states
export const selectBatchInvoiceIsFetching = (state) => isFetchingSelector(state);
export const selectBatchInvoiceIsError = (state) => isErrorSelector(state);

// Select batch Invoice by id
export const selectBatchInvoiceById = (state, id) => itemSelector(state)[id];

// ReSelect - Select all batch invoices and return as array
export const selectBatchInvoices = createSelector(itemSelector, (batchInvoices) => _values(batchInvoices));

// ReSelect - Select batch invoice for current batch invoice route
export const selectBatchInvoiceByBatchRoute = createSelector(
  selectBatchInvoiceId,
  itemSelector,
  (batchInvoiceId, batchInvoices) => batchInvoices && batchInvoices[batchInvoiceId],
);

// Casts a Boolean for hiding and showing text if fees are being passed
export const selectBatchInvoiceFeesPassed = (state, id) => {
  const batch = itemSelector(state) && itemSelector(state)[id];
  return !!(batch && batch.processingFeePaidBy === 'payer');
};
