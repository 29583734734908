/**
 * Division Selector
 *
 * Various selectors using reselect for selecting data from state
 *
 */

import { createSelector } from 'reselect';
import _values from 'lodash/values';
import { selectDivisionAggregates } from 'state/divisionAggregates/selectors';

const stateSelector = (state) => state.divisions;
const isFetchingSelector = (state) => stateSelector(state).isFetching;
const itemSelector = (state) => stateSelector(state).items;

export const selectDivisionIsFetching = (state) => isFetchingSelector(state);

export const selectDivisions = (state) => itemSelector(state) || {};
export const selectDivisionValues = (state) => _values(itemSelector(state));

export const selectDivisionsForDropdown = createSelector(selectDivisionValues, (divisions) =>
  divisions.map((division) => ({ title: division.name, value: division.id })),
);

// ReSelect Divisions and DivisionAggregates
export const selectDivisionsWithAggregates = createSelector(
  [selectDivisionValues, selectDivisionAggregates],
  (divisions, aggregates) =>
    divisions.map((division) => {
      const aggregate = aggregates[division.id];

      const aggregateMembersCount = aggregate
        ? aggregate.selfAndDescendantsAssignedMemberCount + aggregate.selfAndDescendantsUnassignedMemberCount
        : null;

      return {
        ...division,
        aggregateMembersCount,
      };
    }),
);
