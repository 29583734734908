import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, FieldArray } from 'redux-form';
import _get from 'lodash/get';
import { Button } from '@teamsnap/teamsnap-ui';
import { Loader, Panel, FormGroup } from 'components/teamsnapUIExtensions';
import InvoicingFormNav from 'components/baseComponents/InvoicingFormNav';
import InvoiceMembers from 'components/baseComponents/InvoiceMembers';
import InvoicingRecipients from 'components/baseComponents/InvoicingRecipients';
import MemberFilter from 'components/baseComponents/MemberFilter';
import { linkInvoices, linkInvoicingSend } from 'utils/links';
import { setupMemberRecipientRows } from './memberRecipients';
import { validate } from './validate';
import styles from './InvoicingMembers.module.scss';
import { getGroupType, getGroupParams } from 'utils/group';
import { matchPropShape, historyPropShape } from 'interfaces/navigation';
import { memberPropShape, invoiceRecipientPropShape } from 'interfaces/member';
import { invoicePropShape } from 'interfaces/invoice';

class InvoicingMembers extends Component {
  state = {
    members: [],
    noResults: false,
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { queryInvoicesAndRecipients, batchInvoiceId } = this.props;
    queryInvoicesAndRecipients(batchInvoiceId);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const {
      isMemberLoading,
      isPageLoading,
      members,
      invoiceRecipients,
      formSelectorRecipients,
      change: reduxFieldChange,
      match: { params },
    } = nextProps;

    if (isMemberLoading || (getGroupType(params) === 'divisionId' && isPageLoading)) {
      return;
    }

    const memberRecipients = setupMemberRecipientRows(
      members,
      invoiceRecipients,
      formSelectorRecipients,
      reduxFieldChange,
    );

    this.setState({
      members: memberRecipients,
    });
  };

  componentWillUnmount() {
    const {
      loadMembers,
      match: { params },
    } = this.props;
    // Reset member collection on unmount, this will clear out the filtered items from apiv3
    loadMembers(getGroupParams(params));
  }

  redirectToInvoices = (groupId, batchInvoiceId, needsRefresh) => {
    if (needsRefresh) {
      window.location.assign(linkInvoices(groupId, batchInvoiceId));
    } else {
      this.props.history.push(linkInvoices(groupId, batchInvoiceId));
    }
  };

  redirectToInvoicingSend = (groupId, batchInvoiceId) =>
    this.props.history.push(linkInvoicingSend(groupId, batchInvoiceId));

  onFormSubmit = (props) => {
    const { createInvoice, loadBatchInvoices, saveInvoiceMessageRecipients, batchInvoiceId, groupId } = this.props;

    const members = props.members.map((member) => member.id);

    return createInvoice({ batchInvoiceId, memberIds: members }).then((_invoice) => {
      if (members.length > 0) {
        loadBatchInvoices({ id: batchInvoiceId });
        saveInvoiceMessageRecipients(members);
        return this.redirectToInvoicingSend(groupId, batchInvoiceId);
      }

      return this.redirectToInvoices(groupId, batchInvoiceId);
    });
  };

  onFilterSubmit = (props) => {
    const { loadAdvancedDivisionMembers } = this.props;
    loadAdvancedDivisionMembers(props).then((response) => this.setState({ noResults: response.length === 0 }));
  };

  filterMembersByInput = (members, filterGroups, memberType) =>
    members.filter((member) => {
      // Member role defaults to true if memberType is null (isAll)
      const memberRole = memberType ? member.isNonPlayer.toString() === memberType : true;

      // Compare all the filters included in filterGroups with the member columns of the same name
      return (
        memberRole &&
        Object.keys(filterGroups).every((key) => {
          const value = _get(member, key, '').toString().toLowerCase();
          // This will check if any value of the filterGroup matches 'some' member value in the group
          return filterGroups[key].some((group) => value.includes(group));
        })
      );
    });

  renderPanelFooter = (selectedMembers) => {
    const { batchInvoiceId, submitting, handleSubmit: reduxFormSubmit, groupId, needsRefresh } = this.props;

    const skipLink = selectedMembers > 0 ? this.redirectToInvoicingSend : this.redirectToInvoices;

    return (
      <div>
        <Button
          type="button"
          style={ { marginRight: '5px' } }
          color="negative"
          icon="dismiss"
          onClick={ () => skipLink(groupId, batchInvoiceId, needsRefresh) }
        >
          Skip This Step
        </Button>

        <Button
          type="button"
          color="primary"
          style={ { marginLeft: '10px' } }
          onClick={ reduxFormSubmit(this.onFormSubmit) }
          icon="right"
          iconPosition="right"
          isDisabled={ submitting }
        >
          Save & Continue
        </Button>
      </div>
    );
  };

  render() {
    const {
      batchInvoiceId,
      isPageLoading,
      isMemberLoading,
      change: reduxFieldChange,
      teams,
      divisions,
      invoices,
      formSelectorFilters,
      formSelectorRecipients,
      groupId,
      groupType,
    } = this.props;

    const { members, noResults } = this.state;

    if (groupType === 'divisionId' && isPageLoading) {
      return <Loader message="random" />;
    }

    return (
      <div>
        <InvoicingFormNav
          title="New Invoice"
          currentStep={ invoices.length > 0 ? 'membersEdit' : 'membersNew' }
          groupId={ groupId }
          invoicingId={ batchInvoiceId }
          groupType={ groupType }
        />

        <Panel
          title="Create Invoices"
          footerComponent={ this.renderPanelFooter(formSelectorRecipients) }
          componentStyles={ styles }
          isGrid
        >
          <FormGroup size="7of12" componentStyles={ styles } className="FormGroupLeft">
            { groupType === 'divisionId' && (
              <MemberFilter
                groupId={ groupId }
                onFormSubmit={ this.onFilterSubmit }
                formSelectorFilters={ formSelectorFilters }
                teams={ teams }
                divisions={ divisions }
              />
            ) }

            <InvoiceMembers
              reduxFieldChange={ reduxFieldChange }
              isFetching={ isMemberLoading }
              members={ members }
              selectedMembers={ formSelectorRecipients }
              groupType={ groupType }
              noResults={ noResults }
            />
          </FormGroup>

          <form className="u-size5of12">
            <FieldArray
              name="members"
              component={ InvoicingRecipients }
              selectedMembers={ formSelectorRecipients }
              reduxFieldChange={ reduxFieldChange }
            />
          </form>
        </Panel>
      </div>
    );
  }
}

InvoicingMembers.propTypes = {
  match: PropTypes.shape(matchPropShape).isRequired,
  history: PropTypes.shape(historyPropShape).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  groupId: PropTypes.string.isRequired,
  batchInvoiceId: PropTypes.string.isRequired,
  isMemberLoading: PropTypes.bool.isRequired,
  isPageLoading: PropTypes.bool.isRequired,
  loadAdvancedDivisionMembers: PropTypes.func.isRequired,
  loadMembers: PropTypes.func.isRequired,
  createInvoice: PropTypes.func.isRequired,
  loadBatchInvoices: PropTypes.func.isRequired,
  saveInvoiceMessageRecipients: PropTypes.func.isRequired,
  queryInvoicesAndRecipients: PropTypes.func.isRequired,
  formSelectorFilters: PropTypes.arrayOf(PropTypes.shape({})),
  formSelectorRecipients: PropTypes.arrayOf(PropTypes.shape({})),
  invoices: PropTypes.arrayOf(PropTypes.shape(invoicePropShape)),
  teams: PropTypes.arrayOf(PropTypes.shape({})),
  divisions: PropTypes.arrayOf(PropTypes.shape({})),
  needsRefresh: PropTypes.bool.isRequired,
  members: PropTypes.arrayOf(PropTypes.shape(memberPropShape)), // eslint-disable-line react/no-unused-prop-types
  invoiceRecipients: PropTypes.arrayOf(PropTypes.shape(invoiceRecipientPropShape)), // eslint-disable-line react/no-unused-prop-types
  groupType: PropTypes.string.isRequired,
};

InvoicingMembers.defaultProps = {
  formSelectorFilters: [],
  formSelectorRecipients: [],
  invoices: [],
  teams: [],
  divisions: [],
  members: [],
  invoiceRecipients: [],
};

export default reduxForm({
  form: 'invoiceRecipients',
  validate,
})(InvoicingMembers);
