const formatItem = (item) => {
  if (!item || item === null) return '';
  return item;
};

export const convertToCSV = (columns, rows) => {
  if (!rows || rows.length === 0) return '';
  const result = rows.map(item => columns.map(column => formatItem(item[column.key])).join(','));
  result.unshift(columns.map(column => column.label).join(','));
  return result.join('\r\n');
};
