import { loadDivisionWithPreferences } from 'state/divisions/actions';
import { loadTeamWithPreferences } from 'state/teams/actions';
import { getGroupId, getGroupType, getGroupParams } from 'utils/group';
import { defaultDateFormat } from 'utils/date-service';

export const GROUP_PREFERENCES = 'GROUP_PREFERENCES';

const groupConfiguration = {
  divisionId: {
    loadPreferences: loadDivisionWithPreferences,
    useInternationalDate: 'hasInternationalDate',
    useInternationalTime: 'hasInternationalTime',
  },
  teamId: {
    loadPreferences: loadTeamWithPreferences,
    useInternationalDate: 'globalUseInternationalDate',
    useInternationalTime: 'globalUseInternationalTime',
  },
};

const getGroupPreference = (isGroupDivision, data) =>
  isGroupDivision
    ? data.divisionPreferences && data.divisionPreferences[0]
    : data.teamsPreferences && data.teamsPreferences[0];

const saveGroupPreferences = (data, prefs) => (dispatch) => {
  const isGroupDivision = prefs.groupType === 'divisionId';
  const group = isGroupDivision ? data.division[0] : data.team[0];
  const groupPreferences = getGroupPreference(isGroupDivision, data) || {};
  const paymentProvider = group.activePaymentProvider || group.defaultPaymentProvider;
  const hasInternationalDate = groupPreferences && groupPreferences[prefs.groupConfig.useInternationalDate];
  const hasActivePaymentProvider = !!group.activePaymentProvider;
  const hasActiveClassicPayments = !!data.teamFee;

  // TOOD: Make sure groupPreferences is working correctly.  Will need default values if it doesn't exist.

  return dispatch({
    type: GROUP_PREFERENCES,
    payload: {
      activePaymentProvider: group.activePaymentProvider,
      defaultPaymentProvider: group.defaultPaymentProvider,
      paymentProvider,
      hasActivePaymentProvider,
      isPaymentProviderStripe: paymentProvider === 'stripe',
      isPaymentProviderWePay: paymentProvider === 'wepay',
      hasActiveClassicPayments,
      groupType: prefs.groupType,
      groupId: prefs.groupId,
      groupParams: prefs.groupParams,
      hasInternationalDate,
      dateFormat: defaultDateFormat(hasInternationalDate),
      hasInternationalTime: groupPreferences && groupPreferences[prefs.groupConfig.useInternationalTime],
      name: group.name,
    },
  });
};

export const loadGroupPreferences = (params) => (dispatch) => {
  const groupType = getGroupType(params);
  const groupConfig = groupConfiguration[groupType];

  return dispatch(groupConfig.loadPreferences(params)).then((response) =>
    dispatch(
      saveGroupPreferences(response, {
        groupConfig,
        groupType,
        groupId: getGroupId(params),
        groupParams: getGroupParams(params),
      }),
    ),
  );
};
