import { Button } from '@teamsnap/teamsnap-ui';
import InputField from 'components/baseComponents/Fields/InputField';
import RadioButtonField from 'components/baseComponents/Fields/RadioButtonField';
import TextAreaField from 'components/baseComponents/Fields/TextAreaField';
import { ERROR, SUCCESS } from 'components/teamsnapUIExtensions/Feedback/Feedback';
import Form, { FormGroup } from 'components/teamsnapUIExtensions/Form';
import Modal from 'components/teamsnapUIExtensions/Modal';
import { invoicePaymentPropShape } from 'interfaces/financials';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { showFeedback } from 'state/feedback/actions';
import { createInvoiceRefund } from 'state/invoicePayments/actions';
import { queryInvoiceCollections } from 'state/invoices/actions';
import { hideModal } from 'state/modal/actions';
import styles from './ModalIssueRefund.module.scss';
import { validate } from './validate';

class ModalIssueRefund extends Component {
  state = {
    isCreditCardRefund: false,
    showConfirmation: false,
    amount: null,
  };

  onSubmit = (submitProps) => {
    const {
      closeModal,
      reloadCollections,
      invoiceId,
      refundInvoice,
      invoicePayment: { id },
    } = this.props;
    const { isCreditCardRefund, showConfirmation } = this.state;

    if(isCreditCardRefund && !showConfirmation) {
      this.setState({ showConfirmation: true, amount: submitProps.amount });
    } else {
      refundInvoice({ ...submitProps, id })
        .then(() => reloadCollections(invoiceId))
        .then(() => this.props.showFeedback(this.feedbackSuccessProps))
        .catch(() => this.props.showFeedback(this.feedbackErrorProps));

      closeModal();
    }
  };

  handleClose = () => {
    this.props.closeModal();
  };

  handleRadioButton = (e) => {
    this.setState({ isCreditCardRefund: e.target.value === 'card' });
  };

  refundMethodOptions = () => {
    const {
      invoicePayment: { initialPaymentProviderName },
    } = this.props;
    const options = [
      { title: 'Cash', value: 'cash' },
      { title: 'Check', value: 'check' },
    ];

    if (initialPaymentProviderName.match(/stripe/i)) {
      options.push({ title: 'Card', value: 'card' });
    }

    return options;
  };

  feedbackSuccessProps = {
    title: 'You did it!',
    message: 'The invoice has been refunded.',
    type: SUCCESS,
  };

  feedbackErrorProps = {
    title: 'Oops!',
    // eslint-disable-next-line
    message:
      'Something went wrong on our end. Please try again, and if the error persists let us know at https://helpme.teamsnap.com/?solvvy=true so we can see what’s going on.',
    type: ERROR,
  };

  renderFormFields = () => (
    <>
      <FormGroup>
        <RadioButtonField
          label="Refund Method"
          name="kind"
          size="fit"
          options={ this.refundMethodOptions() }
          onChange={ this.handleRadioButton }
          className="InputControlInline"
        />
      </FormGroup>

      <FormGroup>
        <InputField
          name="amount"
          label="Refund Amount"
          placeholder="0.00"
        />
      </FormGroup>

      <FormGroup>
        <TextAreaField name="detail" label="Refund Details" placeholder="Add a note here" />
      </FormGroup>
    </>
  );

  renderConfirmation = () => { 
    const {
      invoicePayment: { amountRefunded, grossAmount },
    } = this.props;
    const { isCreditCardRefund, amount } = this.state;
    const floatAmount = parseFloat(amount);

    const isPartialCreditRefund = isCreditCardRefund && (floatAmount !== grossAmount || amountRefunded > 0);

    return (
      <>
        <h5 style={ { color: '#e26362' } }>
          Warning. This cannot be undone.
          { 
            isPartialCreditRefund && (
              <>
                <br/><br/>
                <p>
                    Please be advised that processing fees can not be refunded as part of a partial refund.
                </p>
              </>
            )
          }
        </h5>
        <p>
          Are you sure you want to issue a refund in the amount of <strong>${ floatAmount.toFixed(2) }</strong>?
        </p>
      </>
    );
  }

  render() {
    const { handleSubmit } = this.props;
    const { showConfirmation } = this.state;

    return (
      <Modal
        className="ModalSmall"
        title={ showConfirmation ? 'Confirm Refund' : 'Issue Refund' }
        handleClose={ this.handleClose }
      >
        <Form handleSubmit={ handleSubmit(this.onSubmit) }>
          <div className={ styles.ModalBody }>
            { showConfirmation && this.renderConfirmation() }
            { !showConfirmation && this.renderFormFields() }
          </div>
          <div className={ styles.ModalFooter }>
            <Button
              type="button"
              onClick={ this.handleClose }
              icon="dismiss"
              color="negative"
              style={ { marginRight: '5px' } }
            >
              Cancel
            </Button>
            <Button color="primary" type="submit" icon={ showConfirmation ? 'check' : 'plus' }>
              { showConfirmation ? 'Yes, Issue Refund' : 'Issue Refund' }
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
}

ModalIssueRefund.propTypes = {
  closeModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  refundInvoice: PropTypes.func.isRequired,
  invoicePayment: PropTypes.shape(invoicePaymentPropShape).isRequired,
  reloadCollections: PropTypes.func.isRequired,
  invoiceId: PropTypes.string.isRequired,
  showFeedback: PropTypes.func,
};

ModalIssueRefund.defaultProps = {
  showFeedback: null,
};

export default connect(null, {
  closeModal: hideModal,
  refundInvoice: createInvoiceRefund,
  reloadCollections: queryInvoiceCollections,
  showFeedback,
})(
  reduxForm({
    form: 'applyRefund',
    validate,
  })(ModalIssueRefund),
);
