import { paymentsRequest } from 'services/paymentsApi';
import { createDepositAccount } from 'state/depositAccount/actions';

export const FETCH_DEPOSIT_ACCOUNT_ONBOARDING = 'FETCH_DEPOSIT_ACCOUNT_ONBOARDING_LINK';
export const SAVE_DEPOSIT_ACCOUNT_ONBOARDING = 'SAVE_DEPOSIT_ACCOUNT_ONBOARDING_LINK';
export const ERROR_DEPOSIT_ACCOUNT_ONBOARDING = 'ERROR_DEPOSIT_ACCOUNT_ONBOARDING_LINK';

/**
 * Create a deposit account onboarding link. 
 * Creates a deposit account if one hasn't been loaded into state.
 * 
 * @param {string} teamId ID of the team
 * @param {string} returnUrl URL to redirect to after onboarding is complete
 */
export const createDepositOnboardingLink = (teamId, returnUrl) => async (dispatch, getState) => {
  const account = getState().depositAccount.account;
  let accountId = account?.id;
  if (!account) {
    accountId = await dispatch(createDepositAccount(teamId));
  }

  dispatch({ type: FETCH_DEPOSIT_ACCOUNT_ONBOARDING });
  const params =
  {
    'refresh_url': returnUrl,
    'return_url': returnUrl,
    'type': 'account_onboarding'
  };
  const response = await paymentsRequest({ method: 'post', url: `/v2/payment_accounts/${accountId}/create_onboarding_link`, data: { ...params }, config: {  } })
    .then((response) => {
      dispatch({ type: SAVE_DEPOSIT_ACCOUNT_ONBOARDING, payload: response });
      return response.url;
    }).catch(() => {
      dispatch({ type: ERROR_DEPOSIT_ACCOUNT_ONBOARDING });
    });
  return Promise.resolve(response);
};
