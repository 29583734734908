import { connect } from 'react-redux';
import { updateBatchInvoice } from 'state/batchInvoices/actions';
import { createBatchInvoicePaymentSchedule } from 'state/batchInvoicePaymentSchedules/actions';
import {
  selectGroupPreferencesIsFetching,
  selectDateFormat,
  selectIsPaymentProviderWePay,
  selectNeedsRefreshToShowFinancials,
} from 'state/group/selectors';
import { showFeedback } from 'state/feedback/actions';
import { getGroupId, getGroupType } from 'utils/group';

import InvoicingPaymentInfo from './InvoicingPaymentInfo';

const mapDispatchToProps = {
  createBatchInvoicePaymentSchedule,
  showFeedback,
  updateBatchInvoice,
};

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    batchInvoice: {
      passTransactionFees: true,
    },
  },
  needsRefresh: selectNeedsRefreshToShowFinancials(state),
  groupId: getGroupId(ownProps.match.params),
  groupType: getGroupType(ownProps.match.params),
  batchInvoiceId: ownProps.match.params.invoicingId,
  dateFormat: selectDateFormat(state),
  isPaymentProviderWePay: selectIsPaymentProviderWePay(state),
  isFetching: selectGroupPreferencesIsFetching(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoicingPaymentInfo);
