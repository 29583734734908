/**
 * Invoice Line Item Actions
 *
 * Various actions using redux-thunk and axios for managing communication with API and redux store
 *
 */

import { createCollection, getCollection, removeCollection } from 'state/baseActions';
import { formatUSD } from 'utils/currency';
import { selectInvoiceLineItemsByInvoiceId } from './selectors';

export const INVOICE_LINE_ITEM = 'INVOICE_LINE_ITEM';

// Load Invoice Line Items
export const loadInvoiceLineItems = (params) => (dispatch) =>
  dispatch(getCollection(INVOICE_LINE_ITEM, 'invoice_line_items/search', params));

export const removeInvoiceLineItemsByInvoiceId = (invoiceId) => (dispatch, getState) => {
  selectInvoiceLineItemsByInvoiceId(getState())(invoiceId).forEach((invoiceLineItem) =>
    dispatch(removeCollection(INVOICE_LINE_ITEM, invoiceLineItem)),
  );
  return Promise.resolve();
};

// Create Invoice Line Item
export const createInvoiceAdjustment = (invoiceId, description, amount) => (dispatch) => dispatch(
  createCollection(INVOICE_LINE_ITEM, 'invoice_line_items', {
    invoiceId,
    description,
    amount: formatUSD(amount),
    invoiceCategoryId: 7,
    type: 'invoiceLineItem',
  }),
);
