import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Cell } from '@teamsnap/teamsnap-ui';
import Arms from 'assets/arms.png';
import styles from '../PayInvoice.module.scss';

const PaymentsDisabled = ({ isCanadian }) => {
  const gridMods = 'u-padLg';

  return (
    <div className={ `${styles.PaymentsDisabledPanel} ${isCanadian ? 'ga-payments-disabled-ca' : ''}` }>
      <Grid mods={ gridMods } className={ styles.PaymentsDisabledGrid }>
        <Cell mods="u-sm-size1of4 u-sm-textRight u-textCenter u-padSidesMd">
          <img className={ styles.ArmsImage } src={ Arms } alt="Arms holding a check and cash" />
        </Cell>
        <Cell mods="u-sm-size3of4 u-spaceTopLg u-padSidesMd u-sm-textLeft u-textCenter">
          <p className="u-fontSizeLg u-colorPrimary">
            <strong>Your organization has not yet enabled online payments.</strong>
            <br />
            Please bring cash or check to the next event, or reach out to an administrator to ask them to enable online
            payments.
          </p>
        </Cell>
      </Grid>
    </div>
  );
};

PaymentsDisabled.propTypes = {
  isCanadian: PropTypes.bool,
};

PaymentsDisabled.defaultProps = {
  isCanadian: false,
};

export default PaymentsDisabled;
