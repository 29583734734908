import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'components/teamsnapUIExtensions';
import Account from 'components/screens/Account';
import AppRoutes from 'components/baseComponents/AppRoutes';
import Invoicing from 'components/screens/Invoicing';
import InvoicingDetails from 'components/screens/InvoicingDetails';
import InvoicingNew from 'components/screens/InvoicingNew';
import InvoicingPaymentInfo from 'components/screens/InvoicingPaymentInfo';
import InvoicingMembers from 'components/screens/InvoicingMembers';
import InvoicingSend from 'components/screens/InvoicingSend';
import InvoicingEdit from 'components/screens/InvoicingEdit';
import InvoiceSummary from 'components/screens/InvoiceSummary';
import { getGroupParams, getGroupId } from 'utils/group';
import { linkPaymentIndexList } from 'utils/links';
import { matchPropShape, locationPropShape } from 'interfaces/navigation';

const routes = [
  { path: '/invoicing', component: Invoicing, exact: true },
  { path: '/invoicing/account', component: Account },
  { path: '/invoicing/new', component: InvoicingNew },
  { path: '/invoicing/:invoicingId/payment-info', component: InvoicingPaymentInfo },
  { path: '/invoicing/:invoicingId/members', component: InvoicingMembers },
  { path: '/invoicing/:invoicingId/send', component: InvoicingSend },
  { path: '/invoicing/:invoicingId/edit', component: InvoicingEdit },
  { path: '/invoicing/:invoicingId/invoices', component: InvoicingDetails, exact: true },
  { path: '/invoicing/:invoicingId/invoices/:invoiceId', component: InvoiceSummary },
];

class TeamPayments extends Component {
  componentDidMount() {
    const {
      loadGroupPreferences,
      saveRouteLocation,
      saveAppSettings,
      loadMembers,
      match,
      location,
      getPaymentAccount,
      getDepositAccount,
    } = this.props;

    saveAppSettings();
    loadGroupPreferences(match.params);
    saveRouteLocation(location.pathname);
    loadMembers(getGroupParams(match.params));
    getPaymentAccount(match.params);
    getDepositAccount(match.params.teamId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.saveRouteLocation(this.props.location.pathname);
    }
  }

  render() {
    const {
      activePaymentProvider,
      defaultPaymentProvider,
      match: { params },
    } = this.props;
    const groupId = getGroupId(params);

    if (defaultPaymentProvider !== null && activePaymentProvider === null && defaultPaymentProvider !== 'stripe') {
      window.location.replace(linkPaymentIndexList(groupId));
      return <Loader message="random" />;
    }

    return <AppRoutes rootPath={ this.props.match.path } routes={ routes } />;
  }
}

TeamPayments.propTypes = {
  loadGroupPreferences: PropTypes.func.isRequired,
  saveRouteLocation: PropTypes.func.isRequired,
  saveAppSettings: PropTypes.func.isRequired,
  loadMembers: PropTypes.func.isRequired,
  match: PropTypes.shape(matchPropShape).isRequired,
  location: PropTypes.shape(locationPropShape).isRequired,
  getPaymentAccount: PropTypes.func.isRequired,
  getDepositAccount: PropTypes.func.isRequired,
  activePaymentProvider: PropTypes.string,
  defaultPaymentProvider: PropTypes.string,
};

TeamPayments.defaultProps = {
  activePaymentProvider: null,
  defaultPaymentProvider: null,
};

export default TeamPayments;
