/**
 * Router Location Actions
 *
 * Various actions using redux-thunk for managing url params from react-router in the redux store
 *
 */

export const SAVE_LOCATION = 'SAVE_LOCATION';

const saveLocation = (match) => ({
  type: SAVE_LOCATION,
  payload: match,
});

export const saveRouteLocation = (path) => (dispatch) => {
  const resourceType = path.includes('team_payments') ? 'teamId' : 'divisionId';

  const [resourceId, invoicingId, invoiceId] = path.match(/\d+/g);

  return dispatch(saveLocation({ path, params: { resourceType, resourceId, invoicingId, invoiceId } }));
};
