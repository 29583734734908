import { connect } from 'react-redux';
import { loadInvoicePaymentDetails, savePaymentMethod, updatePaymentMethod } from 'state/payInvoice/actions';
import { submit } from 'redux-form';
import {
  selectInvoicePaymentLoading,
  selectInvoicePaymentGroup,
  selectInvoicePaymentInvoice,
  selectInvoicePayments,
  selectInvoicePaymentLineItems,
  selectInvoicePaymentOptionAmounts,
  selectInvoicePaymentSubmitted,
  selectInvoiceSchedulePaymentOptionAmounts,
  selectInvoiceSchedulePaymentsPaymentOptionAmounts,
  selectInvoicePaymentUpdateStatus,
  selectInvoicePaymentSchedule,
  selectInvoicePaymentCurrency,
  selectInvoicePaymentDateFormat,
  selectInvoicePaymentError,
} from 'state/payInvoice/selectors';
import { showModal } from 'state/modal/actions';
import PayInvoice from './PayInvoice';
import { selectIsTeamBlockedFromPayments } from 'state/canAcceptPayment/selectors';

const reduxFormSubmit = (formName) => (dispatch) => dispatch(submit(formName));

const mapDispatchToProps = {
  loadInvoicePaymentDetails,
  savePaymentMethod,
  updatePaymentMethod,
  reduxFormSubmit,
  showModal,
};

const mapStateToProps = (state, ownProps) => {
  const { uuid } = ownProps.match.params;
  const group = selectInvoicePaymentGroup(state);

  return {
    uuid,
    isFetching: selectInvoicePaymentLoading(state),
    paymentSubmitted: selectInvoicePaymentSubmitted(state),
    paymentUpdateStatus: selectInvoicePaymentUpdateStatus(state),
    dateFormat: selectInvoicePaymentDateFormat(state),
    invoice: selectInvoicePaymentInvoice(state) || {},
    currency: selectInvoicePaymentCurrency(state) || {},
    invoiceLineItems: selectInvoicePaymentLineItems(state) || [],
    invoicePaymentOptionAmounts: selectInvoicePaymentOptionAmounts(state) || {},
    invoicePayments: selectInvoicePayments(state) || [],
    invoicePaymentSchedule: selectInvoicePaymentSchedule(state) || {},
    invoiceSchedulePaymentsPaymentOptionAmounts: selectInvoiceSchedulePaymentsPaymentOptionAmounts(state) || [],
    invoiceSchedulePaymentOptionAmounts: selectInvoiceSchedulePaymentOptionAmounts(state) || {},
    invoicePaymentError: selectInvoicePaymentError(state),
    group: group,
    isTeamBlockedFromPayments: selectIsTeamBlockedFromPayments(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PayInvoice);
