/**
 * DatePickerField Component Wrapper
 *
 * A 'Field' is used by reduxForms to wrap our internal toolkit components,
 * in this case Input. see http://redux-form.com/6.8.0/docs/api/Field.md/
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import DatePicker from 'components/baseComponents/DatePicker';

const DatePickerField = (props) => <Field component={ DatePicker } { ...props } />;

DatePickerField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  componentStyles: PropTypes.shape({}),
  className: PropTypes.string,
};

DatePickerField.defaultProps = {
  type: 'text',
  label: undefined,
  disabled: false,
  required: false,
  componentStyles: {},
  className: 'DatePicker',
};

export default DatePickerField;
