import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@teamsnap/teamsnap-ui';
import { Header, Panel } from 'components/teamsnapUIExtensions';
import styles from './InvoicingPlaceholder.module.scss';

class InvoicingPlaceholder extends PureComponent {
  render() {
    const { title, bodyText, buttonText, link, isDisabled } = this.props;

    return (
      <Panel>
        <div className={ styles.PlaceholderInnerContent }>
          <Header size="2" title={ title } iconLeftName="money" iconSize="25px" />

          <p className={ styles.PlaceholderBody }>{ bodyText }</p>

          <Button color="primary" icon="plus" onClick={ link } isDisabled={ isDisabled }>
            { buttonText }
          </Button>
        </div>
      </Panel>
    );
  }
}

InvoicingPlaceholder.propTypes = {
  title: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

InvoicingPlaceholder.defaultProps = {
  isDisabled: false
};

export default InvoicingPlaceholder;
