// TODO: Design / Style

import React, { Component } from 'react';

class NoMatch extends Component {
  render() {
    return <div>No Route Found</div>;
  }
}

export default NoMatch;
