import React from 'react';
import _range from 'lodash/range';
import { getYear } from 'utils/date-service';
import { Cell, Grid } from '@teamsnap/teamsnap-ui';
import InputField from 'components/baseComponents/Fields/InputField';
import SelectField from 'components/baseComponents/Fields/SelectField';
import styles from './CheckoutForm.module.scss';

const currentYear = getYear();
const yearOptions = _range(currentYear, currentYear + 11).map((year) => ({ title: year, value: year }));
const monthOptions = _range(1, 13).map((month) => ({ title: month, value: month }));

const WepayCreditCard = () => (
  <div>
    <Grid isWithGutter isFit>
      <Cell mods="u-sizeFull u-sm-size1of2">
        <InputField name="userEmail" label="Email Address" componentStyles={ styles } />
      </Cell>

      <Cell mods="u-sizeFull u-sm-size1of2">
        <InputField name="userName" label="Name on Card" componentStyles={ styles } />
      </Cell>

      <Cell mods="u-sizeFull u-sm-size1of2">
        <InputField
          name="ccNumber"
          label="Credit Card Number"
          pattern="[0-9]*"
          type="number"
          componentStyles={ styles }
        />
      </Cell>

      <Cell mods="u-sizeFull u-sm-size1of2">
        <InputField name="postalCode" label="Postal Code" componentStyles={ styles } />
      </Cell>
    </Grid>

    <Grid isWithGutter>
      <Cell mods="u-size1of2 u-sm-size1of6">
        <SelectField
          name="expMonth"
          label="Expiration Date"
          options={ monthOptions }
          placeholder="Month"
          componentStyles={ styles }
        />
      </Cell>

      <Cell mods="u-size1of2 u-sm-size1of6">
        <SelectField name="expYear" options={ yearOptions } placeholder="Year" componentStyles={ styles } />
      </Cell>

      <Cell mods="u-sizeFull u-sm-size1of6">
        <InputField name="cvv" label="Security Code" pattern="[0-9]*" type="number" componentStyles={ styles } />
      </Cell>
    </Grid>
  </div>
);

export default WepayCreditCard;
