import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@teamsnap/teamsnap-ui';

class Banner extends Component {
  renderIcon = (icon) => (
    <div className="Feedback-icon">
      <Icon name={ icon } />
    </div>
  );

  render() {
    const { icon, title, message, children, className, color, style, mods, otherProps } = this.props;

    const bannerStyle = {
      ...(!icon && { paddingLeft: '8px' }),
      ...style,
    };

    const bannerClassName = `${className} Feedback Feedback--${color} ${mods}`;

    return (
      <div className={ bannerClassName } role="alert" style={ bannerStyle } { ...otherProps }>
        { icon && this.renderIcon(icon) }
        { title && <span className="Feedback-title">{ title }</span> }
        { children || message }
      </div>
    );
  }
}

Banner.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  children: PropTypes.node,
  handleClose: PropTypes.func,
  className: PropTypes.string,
  color: PropTypes.oneOf([null, 'positive', 'negative', 'highlight']),
  style: PropTypes.shape({}),
  mods: PropTypes.string,
  otherProps: PropTypes.shape({}),
  autoClose: PropTypes.bool,
  autoCloseDuration: PropTypes.number,
};

Banner.defaultProps = {
  icon: null,
  title: null,
  message: null,
  children: null,
  handleClose: null,
  className: 'Feedback',
  color: null,
  style: {},
  mods: null,
  otherProps: {},
  autoClose: false,
  autoCloseDuration: 5000,
};

export default Banner;
