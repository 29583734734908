import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@teamsnap/teamsnap-ui';
import Modal from 'components/teamsnapUIExtensions/Modal';
import { hideModal } from 'state/modal/actions';
import PaymentLog from 'components/baseComponents/PaymentLog';
import styles from './ModalPaymentLog.module.scss';

class ModalPaymentLog extends Component {
  handleClose = () => {
    this.props.closeModal();
  };

  render() {
    const { invoice, payments, dateFormat } = this.props;

    return (
      <Modal
        overlay
        title={ `Payment Log for Invoice #${invoice.id}` }
        handleClose={ this.handleClose }
        componentStyles={ styles }
        className="ModalMedium"
      >
        <div className={ styles.ModalBody }>
          <PaymentLog heading="Payment Log" subHeading="Amount" dateFormat={ dateFormat } payments={ payments } />
        </div>

        <div className={ styles.ModalFooter }>
          <Button
            type="button"
            onClick={ this.handleClose }
            label="Close"
            icon="dismiss"
            size="medium"
            color="negative"
          />
        </div>
      </Modal>
    );
  }
}

ModalPaymentLog.propTypes = {
  invoice: PropTypes.shape({ id: PropTypes.string }).isRequired,
  payments: PropTypes.arrayOf(PropTypes.object).isRequired,
  closeModal: PropTypes.func.isRequired,
  dateFormat: PropTypes.string.isRequired,
};

export default connect(null, { closeModal: hideModal })(ModalPaymentLog);
