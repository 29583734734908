/**
 * Member Email Selector
 *
 * Various selectors using reselect for selecting data from state
 *
 */

import { createSelector } from 'reselect';
import _values from 'lodash/values';

const stateSelector = (state) => state.memberEmails;
const itemSelector = (state) => stateSelector(state).items;
const isFetchingSelector = (state) => stateSelector(state).isFetching;
const isErrorSelector = (state) => stateSelector(state).isError;

// Select member email Fetching or Error state
export const selectMemberEmailIsFetching = (state) => isFetchingSelector(state);
export const selectMemberEmailIsError = (state) => isErrorSelector(state);

// ReSelect - Select all member emails and return array
export const selectMemberEmails = createSelector(itemSelector, (memberEmails) => _values(memberEmails));

// Select all from member emails filtered by memberId
//  - This will not memoize using re-select, a problem in the lib with passing in dynamic arguments
export const selectMemberEmailsByMemberId = (state, memberId) =>
  selectMemberEmails(state).filter((memberEmail) => memberEmail.memberId === memberId);
