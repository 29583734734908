import { FETCH_CAN_UPDATE, SAVE_CAN_UPDATE, ERROR_CAN_UPDATE } from './actions';

const initialState = {
  isFetching: false,
  isError: false,
  data: null,
  status: null
};

export function canUpdateReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CAN_UPDATE:
      return {
        ...state,
        isFetching: true,
        isError: false,
        status: 'loading',
      };

    case SAVE_CAN_UPDATE:
      return {
        ...state,
        isFetching: false,
        isError: false,
        status: 'success',
        data: action.payload
      };

    case ERROR_CAN_UPDATE:
      return {
        ...state,
        isFetching: false,
        isError: true,
        status: 'error',
      };

    default:
      return state;
  }
}

