/**
 * Division Actions
 *
 * Various actions using redux-thunk and axios for managing communication with API and redux store
 *
 */

import { getCollection, queryCollection } from 'state/baseActions';

export const DIVISION = 'DIVISION';
export const DIVISION_PREFERENCES = 'DIVISION_PREFERENCES';

export const loadDivisionTree = (params) => (dispatch) => dispatch(getCollection(DIVISION, 'divisions/tree', params));

export const loadDivisionWithPreferences = (params) => (dispatch) =>
  dispatch(
    queryCollection(
      {
        DIVISION,
        DIVISION_PREFERENCES,
      },
      {
        division__id: params.divisionId,
        division_preferences__division_id: params.divisionId,
      },
    ),
  );
