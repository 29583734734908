import { isCurrency } from 'utils/validator';

export const validate = (values) => {
  const errors = {};

  const defaultError = 'Field can not be blank';
  const fields = ['amount', 'kind'];

  fields.forEach((field) => {
    if (!values[field]) {
      errors[field] = defaultError;
    }
    if (field === 'amount') {
      // check for positive values only
      if (values[field] < 0) {
        errors[field] = 'Amount must be a positive number';
      }

      // check for only numbers , or .
      const parsed = isCurrency(values.amount);

      if (!parsed.valid) {
        errors[field] = parsed.message;
      }
    }
  });

  return errors;
};
