// TODO: Update Member Send Workflow with apiv3
//
// The idea for beta is this should work for 'currently' selected members only.
//  - Show selected members from previous step to send.
//  - If no members selected redirect 'back' to the select members step
//      * Will LFE know about 'last' selected members outside of this workflow apiv3?
//

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Heading, Form, Panel, FormGroup } from 'components/teamsnapUIExtensions';
import { Button, Cell } from '@teamsnap/teamsnap-ui';
import { linkInvoices } from 'utils/links';
import { formatDate } from 'utils/date-service';
import InvoicingFormNav from 'components/baseComponents/InvoicingFormNav';
import invoiceCategories from 'components/baseComponents/InvoicingLineItems/lineItemCategories';
import InputField from 'components/baseComponents/Fields/InputField';
import TextAreaField from 'components/baseComponents/Fields/TextAreaField';
import { validate } from './validate';
import styles from './InvoicingFormSend.module.scss';

class InvoicingFormSend extends Component {
  componentDidMount() {
    const { change, groupName, batchInvoice } = this.props;
    change('subject', `New Invoice from ${groupName}`);
    change('body', `You have a new invoice for ${batchInvoice.title}.`);
  }

  renderLineItems = (batchInvoiceLineItems) =>
    batchInvoiceLineItems.map((lineItem) => (
      <li className={ styles.DotLeaderType } key={ lineItem.id }>
        { lineItem.description }
        <span className={ styles.TextSmall }>({ invoiceCategories[lineItem.invoiceCategoryId] })</span>
        <span className={ styles.DotLeaderValue }>{ lineItem.amountWithCurrency }</span>
      </li>
    ));

  redirectToInvoices = (groupId, batchInvoiceId, needsRefresh) => {
    if (needsRefresh) {
      window.location.assign(linkInvoices(groupId, batchInvoiceId));
    } else {
      this.props.history.push(linkInvoices(groupId, batchInvoiceId));
    }
  };

  renderFooter = () => {
    const { batchInvoice, submitting, groupType, needsRefresh } = this.props;

    return (
      <section className={ styles.Footer }>
        <p className={ styles.Note }>
          { /*
            <span>Note: </span>
            Adjustment must be applied to invoices on an individual basis, if you would like to
            apply any adjustment before sending invoices please skip this step.
          */ }
        </p>

        <div className={ styles.Buttons }>
          <Button
            type="button"
            mods="u-spaceRightMd"
            color="negative"
            icon="dismiss"
            onClick={ () => this.redirectToInvoices(batchInvoice[groupType], batchInvoice.id, needsRefresh) }
          >
            Skip This Step
          </Button>

          <Button color="primary" icon="send" type="submit" isDisabled={ submitting }>
            Send Invoices
          </Button>
        </div>
      </section>
    );
  };

  render() {
    const {
      handleSubmit,
      batchInvoice,
      batchInvoiceLineItems,
      invoiceMessageRecipients,
      dateFormat,
      groupId,
      groupType,
    } = this.props;

    return (
      <div>
        <InvoicingFormNav
          title="New Invoice"
          currentStep="send"
          groupId={ groupId }
          invoicingId={ batchInvoice.id }
          groupType={ groupType }
        />

        <Form handleSubmit={ handleSubmit }>
          <Panel isGrid title="Send Invoices" footerComponent={ this.renderFooter() } componentStyles={ styles }>
            <FormGroup size="11of24" title="Overview" isGrid>
              <Cell mods="u-size2of3">
                <h4 className={ styles.Label }>Batch Invoice Title</h4>
                <span className={ styles.Text }>{ batchInvoice.title }</span>
              </Cell>

              <Cell mods="u-sizeFit Grid-cell--right">
                <h4 className={ styles.LabelInline }>Due Date:</h4>
                <span className={ styles.Text }>{ formatDate(batchInvoice.dueAt, dateFormat) }</span>
              </Cell>

              <Cell mods="u-size1of1">
                <h4 className={ styles.Label }>Batch Invoice Description</h4>
                <span className={ styles.Text }>{ batchInvoice.description }</span>

                <Heading title="Invoices Overview" size={ 4 } />

                <ul className={ styles.DotLeader }>{ this.renderLineItems(batchInvoiceLineItems) }</ul>

                <hr />

                <ul className={ styles.DotLeaderBottom }>
                  <li className={ styles.DotLeaderType }>
                    Members Selected
                    <span className={ styles.DotLeaderValue }>{ invoiceMessageRecipients.length }</span>
                  </li>
                </ul>
              </Cell>
            </FormGroup>

            <FormGroup size="12of24" title="Email Message" cellModifiers="Grid-cell--right">
              <div className={ styles.Message }>
                <InputField name="subject" label="Subject:" />
                <TextAreaField name="body" label="Text of your email:" componentStyles={ styles } />
              </div>
            </FormGroup>
          </Panel>
        </Form>
      </div>
    );
  }
}

InvoicingFormSend.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  batchInvoice: PropTypes.shape().isRequired,
  batchInvoiceLineItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  invoiceMessageRecipients: PropTypes.arrayOf(PropTypes.string).isRequired,
  dateFormat: PropTypes.string,
  submitting: PropTypes.bool,
  groupType: PropTypes.string.isRequired,
  groupId: PropTypes.string,
  groupName: PropTypes.string,
  needsRefresh: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  change: PropTypes.func.isRequired,
};

InvoicingFormSend.defaultProps = {
  dateFormat: 'MM/DD/YYYY',
  submitting: false,
  groupId: null,
  groupName: '',
};

export default reduxForm({
  form: 'invoiceSend',
  validate,
})(InvoicingFormSend);
