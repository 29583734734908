import { connect } from 'react-redux';
import { getGroupId, getGroupType } from 'utils/group';
import { selectInvoiceIsFetching, selectInvoicesAndRecipientsByBatchRoute } from 'state/invoices/selectors';
import { selectBatchInvoiceByBatchRoute, selectBatchInvoiceFeesPassed } from 'state/batchInvoices/selectors';
import { selectBatchInvoiceLineItemsByBatchInvoiceId } from 'state/batchInvoiceLineItems/selectors';
import { selectBatchInvoicePaymentScheduleDetailsByBatchRoute } from 'state/batchInvoicePaymentScheduleDetails/selectors';
import { selectDateFormat } from 'state/group/selectors';
import { showModal } from 'state/modal/actions';
import { cancelBatchInvoice, queryBatchInvoiceWithMemberEmailCollections } from 'state/batchInvoices/actions';
import { cancelInvoice, deleteInvoiceAndReloadBatchInvoice } from 'state/invoices/actions';
import { selectInvoiceRecipientsByInvoiceRoute } from 'state/invoiceRecipients/selectors';
import InvoicingDetails from './InvoicingDetails';

const mapDispatchToProps = {
  showModal,
  cancelInvoice,
  cancelBatchInvoice,
  queryBatchInvoiceWithMemberEmailCollections,
  deleteInvoice: deleteInvoiceAndReloadBatchInvoice,
};

const mapStateToProps = (state, ownProps) => {
  const { params } = ownProps.match;

  return {
    loading: selectInvoiceIsFetching(state),
    batchInvoice: selectBatchInvoiceByBatchRoute(state) || {},
    batchInvoiceLineItems: selectBatchInvoiceLineItemsByBatchInvoiceId(state)(params.invoicingId),
    batchInvoicePaymentScheduleDetails: selectBatchInvoicePaymentScheduleDetailsByBatchRoute(state),
    feesPassed: selectBatchInvoiceFeesPassed(state, params.invoicingId),
    invoices: selectInvoicesAndRecipientsByBatchRoute(state) || [],
    batchInvoiceId: params.invoicingId,
    groupId: getGroupId(params),
    groupType: getGroupType(params),
    dateFormat: selectDateFormat(state),
    invoiceRecipient: selectInvoiceRecipientsByInvoiceRoute(state) || {},
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicingDetails);
