import PropTypes from 'prop-types';

export const batchInvoicePropShape = {
  amountCollected: PropTypes.number,
  amountCollectedWithCurrency: PropTypes.string,
  amountDue: PropTypes.number,
  amountDueWithCurrency: PropTypes.string,
  amountInvoiced: PropTypes.number,
  amountInvoicedWithCurrency: PropTypes.string,
  amountPaid: PropTypes.number,
  amountPaidWithCurrency: PropTypes.string,
  description: PropTypes.string,
  divisionId: PropTypes.string,
  failedInvoicesCount: PropTypes.number,
  id: PropTypes.string,
  invoicesCount: PropTypes.number,
  invoicesPaidCount: PropTypes.number,
  invoicesPastDueCount: PropTypes.number,
  invoicesUnpaidCount: PropTypes.number,
  isCancelable: PropTypes.bool,
  isDeletable: PropTypes.bool,
  isRecipientPayingTransactionFees: PropTypes.bool,
  lineItemsAmount: PropTypes.number,
  lineItemsAmountWithCurrency: PropTypes.string,
  paymentAdjustmentAmount: PropTypes.number,
  paymentAdjustmentAmountWithCurrency: PropTypes.string,
  processingFeePaidBy: PropTypes.string,
  status: PropTypes.string,
  teamId: PropTypes.string,
  title: PropTypes.string,
};
