/**
 * Feedback Component
 *
 * A global component to display messages to the user.
 * The banner is capable of three states:
 *
 * TYPE_ERROR - Red
 * TYPE_WARN - Yellow
 * TYPE_SUCCESS - Green
 *
 * example:
 *
 * <Feedback
 *   title='Message Title'
 *   message='A helpful message'
 *   type={ TYPE_ERROR }
 *   handleClose={ localHandleErrorFunction } />
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import defaultStyles from './Feedback.module.scss';

export const ERROR = 'Feedback--error';
export const WARN = 'Feedback--warn';
export const SUCCESS = 'Feedback--success';

class Feedback extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    type: PropTypes.string,
    handleClose: PropTypes.func,
    componentStyles: PropTypes.shape({}),
    className: PropTypes.string,
    autoClose: PropTypes.bool,
  };

  static defaultProps = {
    type: WARN,
    title: '',
    message: '',
    handleClose: null,
    componentStyles: {},
    className: 'Feedback',
    autoClose: true,
  };

  componentDidMount() {
    if (this.props.handleClose) {
      // eslint-disable-next-line
      window.__app_root.addEventListener('keydown', this.handleKeydown, false);
    }
    if (this.props.autoClose) {
      this.autoClose();
    }
  }

  componentWillUnmount() {
    if (this.props.handleClose) {
      // eslint-disable-next-line
      window.__app_root.removeEventListener('keydown', this.handleKeydown, false);
    }
  }

  getFeedbackType = (type, styles) => styles[type];

  handleKeydown = (e) => {
    if (e.key === 'Escape' || e.keyCode === 27) {
      this.props.handleClose();
    }
  };

  autoClose = () => {
    const { handleClose } = this.props;

    setTimeout(() => {
      handleClose();
    }, 5000);
  };

  render() {
    const { title, message, type, componentStyles, className, handleClose } = this.props;
    const styles = { ...defaultStyles, ...componentStyles };

    return (
      <div className={ this.getFeedbackType(type, styles) }>
        <button className={ styles.FeedbackClose } onClick={ handleClose }>
          X
        </button>
        <div className={ styles[className] }>
          { title && <strong className={ styles.FeedbackTitle }>{ title } </strong> }
          <span className={ styles.FeedbackMessage }>{ message }</span>
        </div>
      </div>
    );
  }
}

export default Feedback;
