export const validate = (values) => {
  const errors = {};

  if (!values.status) {
    errors.status = 'You must select an option.';
  }
  if (!values.subject) {
    errors.subject = 'Subject can not be blank.';
  }

  if (!values.body) {
    errors.body = 'Message body can not be blank.';
  }

  return errors;
};
