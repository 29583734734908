import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Header } from 'components/teamsnapUIExtensions';
import {
  Grid,
  Cell,
  Panel,
  PanelHeader,
  PanelBody,
  PanelRow,
  SummaryList,
  Icon,
  ProgressBar,
  Button,
  TextLink,
} from '@teamsnap/teamsnap-ui';
import WidgetPaymentOverview from 'components/baseComponents/WidgetPaymentOverview';
import ModalInstallments from 'components/baseComponents/ModalInstallments';
import { calculatePercent } from 'utils/utils';
import { formatDate } from 'utils/date-service';
import { linkInvoicingMembers, linkInvoicing, linkInvoicingEdit } from 'utils/links';
import styles from './InvoicingDetailsOverview.module.scss';
import { batchInvoicePropShape } from 'interfaces/batchInvoice';
import { historyPropShape } from 'interfaces/navigation';

class InvoicingDetailsOverview extends PureComponent {
  showInstallmentModal = () => {
    const { showModal, batchInvoicePaymentScheduleDetails: paymentSchedule, dateFormat } = this.props;

    // TODO: Add batchInvoicePaymentScheduleDetails, once available from apiv3
    return showModal(ModalInstallments, { paymentSchedule, dateFormat });
  };

  renderPaymentType = (text) => (
    <li className={ styles.InvoicePaymentOptionActive }>
      <Icon name="check" style={ { fontSize: '7px', color: '#7FC243' } } /> { text }
    </li>
  );

  renderRemainingPayments = () => {
    const { batchInvoicePaymentScheduleDetails } = this.props;

    if (batchInvoicePaymentScheduleDetails.length > 0) {
      return (
        <TextLink location={ null } onClick={ this.showInstallmentModal }>
          { `${batchInvoicePaymentScheduleDetails.length + 1} Total Installments` }
        </TextLink>
      );
    }

    return <span>Not Activated</span>;
  };

  render() {
    const { batchInvoice, batchInvoiceLineItems, groupId, groupType, dateFormat, feesPassed, history } = this.props;

    const isClub = groupType === 'divisionId';

    let lineItems = [];

    if (isClub) {
      lineItems = batchInvoiceLineItems.map((lineItem) => ({
        description: lineItem.description,
        value: lineItem.amountWithCurrency,
        key: `${lineItem.id}-${lineItem.description}`,
      }));
    }

    lineItems.push({
      isFooterRow: isClub,
      description: 'Invoice Total',
      value: batchInvoice.lineItemsAmountWithCurrency || '',
    });

    return (
      <section>
        <Header size={ 1 } title={ batchInvoice.title } componentStyles={ styles }>
          <div>
            <Button mods="u-spaceRightSm" onClick={ () => history.push(linkInvoicing(groupId)) } icon="left">
              Back to Invoicing
            </Button>
            <Button mods="u-spaceRightSm" onClick={ () => history.push(linkInvoicingEdit(groupId, batchInvoice.id)) } icon="edit">
              Edit Invoice
            </Button>
            <Button
              onClick={ () => history.push(linkInvoicingMembers(groupId, batchInvoice.id)) }
              color="primary"
              icon="plus"
            >
              Add Recipients
            </Button>
          </div>
        </Header>

        <Grid mods="Grid--withGutter u-padBottomMd">
          <Cell mods="u-size1of3">
            <Panel style={ { height: '100%' } }>
              <PanelHeader>
                <span className="Panel-title">Invoice Info</span>
              </PanelHeader>
              <PanelBody>
                <PanelRow>
                  <h5 className={ styles.SubHeader }>Description</h5>
                  <p>{ batchInvoice.description }</p>
                </PanelRow>
                <PanelRow>
                  <h5 className={ styles.SubHeader }>{ isClub ? 'Line Items' : 'Amount' }</h5>
                  <SummaryList hasBorder={ false } items={ lineItems } />
                </PanelRow>
              </PanelBody>
            </Panel>
          </Cell>

          <Cell mods="u-size1of3">
            <Panel style={ { height: '100%' } }>
              <PanelHeader>
                <span className="Panel-title">Payments Overview</span>
              </PanelHeader>
              { batchInvoice.id && (
                <PanelBody>
                  <PanelRow>
                    <WidgetPaymentOverview
                      amountCollected={ batchInvoice.amountCollected }
                      amountTotal={ batchInvoice.amountInvoiced }
                      amountTotalWithCurrency={ batchInvoice.amountInvoicedWithCurrency }
                      amountCollectedWithCurrency={ batchInvoice.amountCollectedWithCurrency }
                      amountDueWithCurrency={ batchInvoice.amountDueWithCurrency }
                      openInvoices={ batchInvoice.invoicesUnpaidCount }
                      paidInvoices={ batchInvoice.invoicesPaidCount }
                      totalInvoices={ batchInvoice.invoicesNotCanceledCount }
                      note={
                        feesPassed
                          ? '* Transaction Fees Passed on to Recipient.'
                          : '* Transaction Fees Paid by Organization.'
                      }
                    />
                  </PanelRow>
                </PanelBody>
              ) }
            </Panel>
          </Cell>

          <Cell mods="u-size1of3">
            <Panel style={ { height: '100%' } }>
              <PanelHeader>
                <span className="Panel-title">Payments Schedule</span>
              </PanelHeader>
              <PanelBody>
                <PanelRow>
                  <h5 className={ styles.OverviewLabelInline }>Due Date</h5>
                  <span
                    className={
                      batchInvoice.dueAt < new Date()
                        ? styles.OverviewInlineDetailsNegative
                        : styles.OverviewInlineDetails
                    }
                  >
                    { formatDate(batchInvoice.dueAt, dateFormat) }
                  </span>
                </PanelRow>
                <PanelRow>
                  <h5 className={ styles.OverviewLabelInline }>Past Due Invoices</h5>
                  <span className={ styles.OverviewInlineDetails }>
                    { batchInvoice.invoicesPastDueCount }
                    <ProgressBar
                      progress={ calculatePercent(batchInvoice.invoicesCount, batchInvoice.invoicesPastDueCount) }
                      color="negative"
                      style={ { display: 'inline-block', marginLeft: '15px', width: '55%' } }
                    />
                  </span>
                </PanelRow>
                <PanelRow>
                  <h5 className={ styles.OverviewLabelInline }>Installment Payments</h5>
                  { this.renderRemainingPayments() }
                </PanelRow>
                { /* <PanelRow>
                  <h5 className={ styles.OverviewLabelInline }>Errored Invoices</h5>
                  <span>
                    8
                    <ProgressBar
                      progress={ 50 }
                      color='negative'
                      style={{ display: 'inline-block', marginLeft: '15px', width: '57%' }} />
                  </span>
                </PanelRow> */ }
              </PanelBody>
            </Panel>
          </Cell>
        </Grid>
      </section>
    );
  }
}

InvoicingDetailsOverview.propTypes = {
  groupId: PropTypes.string.isRequired,
  groupType: PropTypes.string.isRequired,
  feesPassed: PropTypes.bool.isRequired,
  batchInvoice: PropTypes.shape(batchInvoicePropShape).isRequired,
  batchInvoiceLineItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  batchInvoicePaymentScheduleDetails: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dateFormat: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired,
  history: PropTypes.shape(historyPropShape).isRequired,
};

export default InvoicingDetailsOverview;
