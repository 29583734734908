/**
 * RadioButton Component
 *
 * This is a 'helper' component that calls InputControl with the appropriate
 * default props for a 'radio button'
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import InputControl from './InputControl';
import defaultStyles from './RadioButton.module.scss';

const RadioButton = ({ componentStyles, ...props }) => {
  const styles = { ...defaultStyles, ...componentStyles };
  return <InputControl { ...props } type="radio" componentStyles={ styles } />;
};

RadioButton.propTypes = {
  componentStyles: PropTypes.shape({}),
};

RadioButton.defaultProps = {
  componentStyles: {},
};

export default RadioButton;
