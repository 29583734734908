/**
 * RadioButtonField Component Wrapper
 *
 * A 'Field' is used by reduxForms to wrap our internal toolkit components,
 * in this case Toggle. see http://redux-form.com/6.8.0/docs/api/Field.md/
 *
 * This component accepts an array of options for multiple radio controls.
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { RadioButton } from 'components/teamsnapUIExtensions/InputControl';
import { inputOptionPropShape } from 'interfaces/fields';

const RadioButtonField = (props) => <Field component={ RadioButton } { ...props } />;

RadioButtonField.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape(inputOptionPropShape)).isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  size: PropTypes.string,
  componentStyles: PropTypes.shape({}),
  className: PropTypes.string,
};

RadioButtonField.defaultProps = {
  label: undefined,
  disabled: false,
  required: false,
  size: undefined,
  componentStyles: {},
  className: 'InputControl',
};

export default RadioButtonField;
