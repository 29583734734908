import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'components/teamsnapUIExtensions';
import PageHeader from './PageHeader';
import PageFooter from './PageFooter';
import styles from '../PayInvoice.module.scss';
import PaymentsDisabled from './PaymentsDisabled';

const PageLayout = ({ isFetching, children, paymentsDisabled, isCanadian, subHeader }) => (
  <main>
    <PageHeader />
    { isFetching && <Loader type="spin" /> }

    { subHeader && <div style={ { maxWidth: '750px', margin: '0 auto' } }>{ subHeader }</div> }

    { !isFetching && <div className={ styles.MobileContainer }>{ children } </div> }

    { !isFetching && paymentsDisabled && <PaymentsDisabled isCanadian={ isCanadian } /> }

    <PageFooter />
  </main>
);

PageLayout.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  children: PropTypes.node,
  paymentsDisabled: PropTypes.bool.isRequired,
  isCanadian: PropTypes.bool,
  subHeader: PropTypes.node,
};

PageLayout.defaultProps = {
  children: null,
  isCanadian: false,
};

export default PageLayout;
