import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InvoicingFormInfo from 'components/baseComponents/InvoicingFormInfo';
import { Loader } from 'components/teamsnapUIExtensions';
import { formatDate } from 'utils/date-service';
import { linkInvoices } from 'utils/links';
import invoiceCategories from 'components/baseComponents/InvoicingLineItems/lineItemCategories';
import { getGroupId, getGroupType } from 'utils/group';
import { batchInvoicePropShape } from 'interfaces/batchInvoice';
import { matchPropShape, historyPropShape } from 'interfaces/navigation';

import { SUCCESS, ERROR } from 'components/teamsnapUIExtensions/Feedback/Feedback';

class InvoicingEdit extends Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const {
      loadBatchInvoiceLineItems,
      loadBatchInvoices,
      match: {
        params: { invoicingId },
      },
    } = this.props;
    loadBatchInvoiceLineItems({ invoicingId });
    loadBatchInvoices({ id: invoicingId });
  }

  handleSubmit = (props) => {
    const {
      updateBatchInvoice,
      match: {
        params: { invoicingId },
      },
    } = this.props;
    const groupId = getGroupId(this.props.match.params);

    const updatedInvoice = {
      id: invoicingId,
      title: props.batchInvoice.title,
      description: props.batchInvoice.description,
      dueAt: formatDate(props.batchInvoice.dueAt),
      batchInvoiceLineItems: props.batchInvoiceLineItems,
    };

    return updateBatchInvoice(updatedInvoice)
      .then(() => this.props.showFeedback({
        title: '',
        message: 'Success! The invoice has been edited.',
        type: SUCCESS,
      }))
      .then(() => this.props.history.push(linkInvoices(groupId, invoicingId)))
      .catch(() => this.props.showFeedback({
        title: 'Oops!',
        message: 'Something went wrong on our end. Please try again, and if the error persists let us know.',
        type: ERROR,
      }));
  };

  render() {
    const {
      isFetching,
      batchInvoiceLineItems,
      batchInvoice,
      dateFormat,
      history,
      match: {
        params: { invoicingId },
      },
    } = this.props;
    const groupId = getGroupId(this.props.match.params);
    const groupType = getGroupType(this.props.match.params);

    // Return Loader if still fetching items
    if (isFetching) {
      return <Loader message="random" />;
    }

    const lineItems = batchInvoiceLineItems.map((item) => ({
      id: item.id,
      description: item.description,
      amount: item.amountWithCurrency,
      category: invoiceCategories[item.invoiceCategoryId],
    }));

    // Initial Values set for reduxForm field sections
    const initialValues = {
      batchInvoiceLineItems: { lineItems },
      batchInvoice: {
        title: batchInvoice.title,
        description: batchInvoice.description,
        dueAt: formatDate(batchInvoice.dueAt, 'MM/DD/YYYY'),
      },
    };

    return (
      <InvoicingFormInfo
        onSubmit={ this.handleSubmit }
        history={ history }
        groupId={ groupId }
        groupType={ groupType }
        invoicingId={ invoicingId }
        initialValues={ initialValues }
        dateFormat={ dateFormat }
        hideLineItems={ true }
        disableLineItems={ true }
      />
    );
  }
}

InvoicingEdit.propTypes = {
  match: PropTypes.shape(matchPropShape).isRequired,
  history: PropTypes.shape(historyPropShape).isRequired,
  batchInvoice: PropTypes.shape(batchInvoicePropShape).isRequired,
  batchInvoiceLineItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loadBatchInvoiceLineItems: PropTypes.func.isRequired,
  loadBatchInvoices: PropTypes.func.isRequired,
  updateBatchInvoice: PropTypes.func.isRequired,
  showFeedback: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  dateFormat: PropTypes.string.isRequired,
};

export default InvoicingEdit;
