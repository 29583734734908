/**
 * Invoice Payment Transaction Actions
 *
 * Various actions using redux-thunk and axios for managing communication with API and redux store
 *
 */

import { getCollection } from 'state/baseActions';

export const INVOICE_PAYMENT_TRANSACTION = 'INVOICE_PAYMENT_TRANSACTION';

// TODO: Some naming 'issues' have occured.  We need to separate out invoicePayment && invoicePaymentTransaction

// Load Invoice Payments
export const loadInvoicePaymentTransactions = (params) => (dispatch) =>
  dispatch(getCollection(INVOICE_PAYMENT_TRANSACTION, 'invoice_payment_transactions/search', params));
