import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';
import SectionHeading from 'components/teamsnapUIExtensions/SectionHeading';
import Table from 'components/teamsnapUIExtensions/Table';
import { Grid, Cell, Icon } from '@teamsnap/teamsnap-ui';
import { setupMemberRecipientColumns } from 'components/screens/InvoicingMembers/memberRecipients';
import styles from './InvoiceMembers.module.scss';
import { memberPropShape } from 'interfaces/member';

class InvoiceMembers extends PureComponent {
  addAllCurrentMembers = (members) => {
    const { selectedMembers, reduxFieldChange } = this.props;

    // Filter out previously invoiced members and map over to return the required attributes.
    const newMembers = members.filter((member) => !member.isInvoiced && !member.isSelected);

    // Check if any newMembers exist and if they are equal to previous state.
    if (newMembers.length === 0 || _isEqual(selectedMembers, newMembers)) {
      return;
    }

    // Fire the ReduxForm 'change' method and merge array fields
    reduxFieldChange('members', [...selectedMembers, ...newMembers]);
  };

  renderTableMessage = () => {
    const { isFetching, members, noResults } = this.props;

    const content = {
      heading: 'Please search for members above.',
      body: 'Note: searches returning 2,000 or more results may experience a longer load time.',
    };

    if (noResults) {
      content.heading = 'No members found.';
      content.body = `Please adjust your search above. ${content.body}`;
    }

    if (!isFetching && members.length === 0) {
      return (
        <Grid mods="u-padLg">
          <Cell mods="u-sizeFit">
            <Icon name="team" style={ { fontSize: '100px', color: '#ccc' } } />
          </Cell>

          <Cell mods="u-sizeFill u-spaceLeftMd">
            <h4 className={ styles.PlaceholderHeader }>{ content.heading }</h4>
            <p className={ styles.PlaceholderContent }>{ content.body }</p>
          </Cell>
        </Grid>
      );
    }

    return null;
  };

  render() {
    const { isFetching, members, groupType } = this.props;

    return (
      <div>
        <SectionHeading title="Select Members" />
        <Table
          loadingMessage={ isFetching ? 'Loading Members...' : null }
          columns={ setupMemberRecipientColumns(members, this.addAllCurrentMembers, groupType) }
          rows={ members }
          perPage={ 20 }
          defaultSort="nameAndRole.sortValue"
        >
          { this.renderTableMessage() }
        </Table>
      </div>
    );
  }
}

InvoiceMembers.propTypes = {
  reduxFieldChange: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  members: PropTypes.arrayOf(PropTypes.shape(memberPropShape)),
  selectedMembers: PropTypes.arrayOf(PropTypes.shape(memberPropShape)),
  noResults: PropTypes.bool,
  groupType: PropTypes.string,
};

InvoiceMembers.defaultProps = {
  members: [],
  selectedMembers: [],
  noResults: false,
  groupType: null,
};

export default InvoiceMembers;
