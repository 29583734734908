import PropTypes from 'prop-types';

export const memberPropShape = {
  divisionId: PropTypes.string,
  emailAddresses: PropTypes.arrayOf(PropTypes.string),
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  href: PropTypes.string,
  id: PropTypes.string,
  isActivated: PropTypes.bool,
  isCoach: PropTypes.bool,
  isCommissioner: PropTypes.bool,
  isDeletable: PropTypes.bool,
  isEditable: PropTypes.bool,
  isLeagueOwner: PropTypes.bool,
  isManager: PropTypes.bool,
  isNonPlayer: PropTypes.bool,
  isOwner: PropTypes.bool,
  position: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  userId: PropTypes.string,
  teamId: PropTypes.string,
};

export const invoiceRecipientPropShape = {
  invoiceableId: PropTypes.string,
};
