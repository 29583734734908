/**
 * Router Location Selector
 *
 * Various selectors using reselect for selecting data from state
 *
 */

const paramSelector = (state) => state.routerLocation.params;

export const selectResourceType = (state) => paramSelector(state).resourceType;

export const selectResourceId = (state) => paramSelector(state).resourceId;

export const selectBatchInvoiceId = (state) => paramSelector(state).invoicingId;

export const selectInvoiceId = (state) => paramSelector(state).invoiceId;
