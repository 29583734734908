/**
 * Paginate Component
 *
 * A paginate component that requires currentPage, totalPages and an onClick event handler
 * It also accepts props to override default pages shown and first, last, next, prev links.
 *
 * TODO:
 *  -> Need to load styles from TeamSnap UI (once created)
 *  -> Instead of showing a 'first' and 'last' link.  We could show the last page instead.
 *  -> Add displayedPages to dynamically set the number of links shown at a time.
 *  -> Could add more flexibility to the links?  Allow text to be passed for Next, Prev, ect ?
 *
 */

import React, { PureComponent } from 'react';
import _range from 'lodash/range';
import { Cell } from '@teamsnap/teamsnap-ui';
import PropTypes from 'prop-types';
import defaultStyles from './Paginate.module.scss';

// This sets up the logic on when the page links 'rotate' to show more or less
// links according to the current page and placement in the displayed links.
const setupPageLinks = (currentPage, totalPages) => {
  const displayedPages = 5;
  let startPage = currentPage - 2;
  let endPage = currentPage + 2;

  if (totalPages <= displayedPages) {
    startPage = 1;
    endPage = totalPages;
  } else if (currentPage <= 3) {
    startPage = 1;
    endPage = displayedPages;
  } else if (currentPage + 2 >= totalPages) {
    startPage = totalPages - 4;
    endPage = totalPages;
  }

  return _range(startPage, endPage + 1);
};

class Paginate extends PureComponent {
  renderPageLink = (page, styles, name = null) => {
    const { currentPage, totalPages, onClick } = this.props;
    let linkClassName = 'PaginateItem';

    if (!name && page === currentPage) {
      linkClassName = 'PaginateItemIsActive';
    } else if ((name === 'First' || name === 'Prev') && currentPage <= 1) {
      linkClassName = 'PaginateItemIsDisabled';
    } else if ((name === 'Next' || name === 'Last') && currentPage >= totalPages) {
      linkClassName = 'PaginateItemIsDisabled';
    }

    return (
      <li key={ name || page } className={ styles[linkClassName] }>
        <a href={ `#${page}` } onClick={ onClick }>
          { name || page }
        </a>
      </li>
    );
  };

  renderPageList = (styles) => {
    const { currentPage, totalPages } = this.props;
    const pages = setupPageLinks(currentPage, totalPages);

    return pages.map((page) => this.renderPageLink(page, styles));
  };

  render() {
    const {
      currentPage,
      totalPages,
      countDisplay,
      showFirst,
      showLast,
      showPrev,
      showNext,
      componentStyles,
      className,
    } = this.props;
    const styles = { ...defaultStyles, ...componentStyles };
    const cellClasses = className === 'PaginateRight' ? 'u-size1of2 u-textRight' : 'u-textCenter';

    return (
      <Cell mods={ cellClasses }>
        <span className={ styles.PaginateCounter }>{ countDisplay }</span>
        <ul className={ styles[className] }>
          { showFirst && this.renderPageLink(1, styles, 'First') }
          { showPrev && this.renderPageLink(currentPage - 1, styles, 'Prev') }

          { this.renderPageList(styles) }

          { showNext && this.renderPageLink(currentPage + 1, styles, 'Next') }
          { showLast && this.renderPageLink(totalPages, styles, 'Last') }
        </ul>
      </Cell>
    );
  }
}

Paginate.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  countDisplay: PropTypes.string,
  showFirst: PropTypes.bool,
  showLast: PropTypes.bool,
  showPrev: PropTypes.bool,
  showNext: PropTypes.bool,
  componentStyles: PropTypes.shape({}),
  className: PropTypes.string,
};

Paginate.defaultProps = {
  countDisplay: null,
  showFirst: false,
  showLast: false,
  showPrev: true,
  showNext: true,
  componentStyles: {},
  className: 'Paginate',
};

export default Paginate;
