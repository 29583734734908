import React from 'react';
import PropTypes from 'prop-types';
import { SummaryList } from '@teamsnap/teamsnap-ui';
import styles from '../PayInvoice.module.scss';
import { invoicePropShape } from 'interfaces/invoice';

const InvoiceSummary = ({ invoiceLineItems, invoice }) => {
  const items = invoiceLineItems.map((item) => ({
    key: item.id,
    description: item.description,
    value: item.amountWithCurrency,
  }));

  return (
    <section className={ [styles.Component, 'u-padBottomLg'].join(' ') }>
      <SummaryList
        items={ items }
        heading="Invoice Summary"
        subHeading="Amount"
        footer={ invoice && { description: 'Balance:', value: invoice.balanceWithCurrency } }
      />
    </section>
  );
};

InvoiceSummary.propTypes = {
  invoiceLineItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  invoice: PropTypes.shape(invoicePropShape),
};

InvoiceSummary.defaultProps = {
  invoice: null,
};

export default InvoiceSummary;
