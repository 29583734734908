import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@teamsnap/teamsnap-ui';

class PaymentFeedback extends PureComponent {
  render() {
    let className = 'Feedback Feedback--positive';
    let iconName = 'check';
    let message = 'Success! Your installment plan has been updated with your new card.';

    if (this.props.status === 'error') {
      className = 'Feedback Feedback--negative';
      iconName = 'alert';
      message = 'Oops, Something went wrong while updating your plan.  Please try again.';
    }

    return (
      <div className={ className } role="alert">
        <div className="Feedback-icon">
          <Icon name={ iconName } />
        </div>
        <h5 className="Feedback-title">{ message }</h5>
      </div>
    );
  }
}

PaymentFeedback.propTypes = {
  status: PropTypes.string,
};

PaymentFeedback.defaultProps = {
  status: null,
};

export default PaymentFeedback;
