import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import NoMatch from 'components/baseComponents/NoMatch';
import AppRoute from 'components/baseComponents/AppRoute';
import { routePropShape } from 'interfaces/navigation';

class AppRoutes extends Component {
  render() {
    const { routes, rootPath } = this.props;

    return (
      <Switch>
        { routes.map((route) => (
          <AppRoute
            key={ route.path }
            path={ rootPath ? `${rootPath}${route.path}` : route.path }
            exact={ route.exact }
            strict={ route.strict }
            component={ route.component }
          />
        )) }

        <Route component={ NoMatch } />
      </Switch>
    );
  }
}

AppRoutes.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape(routePropShape)).isRequired,
  rootPath: PropTypes.string.isRequired,
};

export default AppRoutes;
