import PropTypes from 'prop-types';

export const matchPropShape = {
  params: PropTypes.shape({
    teamId: PropTypes.string,
    divisionId: PropTypes.string,
    invoicingId: PropTypes.string,
  }),
  path: PropTypes.string,
};

export const historyPropShape = {
  push: PropTypes.func,
};

export const locationPropShape = {
  pathname: PropTypes.string,
};

export const paginatePropShape = {
  hasNext: PropTypes.bool,
  hasPrev: PropTypes.bool,
};

export const routePropShape = {
  path: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  exact: PropTypes.bool,
};
