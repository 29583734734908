import _forOwn from 'lodash/forOwn';

// -----------------------------------
// selectAndSortBy
//
// Takes a collection and a by (object key, i.e. memberId)
// and sorts them
// -----------------------------------
export const selectAndSortBy = (collection, by) => {
  const sortedBy = {};
  _forOwn(collection, (item) => {
    if (item[by] !== undefined) {
      if (!sortedBy[item[by]]) {
        sortedBy[item[by]] = [];
      }
      sortedBy[item[by]].push(item);
    }
  });
  return sortedBy;
};
