import _uniqueId from 'lodash/uniqueId';
import _get from 'lodash/get';
import sortBy from 'utils/sorter';

// Make sure all items have a unique key if no id provided.
export const updateItems = (rows) => {
  let items = null;
  // return rows if id is already provided
  if (rows.length && 'id' in rows[0]) {
    items = rows;
  } else {
    items = rows.map((row) => ({ id: _uniqueId('row-'), ...row }));
  }
  return items;
};

// Set Table Filter status by filterable columns
export const setFilters = (columns) => {
  const filterColumns = columns.filter((column) => column.filterable);
  let isFilterable = false;
  let filter = null;
  let filterCategories = [];

  if (filterColumns.length) {
    isFilterable = true;
    filter = filterColumns[0].key;
    filterCategories = filterColumns.map((filterColumn) => ({ title: filterColumn.label, value: filterColumn.key }));
  }

  return {
    isFilterable,
    filter,
    filterCategories,
  };
};

// Filter items in state by typed input value
export const filterItems = (items, filter, filterValue, sort, sortReverse) => {
  const currentItems = items.filter((item) => {
    const rowData = _get(item, filter, '');
    let currentValue = rowData || '';

    // If value is an object assume React Element and look for data-sort or data-filter prop
    if (rowData != null && typeof rowData === 'object') {
      currentValue = rowData.props['data-filter'] || rowData.props['data-sort'];
    }

    // Search String StartsWith Value
    // return rowValue.substring(0, inputValue.length) === inputValue

    // Search String Contains Value
    return currentValue.toString().toLowerCase().includes(filterValue);
  });

  return sort ? sortBy(sort, currentItems, sortReverse) : currentItems;
};
