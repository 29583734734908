/* global WePay  */

import { wepayClientId } from './window';

/*
 * WePay
 *  -> Payment Processing
 *
 * This uses the 'custom-checkout' provided by WePay for processing cards.  It
 * takes specific parameters and passes back a credit_card_id which we send to apiv3
 * for further processing.  https://developer.wepay.com/docs/process-payments/custom-checkout
 *
 * This utility uses the WePay tokenization library loaded externally from their
 * servers.  https://static.wepay.com/js/tokenization.v2.js
 *
 */

export const loadWePayScript = (actionCallback) => {
  if (typeof WePay === 'undefined') {
    const script = document.createElement('script');
    script.src = 'https://static.wepay.com/min/js/tokenization.3.latest.js';
    script.onload = () => actionCallback(true);
    document.body.appendChild(script);
  } else {
    actionCallback(true);
  }
};

export const wepayCreateToken = (card, callback) => {
  const creditCard = {
    client_id: wepayClientId,
    user_name: card.userName,
    cc_number: card.ccNumber,
    cvv: card.cvv,
    expiration_month: card.expMonth,
    expiration_year: card.expYear,
    email: card.userEmail.trim(),
    address: { country: card.countryCode, postal_code: card.postalCode },
  };

  const wepayEnv = process.env.REACT_APP_INVOICING_ENV === 'production' ? 'production' : 'stage';
  WePay.set_endpoint(wepayEnv);
  WePay.credit_card.create(creditCard, callback);
};
