import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tab from 'components/baseComponents/Tab';

const Tabs = ({ children }) => {
  const [activeTab, setActiveTab] = useState('');

  useEffect(() => {
    setActiveTab(children[0].props.label);
  }, []);

  const onClickTabItem = (tab) => setActiveTab(tab);

  return (
    <div className="Tabs">
      <ol className="Tabs-header">
        { children.map(tab => {
          const { label, onClick } = tab.props;
          return (
            <Tab
              { ...tab.props }
              activeTab={ activeTab }
              key={ label }
              label={ label }
              onClick={ (tab) => {
                onClickTabItem(tab);
                if (onClick) onClick();
              } }
            />
          );
        }) }
      </ol>
      <div className="tabs-contentItem">
        { children.map((tab) => {
          if (tab.props.label !== activeTab) return undefined;
          return tab.props.children;
        }) }
      </div>
    </div>
  );
};

Tabs.propTypes = {
  children: PropTypes.array.isRequired
};

export default Tabs;
