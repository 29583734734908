import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TextLink, Icon } from '@teamsnap/teamsnap-ui';
import { stringifyArray } from 'utils/utils';
import styles from './InvoicingRecipients.module.scss';

class RecipientItem extends PureComponent {
  handleClick = (e) => {
    e.preventDefault();

    const { member, handleRemove } = this.props;
    return handleRemove(member.id);
  };

  render() {
    const { member } = this.props;
    const memberName = member.teamName || member.divisionName ? `${member.fullName} | ` : member.fullName;
    const memberDetails = stringifyArray([member.teamName, member.divisionName]);

    return (
      <li className={ styles.MemberListItem }>
        <strong className={ styles.MemberListName }> { memberName } </strong>
        <span className={ styles.MemberListDetails }> { memberDetails } </span>
        <TextLink location={ null } onClick={ this.handleClick }>
          <Icon name="dismiss" style={ { fontSize: '12px', color: '#e26362' } } />
        </TextLink>
      </li>
    );
  }
}

RecipientItem.propTypes = {
  member: PropTypes.shape({
    teamName: PropTypes.string,
    divisionName: PropTypes.string,
    fullName: PropTypes.string,
    id: PropTypes.string,
  }),
  handleRemove: PropTypes.func,
};

RecipientItem.defaultProps = {
  member: {},
  handleRemove: null,
};

export default RecipientItem;
