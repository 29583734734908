/**
 * Batch Invoice Payment Schedule Details
 *
 * Various actions using redux-thunk and axios for managing communication with API and redux store
 *
 */

import { getCollection } from 'state/baseActions';

export const BATCH_INVOICE_PAYMENT_SCHEDULE_DETAIL = 'BATCH_INVOICE_PAYMENT_SCHEDULE_DETAIL';

export const loadBatchInvoicePaymentScheduleDetails = (batchInvoiceId) => (dispatch) =>
  dispatch(
    getCollection(BATCH_INVOICE_PAYMENT_SCHEDULE_DETAIL, 'batch_invoice_payment_schedule_details/search', {
      batchInvoiceId,
    }),
  );
