import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { createReducer } from 'state/baseReducer';
import routerLocationReducer from 'state/routerLocation/reducer';
import feedbackReducer from 'state/feedback/reducer';
import invoiceMessageRecipientsReducer from 'state/invoiceMessageRecipients/reducer';
import modalReducer from 'state/modal/reducer';
import appReducer from 'state/app/reducer';
import payInvoiceReducer from 'state/payInvoice/reducer';
import groupReducer from 'state/group/reducer';
import paymentAccountReducer from 'state/paymentAccount/reducer';
import depositAccountReducer from 'state/depositAccount/reducer';
import depositAccountOnboardingReducer from 'state/depositAccountOnboarding/reducer';
import { canUpdateReducer } from './canUpdate/reducer';
import { canAcceptPaymentReducer } from './canAcceptPayment/reducer';

import { BATCH_INVOICES_AGGREGATE } from 'state/batchInvoiceAggregates/actions';
import { BATCH_INVOICE_LINE_ITEM } from 'state/batchInvoiceLineItems/actions';
import { BATCH_INVOICE_PAYMENT_SCHEDULE_DETAIL } from 'state/batchInvoicePaymentScheduleDetails/actions';
import { BATCH_INVOICE_PAYMENT_SCHEDULE } from 'state/batchInvoicePaymentSchedules/actions';
import { BATCH_INVOICE } from 'state/batchInvoices/actions';
import { DIVISION_AGGREGATE } from 'state/divisionAggregates/actions';
import { DIVISION } from 'state/divisions/actions';
import { INVOICE_LINE_ITEM } from 'state/invoiceLineItems/actions';
import { INVOICE_PAYMENT } from 'state/invoicePayments/actions';
import { INVOICE_PAYMENT_TRANSACTION } from 'state/invoicePaymentTransactions/actions';
import { INVOICE_RECIPIENT } from 'state/invoiceRecipients/actions';
import { INVOICE } from 'state/invoices/actions';
import { MEMBER_EMAIL } from 'state/memberEmails/actions';
import { MEMBER } from 'state/members/actions';
import { TEAM_NAME } from 'state/teamNames/actions';
import { TEAM } from 'state/teams/actions';

export default combineReducers({
  app: appReducer,
  batchInvoiceAggregates: createReducer(BATCH_INVOICES_AGGREGATE),
  batchInvoiceLineItems: createReducer(BATCH_INVOICE_LINE_ITEM),
  batchInvoicePaymentScheduleDetails: createReducer(BATCH_INVOICE_PAYMENT_SCHEDULE_DETAIL),
  batchInvoicePaymentSchedules: createReducer(BATCH_INVOICE_PAYMENT_SCHEDULE),
  batchInvoices: createReducer(BATCH_INVOICE),
  divisions: createReducer(DIVISION),
  divisionAggregates: createReducer(DIVISION_AGGREGATE),
  feedback: feedbackReducer,
  form: formReducer,
  group: groupReducer,
  invoiceLineItems: createReducer(INVOICE_LINE_ITEM),
  invoiceMessageRecipients: invoiceMessageRecipientsReducer,
  invoicePayments: createReducer(INVOICE_PAYMENT),
  invoicePaymentTransactions: createReducer(INVOICE_PAYMENT_TRANSACTION),
  invoiceRecipients: createReducer(INVOICE_RECIPIENT),
  invoices: createReducer(INVOICE),
  memberEmails: createReducer(MEMBER_EMAIL),
  members: createReducer(MEMBER),
  modal: modalReducer,
  payInvoice: payInvoiceReducer,
  paymentAccount: paymentAccountReducer,
  depositAccount: depositAccountReducer,
  depositAccountOnboarding: depositAccountOnboardingReducer,
  routerLocation: routerLocationReducer,
  teamNames: createReducer(TEAM_NAME),
  team: createReducer(TEAM),
  canUpdate: canUpdateReducer,
  canAcceptPayment: canAcceptPaymentReducer,
});
