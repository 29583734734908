import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormSection } from 'redux-form';
import InputField from 'components/baseComponents/Fields/InputField';
import TextAreaField from 'components/baseComponents/Fields/TextAreaField';
import DatePickerField from 'components/baseComponents/Fields/DatePickerField';
import { trimInput } from 'utils/utils';
import { Grid, Cell } from '@teamsnap/teamsnap-ui';
import { isMaxLength, isValidDate } from 'utils/validator';
import styles from './InvoicingFormInfo.module.scss';
import { batchInvoicePropShape } from 'interfaces/batchInvoice';

class InfoSection extends PureComponent {
  renderStaticContent = (label, value) => (
    <div>
      <h4 className={ styles.OverviewHeader }>{ label }</h4>
      <p className={ styles.OverviewBody }>{ value }</p>
    </div>
  );

  renderTitle = () => {
    const label = 'Title';

    return (
      <InputField
        name="title"
        label={ label }
        validate={ [isMaxLength(100)] }
        normalize={ (value) => trimInput(value) }
        placeholder="Invoice Title"
      />
    );
  };

  renderDescription = () => {
    const label = 'Description';

    return (
      <TextAreaField
        name="description"
        label={ label }
        validate={ [isMaxLength(250)] }
        placeholder="Add a description here"
      />
    );
  };

  renderDueAt = (dateFormat) => {
    const label = 'Due Date';

    return (
      <DatePickerField
        name="dueAt"
        placeholder={ dateFormat }
        label={ label }
        note="* Invoice must be paid in full, or an installment plan must be set up."
        dateFormat={ dateFormat }
        validate={ [isValidDate(dateFormat)] }
      />
    );
  };

  render() {
    const {
      sectionName,
      dateFormat,
    } = this.props;

    return (
      <FormSection name={ sectionName }>
        <Grid isWithGutter isFit mods="u-padNone">
          <Cell mods="u-size1of2">
            { this.renderTitle() }
            { this.renderDueAt(dateFormat) }
          </Cell>
          <Cell mods="u-size1of2">{ this.renderDescription() }</Cell>
        </Grid>
      </FormSection>
    );
  }
}

InfoSection.propTypes = {
  invoicingId: PropTypes.string,
  sectionName: PropTypes.string,
  batchInvoice: PropTypes.shape(batchInvoicePropShape),
  dateFormat: PropTypes.string.isRequired,
};

InfoSection.defaultProps = {
  invoicingId: null,
  sectionName: null,
  batchInvoice: {},
};

export default InfoSection;
