import { teamSnap } from 'utils/window';

// Actions
export const SET_WEPAY_STATUS = 'app.SET_WEPAY_STATUS';
export const SAVE_APP_SETTINGS = 'app.SAVE_APP_SETTINGS';

// Action Creators
export const setWePayStatus = (status) => ({
  type: SET_WEPAY_STATUS,
  payload: status,
});

export const saveAppSettings = () => ({
  type: SAVE_APP_SETTINGS,
  payload: teamSnap,
});
