/**
 * Team Actions
 *
 * Various actions using redux-thunk and axios for managing communication with API and redux store
 *
 */

import { getCollection } from 'state/baseActions';

export const TEAM_NAME = 'TEAM_NAME';

export const loadTeamNames = (params) => (dispatch) => dispatch(getCollection(TEAM_NAME, 'team_names/search', params));
