import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'components/teamsnapUIExtensions';
import { TextLink } from '@teamsnap/teamsnap-ui';
import RecipientItem from './RecipientItem';
import styles from './InvoicingRecipients.module.scss';

class InvoicingRecipients extends Component {
  removeMember = (id) => {
    const { selectedMembers, fields } = this.props;
    const memberIndex = selectedMembers.findIndex((selected) => selected.id === id);
    fields.remove(memberIndex);
  };

  renderRemoveAll = () => (
    <TextLink
      style={ { display: 'block', textDecoration: 'underline', textAlign: 'right' } }
      location={ null }
      mods="u-colorNegative u-fontSizeSm"
      onClick={ () => this.props.reduxFieldChange('members', []) }
    >
      Remove All
    </TextLink>
  );

  renderSelectedMembers = (members) => (
    <ul className={ styles.MemberList }>
      { members.map((member) => (
        <RecipientItem key={ member.id } member={ member } handleRemove={ this.removeMember } />
      )) }
    </ul>
  );

  render() {
    const {
      selectedMembers,
      meta: { submitFailed, error },
    } = this.props;
    const selectedTitle = selectedMembers.length > 0 ? `(${selectedMembers.length}) ` : '';

    return (
      <FormGroup
        size="fill"
        title={ `${selectedTitle}Members Selected` }
        cta={ selectedMembers.length > 0 && this.renderRemoveAll() }
        componentStyles={ styles }
        className="FormGroupRight"
      >
        <div className={ styles.SelectedMembers }>
          { submitFailed && error && <span className={ styles.FieldError }>{ error }</span> }
          { this.renderSelectedMembers(selectedMembers) }
        </div>
      </FormGroup>
    );
  }
}

InvoicingRecipients.propTypes = {
  reduxFieldChange: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    push: PropTypes.func,
    remove: PropTypes.func,
  }).isRequired,
  meta: PropTypes.object.isRequired,
  selectedMembers: PropTypes.arrayOf(PropTypes.shape({})),
};

InvoicingRecipients.defaultProps = {
  selectedMembers: [],
};

export default InvoicingRecipients;
