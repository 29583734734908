import _isArray from 'lodash/isArray';
import _flatten from 'lodash/flatten';
import _snakeCase from 'lodash/snakeCase';
import _camelCase from 'lodash/camelCase';
import _reduce from 'lodash/reduce';

// Convert snake_case <=> camelCase with 'Snake' Plissken
export const plissken = (string, snake = false) => (snake ? _snakeCase(string) : _camelCase(string));

export const stringifyArray = (array, joinBy = ', ') => array.filter(Boolean).join(joinBy);

export const calculatePercent = (total, current) => (current / total || 0) * 100;

// Find all descendants in array by matching child/parent fields
export const findDescendants = (searchValue, array, childField = 'parentId', parentField = 'id') => {
  const descendants = [];
  const children = array.filter((arr) => arr[childField] === searchValue);

  if (children.length) {
    descendants.push(...children);

    children.forEach((child) => {
      const grandChildren = findDescendants(child[parentField], array, childField, parentField);

      if (grandChildren) {
        descendants.push(...grandChildren);
      }
    });
  }

  return descendants;
};

export function camelize(str) {
  return str.replace(/[-_]+(\w)/g, (_, char) => char.toUpperCase());
}

export function capitalize(string) {
  return string.replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
}

export function serializeItem(item) {
  const newItem = {};
  const ref1 = item.data;
  for (let i = 0, len = ref1.length; i < len; i += 1) {
    const prop = ref1[i];
    let value = prop.value;
    if (prop.type === 'DateTime' && value) {
      value = new Date(value);
    }
    if (prop.name === 'type') {
      value = camelize(value);
    }
    newItem[camelize(prop.name)] = value;
  }
  newItem.links = item.links || [];

  return newItem;
}

export function templateToObject(template) {
  const newObject = {};
  template.forEach((key) => {
    newObject[camelize(key.name)] = null;
  });
  return newObject;
}

export function objectToArray(object) {
  return Object.keys(object).map((key) => object[key]);
}

export function getClassName(className, classList = []) {
  if (_isArray(className)) {
    classList.push(className);
    return _flatten(classList).join(' ');
  }
  if (className) {
    classList.push(className);
  }
  return classList.join(' ');
}

// Merge base global class with new class definition and add css-module style override if exists
export const getClassNameStyles = (key, baseClassNames, componentClassNames, componentStyles) => {
  const classNames = { ...baseClassNames, ...componentClassNames };
  const styles = key in componentStyles ? componentStyles[key] : '';

  return `${classNames[key]} ${styles}`;
};

export const trimInput = (text) => {
  if (!/[^\s]/.test(text)) {
    return text.trim();
  }
  return text;
};

export const isArray = (value) => Array.isArray(value);
export const isObject = (value) => Object.prototype.toString.call(value) === '[object Object]';
export const reduce = (object, callback, initial = {}) => _reduce(object, callback, initial);

export const objectKeysToCase = (value, isSnakeCase = false) => {
  if (isArray(value)) {
    return value.map((v) => objectKeysToCase(v, isSnakeCase));
  } else if (!isObject(value)) {
    return value;
  }

  return reduce(
    value,
    (r, v, k) => ({
      ...r,
      [plissken(k, isSnakeCase)]: objectKeysToCase(v, isSnakeCase),
    }),
    {},
  );
};
