import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import ErrorBoundary from 'components/baseComponents/ErrorBoundary';

class AppRoute extends Component {
  render() {
    const { path, exact, strict, component } = this.props;
    const RouteComponent = component;

    return (
      <Route
        path={ path }
        exact={ exact }
        strict={ strict }
        render={ (params) => (
          <ErrorBoundary>
            <RouteComponent { ...params } />
          </ErrorBoundary>
        ) }
      />
    );
  }
}

AppRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  exact: PropTypes.bool,
  strict: PropTypes.bool,
};

AppRoute.defaultProps = {
  exact: false,
  strict: false,
};

export default AppRoute;
