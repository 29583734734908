/**
 * Base Reducer
 *  - This is a common/shared reducer for updating common redux stores across resources
 *
 * Example:
 *  import { createReducer } from 'state/reducers'
 *  createReducer('SOME_ACTION_TYPE')
 *
 */

import _omit from 'lodash/omit';

// TODO: Adjust how loading states are handled.  I don't think this needs to be anything crazy, at the least we
// should not default isFetching to true, but we could add a little more flexibility by including some kind of status
// to represent, null, loading, success or error?
// Temporarily there are 2 different loading patterns set (isFetching & status), member loading needed the default
// state set 'false', we could create a unique reducer for members, but I have wanted this on other actions as well.
const initialState = {
  isFetching: true,
  isError: false,
  items: null,
  status: null,
};

// creatReducer is a 'reducerFactory', it's a simple and easy to understand reducer that will eliminate
// most of the current boilerplate.  We can also easily "extend" it when necessary.
export const createReducer = (actionName = '') => (state = initialState, action) => {
  switch (action.type) {
    case `FETCH_${actionName}`:
      return {
        ...state,
        isFetching: true,
        isError: false,
        status: 'loading',
      };

    case `SAVE_${actionName}`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        status: 'success',
        items: { ...state.items, ...action.payload },
      };

    case `UPDATE_${actionName}`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        status: 'success',
        items: action.payload,
      };

    case `UPDATE_ITEM_${actionName}`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        status: 'success',
        items: { ...state.items, [action.payload.id]: action.payload },
      };

    case `REMOVE_${actionName}`:
      return {
        ...state,
        isFetching: false,
        isError: false,
        status: 'success',
        items: _omit(state.items, action.payload.id),
      };

    case `RESET_${actionName}`:
      return {
        ...initialState,
      };

    case `ERROR_${actionName}`:
      return {
        ...state,
        isFetching: false,
        isError: true,
        status: 'error',
      };

    default:
      return state;
  }
};
