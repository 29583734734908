// How do we feel about this pattern for when propTypes are shared across multiple
// components.  Inputs with reduxForm for example.  Below is only 2 shared props
// but you could add all the fields that the components share?  Does this "hide"
// the propTypes too much and defeat the purpose or is something still to be said
// for "DRY"?

import PropTypes from 'prop-types';

export const formFieldProps = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any,
  }).isRequired,
  meta: PropTypes.shape({
    active: PropTypes.bool,
    asyncValidating: PropTypes.bool,
    autofilled: PropTypes.bool,
    dirty: PropTypes.bool,
    dispatch: PropTypes.func,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitFailed: PropTypes.bool,
    submitting: PropTypes.bool,
    touched: PropTypes.bool,
    valid: PropTypes.bool,
    error: PropTypes.string,
    form: PropTypes.string,
    initial: PropTypes.string,
    warning: PropTypes.string,
  }),
};

export const formFieldDefaults = {
  meta: {},
};
