export const SHOW_FEEDBACK = 'SHOW_FEEDBACK';
export const HIDE_FEEDBACK = 'HIDE_FEEDBACK';

const showFeedback = (feedbackProps) => ({
  type: SHOW_FEEDBACK,
  payload: {
    feedbackProps,
  },
});

const hideFeedback = () => ({
  type: HIDE_FEEDBACK,
});

export { showFeedback, hideFeedback };
