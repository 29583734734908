import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { SummaryList, TextLink, Icon } from '@teamsnap/teamsnap-ui';
import styles from '../PayInvoice.module.scss';
import { invoicePropShape } from 'interfaces/invoice';

class PaymentSummary extends PureComponent {
  showPaymentLogModal = () => {
    this.props.handleClick();
  };

  renderLink = () => (
    <TextLink location={ null } onClick={ this.showPaymentLogModal }>
      <p>
        Payments <Icon name="info" />
      </p>
    </TextLink>
  );

  renderItems = (invoice) => {
    const items = [];

    if (invoice.amountPaid > 0) {
      items.push({
        description: this.renderLink(),
        value: `${invoice.amountPaidWithCurrency}`,
      });
    }

    if (invoice.amountRefunded > 0) {
      items.push({
        description: 'Refunds',
        value: `${invoice.amountRefundedWithCurrency}`,
      });
    }

    if (invoice.processingFeesPaid > 0) {
      items.push({
        description: 'Processing Fees',
        value: `${invoice.processingFeesPaidWithCurrency}`,
        mods: 'u-colorGrey',
      });
    }

    return items;
  };

  render() {
    const { invoice } = this.props;

    return (
      <section className={ styles.Component }>
        <SummaryList
          items={ this.renderItems(invoice) }
          footer={ { description: 'Balance:', value: invoice.balanceWithCurrency } }
        />
      </section>
    );
  }
}

PaymentSummary.propTypes = {
  invoice: PropTypes.shape(invoicePropShape).isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default PaymentSummary;
