const stateSelector = (state) => state.paymentAccount;
const itemSelector = (state) => stateSelector(state).items;

export const selectPaymentAccountIsFetching = (state) => stateSelector(state).isFetching;
export const selectPaymentAccountIsError = (state) => stateSelector(state).isError;
export const selectPaymentAccountIsLoaded = (state) => stateSelector(state).isLoaded;

export const selectPaymentAccount = (state) => itemSelector(state) && itemSelector(state).account;
export const selectPaymentAccountSetupComplete = (state) => itemSelector(state) && itemSelector(state).isSetupComplete;
export const selectPaymentAccountPayoutsEnabled = (state) => (
  itemSelector(state) && itemSelector(state).account.payoutsEnabled
);
export const selectPaymentAccountChargesEnabled = (state) => (
  itemSelector(state) && itemSelector(state).account.chargesEnabled
);
export const selectPaymentAccountCurrentlyDueRequirements = (state) => itemSelector(state) &&
  itemSelector(state).account.requirements.currentlyDue;

export const selectPaymentAccountEventuallyDueRequirements = (state) => itemSelector(state) &&
  itemSelector(state).account.requirements.eventuallyDue;

export const selectPaymentAccountCurrentDeadline= (state) => itemSelector(state) &&
  itemSelector(state).account.requirements.currentDeadline;

export const selectPaymentAccountDisabledReason = (state) => itemSelector(state) &&
  itemSelector(state).account.requirements.disabledReason;
