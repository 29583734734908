import PropTypes from 'prop-types';

export const groupPropShape = {
  activePaymentProvider: PropTypes.string,
  defaultPaymentProvider: PropTypes.string,
  billingAddress: PropTypes.string,
  id: PropTypes.string,
  country: PropTypes.string,
  businessType: PropTypes.string,
  type: PropTypes.string,
};
