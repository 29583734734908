import { getEnvironmentFromWindow } from './window';

export const FeatureFlags = {
  DISABLE_PAYMENT: 'DISABLE_PAYMENT', // Dont allow invoice to be paid if deposit account is not accepted
};

export const FeatureFlagConfig = {
  [FeatureFlags.DISABLE_PAYMENT]: {
    id: FeatureFlags.DISABLE_PAYMENT,
    enabled: true,
    allowedEnvironments: ['production', 'staging', 'local'], // all or nothing by env, this route has no org/team/user context
  },
};

export const isAllowedInCurrentEnvironment = (featureFlag) => {
  const env = getEnvironmentFromWindow();
  return !!FeatureFlagConfig[featureFlag]?.allowedEnvironments?.includes(env);
};
