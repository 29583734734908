/**
 * Invoice Messages
 *
 * Various actions using redux-thunk and axios for managing communication with API and redux store
 *
 */

import api from 'services/api';

export const INVOICE_MESSAGE = 'INVOICE_MESSAGE';

/**
 * createInvoiceMessage
 *
 * Sends an email with the details of the invoice to a specific set of users by either
 * specifying member_ids or status.
 *
 * @param  {String} batch_invoice_id
 * @param  {Array}  member_ids Array of specific member_ids to deliver to
 * @param  {String} status Invoice status to deliver message to ['open', 'paid', 'canceled', 'all']
 * @param  {String} subject The email subject line
 * @param  {String} body The body of the email
 */

export const createInvoiceMessage = (params) => () =>
  api
    .cmd('/invoice_messages/payment_request', params)
    .then((resp) => Promise.resolve(resp))
    .catch((error) => Promise.reject(error));
