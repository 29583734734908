import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import Form, { FormGroup } from 'components/teamsnapUIExtensions/Form';
import { Button } from '@teamsnap/teamsnap-ui';
import RadioButtonField from 'components/baseComponents/Fields/RadioButtonField';
import TextAreaField from 'components/baseComponents/Fields/TextAreaField';
import InputField from 'components/baseComponents/Fields/InputField';
import styles from './ApplyPaymentForm.module.scss';

import { validate } from './validate';

const paymentOptions = [
  { title: 'Cash', value: 'cash' },
  { title: 'Check', value: 'check' },
];

const ApplyPaymentForm = ({ handleSubmit, handleClose }) => (
  <Form handleSubmit={ handleSubmit }>
    <div className={ styles.ModalBody }>
      <FormGroup>
        <RadioButtonField
          label="How did they pay?"
          name="kind"
          size="fit"
          options={ paymentOptions }
          className="InputControlInline"
        />
      </FormGroup>

      <FormGroup>
        <InputField name="amount" type="text" label="Payment Amount" placeholder="0.00" icon="$" />
      </FormGroup>

      <FormGroup>
        <TextAreaField name="detail" label="Payment Details (e.g. Check #)?" placeholder="Add a note here" />
      </FormGroup>
    </div>
    <div className={ styles.ModalFooter }>
      <Button type="button" onClick={ handleClose } icon="dismiss" color="negative" style={ { marginRight: '5px' } }>
        Cancel
      </Button>

      <Button color="primary" type="submit" icon="plus">
        Apply Payment
      </Button>
    </div>
  </Form>
);

ApplyPaymentForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'applyInvoicePayment',
  validate,
})(ApplyPaymentForm);
