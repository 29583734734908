export const teamSnapWindow = (value) => {
  // we can pass a json string in the env variable REACT_APP_TEAMSNAP_CONFIG when not using classic shell
  if (process.env.REACT_APP_TEAMSNAP_CONFIG) { 
    const configJson = JSON.parse(process.env.REACT_APP_TEAMSNAP_CONFIG) || {};
    return configJson[value] || window.TeamSnap[value];
  }
  if (!window.TeamSnap) return null; // to avoid failure in test environment
  return window.TeamSnap[value];
};

export const wepayClientId = teamSnapWindow('wepayClientId') || null;

// api base urls
export const apiv3Url = teamSnapWindow('apiv3Url') || null;
export const paymentsApiUrl = teamSnapWindow('paymentsApiUrl') || null;

// same token for  SNAPI and payments-api
export const authenticationToken = teamSnapWindow('authenticationToken') || null;

export const authenticationAppId = teamSnapWindow('authenticationAppId') || null;

export const userId = teamSnapWindow('userId') || null;

export const isAdmin = teamSnapWindow('isAdmin') || null;

export const userIsTeamOwner = teamSnapWindow('userIsTeamOwner') || false;
export const isTeam = !!teamSnapWindow('teamId');
export const isStandaloneTeam = teamSnapWindow('isStandaloneTeam') || false;

export const memberId = teamSnapWindow('memberId') || null;

export const rollouts = teamSnapWindow('featureRollouts') || {};

// B2B values
export const organizationJsUrl = teamSnapWindow('organizationJsUrl') || null;
export const organizationId = teamSnapWindow('organizationId') || null;
export const divisionId = teamSnapWindow('divisionId') || null;
export const programId = teamSnapWindow('programId') || null;
export const seasonId = teamSnapWindow('seasonId') || null;

export const getEnvironmentFromWindow = () => {
  if (apiv3Url?.includes('staging')) {
    return 'staging';
  }
  if (apiv3Url?.includes('local')) {
    return 'local';
  }
  return 'production';
};

export const teamSnap = {
  apiv3Url,
  authToken: authenticationToken, // alias to avoid breaking changes
  userId,
  isAdmin,
  memberId,
  rollouts,
};

export const featureRollout = (rollout) => rollouts[rollout] === true;
