/* eslint-disable no-console */

/**
 * Request
 *
 * A wrapper around axios to standardize all request calls with the same headers and handlers
 *
 * TODO:
 *  - It might be nice to make this a little more flexible, so we aren't tied to the
 *    apiv3 url and collection JSON only.  Maybe pass config params to override?
 *  - It would be good if we setup a better ENV config instead of relying on configuration
 *    from classic.
 */

import AXIOS from 'axios';
// import { notify } from 'utils/notifier'
import { convertFromCollection, objectKeysToCamel } from './collectionJson';
import { apiv3Url, authenticationToken } from 'utils/window';

// Polyfill for .finally support
// https://github.com/axios/axios/blob/master/COOKBOOK.md
require('promise.prototype.finally').shim();

// Setup local instance of axios with default url and headers
const axios = AXIOS.create({
  baseURL: `${apiv3Url}`,
  headers: {
    accept: 'application/vnd.collection+json',
    authorization: `Bearer ${authenticationToken}`,
  },
});

// Add start and end times to axios request and response objects
axios.interceptors.request.use((config) => ({
  ...config,
  startTime: new Date().getTime(),
}));

axios.interceptors.response.use((response) => ({
  ...response,
  config: {
    ...response.config,
    endTime: new Date().getTime(),
  },
}));

// OnSuccess fired automatically after a successful v3 response
const onSuccess = ({ config: { url, params, method, headers, startTime, endTime }, data, status, statusText }) => {
  const collection = convertFromCollection(data);

  const collectionRequest = collection.rel === 'bulk_load' ? `bulk_load [${params.types}]` : collection.rel || '';

  console.log(`Success - ${collectionRequest}`, {
    request: { time: `${endTime - startTime} ms`, url, params, method, headers, status, statusText },
    response: data,
    data: collection.items,
  });

  return collection;
};

// OnError fired automatically after a failed v3 response
// const onError = ({ response: { config: { url, params, method, headers }, status, statusText, data }, message }) => {
export const onError = (response = {}, message = 'Server Error') => {
  const {
    config: { url, params, method, headers },
    status,
    statusText,
    data,
  } = response;

  const errorObject = {
    status: status || 500,
    statusText: statusText || 'Something went wrong',
    message: (data && data.collection && data.collection.error.message) || message,
  };

  console.error(`Failed - ${url || 'Request'}`, {
    request: { url, params, method, headers, status, statusText },
    response: errorObject,
  });

  // notify(`APIv3 - ${errorObject.statusText}`, {
  //   request: { url, params, method, headers, status, statusText },
  //   response: errorObject
  // })

  return Promise.reject(errorObject);
};

// Main request wrapper for axios
const request = (options) =>
  axios(options)
    .then((data) => onSuccess(data))
    .catch(onError);

// Axios 'all' request wrapper
const requestAll = (items) =>
  AXIOS.all(items.map((item) => request(item))).then(
    AXIOS.spread((...args) =>
      Object.assign(...args.map((arg) => objectKeysToCamel(arg.data.collection.rel, convertFromCollection(arg.data)))),
    ),
  );

export { request, requestAll };
