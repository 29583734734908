/**
 * Filter Component
 *
 * Filter component is used to 'Filter' or 'Search' data.  This can be applied to a
 * table as a quick search or added to a more advanced filter.
 *
 * TODO:
 *  -> Need to load styles from TeamSnap UI (once created)
 *  -> Add a 'criteria' field.  This would be something like field 'contains, start with, ect'?
 *  -> This is an initial filter component, once we get to building advanced filters this
 *     will likely need to be updated.
 *
 */

import React, { PureComponent } from 'react';
import { Input, Select } from 'components/teamsnapUIExtensions';
import { Grid, Cell } from '@teamsnap/teamsnap-ui';
import PropTypes from 'prop-types';
import defaultStyles from './Filter.module.scss';

class Filter extends PureComponent {
  state = { inputValue: '' };

  handleChange = (e) => this.setState({ inputValue: e.target.value });

  setPlaceHolderText = () => {
    const { selectValue } = this.props;
    switch (selectValue) {
      case 'status':
        return 'Search by open, paid, submitted, or canceled';
      case 'recipient':
        return 'Search by recipient name';
      case 'batch':
        return 'Search by batch title';
      case 'titleLink':
        return 'Search by batch title';
      case 'batchStatus':
        return 'Search by open, paid, or canceled';
      default:
        return 'Search Filter';
    }
  };

  render() {
    const { inputValue } = this.state;
    const { name, onSelectChange, onKeyUp, selectValue, categories, size, componentStyles } = this.props;
    const styles = { ...defaultStyles, ...componentStyles };

    const selectInput = {
      name: 'filterCategories',
      onChange: onSelectChange,
      value: selectValue,
    };

    const input = {
      name,
      onKeyUp,
      value: inputValue,
      onChange: this.handleChange,
    };

    return (
      <Cell className="CellMiddle" mods={ `u-size${size}` } componentStyles={ styles }>
        <Grid isWithGutter>
          <Select input={ selectInput } options={ categories } size="1of2" label={ null } componentStyles={ styles } />
          <Input input={ input } size="1of2" placeholder={ this.setPlaceHolderText() } label={ null } componentStyles={ styles } />
        </Grid>
      </Cell>
    );
  }
}

Filter.propTypes = {
  name: PropTypes.string.isRequired,
  selectValue: PropTypes.string.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  onKeyUp: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.any.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ).isRequired,
  size: PropTypes.string,
  componentStyles: PropTypes.shape({}),
};

Filter.defaultProps = {
  size: 'fill',
  componentStyles: {},
};

export default Filter;
