import { connect } from 'react-redux';
import { saveAppSettings } from 'state/app/actions';
import { saveRouteLocation } from 'state/routerLocation/actions';
import { loadGroupPreferences } from 'state/group/actions';
import TeamPayments from './TeamPayments';
import { loadMembers } from 'state/members/actions';
import { selectActivePaymentProvider, selectDefaultPaymentProvider } from 'state/group/selectors';
import { getPaymentAccount } from 'state/paymentAccount/actions';
import { getDepositAccount } from 'state/depositAccount/actions';

const mapDispatchToProps = {
  saveAppSettings,
  saveRouteLocation,
  loadGroupPreferences,
  loadMembers,
  getPaymentAccount,
  getDepositAccount,
};

const mapStateToProps = (state) => ({
  members: state.members,
  activePaymentProvider: selectActivePaymentProvider(state),
  defaultPaymentProvider: selectDefaultPaymentProvider(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamPayments);
