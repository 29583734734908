/**
 * Member Actions
 *
 * Various actions using redux-thunk and axios for managing communication with API and redux store
 *
 */

import { getCollection, resetCollection } from 'state/baseActions';

export const MEMBER = 'MEMBER';

// Load Members
export const loadMembers = (params) => (dispatch) => dispatch(getCollection(MEMBER, 'members/search', params));

// The loadMembers method above doesn't return members in a sub-division
// Leaving for now until we determine if it needs to be removed.
export const loadAdvancedDivisionMembers = (params) => (dispatch) =>
  dispatch(getCollection(MEMBER, 'members/advanced_division_search', params, { replaceState: true }));

export const resetMemberCollection = () => (dispatch) => dispatch(resetCollection(MEMBER));
