import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Header } from 'components/teamsnapUIExtensions';
import { linkInvoicing, linkInvoices } from 'utils/links';
import { Button, Grid } from '@teamsnap/teamsnap-ui';
import { Form, FormGroup, Panel } from 'components/teamsnapUIExtensions';
import InvoicingFormNav from 'components/baseComponents/InvoicingFormNav';
import InfoSection from './InfoSection';
import LineItemsSection from './LineItemsSection';
import { validate } from './validate';
import styles from './InvoicingFormInfo.module.scss';
import { historyPropShape } from 'interfaces/navigation';

const FORM_SECTION = {
  batchInvoice: 'batchInvoice',
  batchInvoiceLineItems: 'batchInvoiceLineItems',
};

class InvoicingFormInfo extends Component {
  renderFooter = (groupId, submitting) => {
    const { invoicingId } = this.props;
    const cancelLink = invoicingId ? linkInvoices(groupId, invoicingId) : linkInvoicing(groupId);
    const saveButton = invoicingId ?
      <Button type="submit" color="primary" isDisabled={ submitting }>
        Save
      </Button>
      :
      <Button type="submit" color="primary" isDisabled={ submitting } icon="right" iconPosition="right">
        Save & Continue
      </Button>;

    return (
      <div>
        <Button
          mods="u-spaceRightMd"
          color="negative"
          icon="dismiss"
          onClick={ () => this.props.history.push(cancelLink) }
        >
          Cancel
        </Button>

        { saveButton }
      </div>
    );
  };

  renderLineItemSection = () => {
    const { invoicingId, initialValues, disableLineItems } = this.props;

    return (
      <LineItemsSection
        disableLineItems={ disableLineItems }
        invoicingId={ invoicingId }
        sectionName={ FORM_SECTION.batchInvoiceLineItems }
        batchInvoiceLineItems={ initialValues[FORM_SECTION.batchInvoiceLineItems] }
      />
    );
  };

  renderBatchInvoiceHeader = () => {
    const {
      groupId,
      invoicingId,
      groupType,

    } = this.props;

    if (invoicingId) {
      return (<Header size={ 1 } title="Edit Invoice Batch" componentStyles={ styles }/>);
    } else {
      return (
        <InvoicingFormNav
            title={ 'New Invoice' }
            currentStep={ 'new' }
            groupId={ groupId }
            invoicingId={ invoicingId }
            groupType={ groupType }
        />
      );
    }
  }

  render() {
    const {
      handleSubmit,
      groupId,
      invoicingId,
      initialValues,
      dateFormat,
      submitting,
      disableLineItems,
      hideLineItems,
    } = this.props;

    return (
      <div>
        { this.renderBatchInvoiceHeader() }

        <Form handleSubmit={ handleSubmit }>
          <Panel title="Invoice Info" footerComponent={ this.renderFooter(groupId, submitting) } componentStyles={ styles }>
            <Grid isWithGutter>
              <FormGroup title="Overview Info">
                <InfoSection
                  invoicingId={ invoicingId }
                  sectionName={ FORM_SECTION.batchInvoice }
                  batchInvoice={ initialValues[FORM_SECTION.batchInvoice] }
                  dateFormat={ dateFormat }
                />

                { !hideLineItems && disableLineItems && this.renderLineItemSection() }
              </FormGroup>
            </Grid>

            { !hideLineItems && !disableLineItems && (
              <FormGroup size="1of1" title="Line Items" className="FormGroupPadded">
                { this.renderLineItemSection() }
              </FormGroup>
            ) }
          </Panel>
        </Form>
      </div>
    );
  }
}

InvoicingFormInfo.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  disableLineItems: PropTypes.bool.isRequired,
  dateFormat: PropTypes.string.isRequired,
  invoicingId: PropTypes.string,
  submitting: PropTypes.bool,
  hideLineItems: PropTypes.bool,
  initialValues: PropTypes.shape({
    batchInvoice: PropTypes.shape({
      title: PropTypes.string,
    }),
  }),
  groupType: PropTypes.string.isRequired,
  history: PropTypes.shape(historyPropShape).isRequired,
};

InvoicingFormInfo.defaultProps = {
  invoicingId: null,
  submitting: false,
  hideLineItems: false,
  initialValues: { batchInvoice: {}, batchInvoiceLineItems: {} },
};

export default reduxForm({
  form: 'invoicingInfo',
  validate,
})(InvoicingFormInfo);
