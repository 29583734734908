import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from '@teamsnap/teamsnap-ui';
import _startCase from 'lodash/startCase';
import styles from './InvoiceStatus.module.scss';

const progressColor = (status) => {
  if (status === 'canceled') {
    return 'neutral';
  }

  return '';
};

const progressPercent = (options) => {
  const { status, currentValue, totalValue } = options;

  if (status === 'canceled') {
    return 0;
  }

  if (status !== 'paid' && currentValue === 0.0) {
    return 0;
  }

  return ((totalValue - currentValue) / totalValue) * 100;
};

const statusSubText = (options) => {
  const { status, hasPaymentSchedule, errorCount, includeCounts } = options;

  if (errorCount > 0) {
    const errorText = errorCount === 1 ? 'error' : 'errors';
    return includeCounts ? `(${errorCount} ${errorText})` : `(${errorText})`;
  }

  if (status === 'open' && hasPaymentSchedule === 'true') {
    return '(scheduled)';
  }

  // TODO: Follow up with changes in a future project
  // if (pastDueCount > 0) {
  //   return `(${pastDueCount} past due)`
  // }

  return null;
};

const InvoiceStatus = (props) => {
  const { status, hasLabel, errorCount } = props;

  return (
    <div>
      <h4 className={ styles.StatusText }>
        <span className={ styles.StatusLabel }>{ hasLabel && 'Status: ' }</span>
        <span style={ errorCount > 0 ? { color: '#e57372' } : {} }>{ _startCase(status) }</span>
        <small className={ styles.StatusSubText }>{ statusSubText(props) }</small>
      </h4>

      <ProgressBar size="xsmall" color={ progressColor(status) } progress={ progressPercent(props) } />
    </div>
  );
};

InvoiceStatus.propTypes = {
  status: PropTypes.string,
  hasPaymentSchedule: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  errorCount: PropTypes.number,
  // pastDueCount: PropTypes.number,
  currentValue: PropTypes.number, // eslint-disable-line react/no-unused-prop-types
  totalValue: PropTypes.number, // eslint-disable-line react/no-unused-prop-types
  hasLabel: PropTypes.bool,
  includeCounts: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
};

InvoiceStatus.defaultProps = {
  status: null,
  hasPaymentSchedule: 'false',
  errorCount: 0,
  // pastDueCount: 0,
  currentValue: 0,
  totalValue: 0,
  hasLabel: false,
  includeCounts: false,
};

export default InvoiceStatus;
