import { ANALYTIC_EVENT } from 'state/analyticEvent/actions';

const gtmMiddleware = () => (next) => (action) => {
  window.dataLayer = window.dataLayer || [];

  if (action.type === ANALYTIC_EVENT) {
    window.dataLayer.push({
      event: 'Invoicing App Event',
      ...action.payload,
    });
  }
  return next(action);
};

export default gtmMiddleware;
