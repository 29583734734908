import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { calculatePercent } from 'utils/utils';
import { Grid, Cell, RadialProgress } from '@teamsnap/teamsnap-ui';
import LabelIndicator from 'components/baseComponents/Widget/LabelIndicator';

class WidgetPaymentOverview extends PureComponent {
  render() {
    const {
      note,
      amountTotal,
      amountCollected,
      amountTotalWithCurrency,
      amountCollectedWithCurrency,
      amountDueWithCurrency,
      openInvoices,
      paidInvoices,
      totalInvoices,
    } = this.props;

    const chartProgress = calculatePercent(amountTotal, amountCollected);

    return (
      <div>
        <Grid>
          <Cell mods="u-size1of3">
            <RadialProgress progress={ chartProgress } size="large" />
          </Cell>
          <Cell mods="u-size2of3">
            <Grid>
              <Cell mods="u-size1of2">
                <LabelIndicator label="Collected" value={ amountCollectedWithCurrency } indicatorColor="primary" />
              </Cell>
              <Cell mods="u-size1of2">
                <LabelIndicator label="Due" value={ amountDueWithCurrency } indicatorColor="neutral" />
              </Cell>
              <Cell mods="u-size1of1 u-borderTop u-padTopMd u-spaceTopMd">
                <LabelIndicator label="Invoiced Total" value={ amountTotalWithCurrency } />
              </Cell>
            </Grid>
          </Cell>
        </Grid>

        <Grid mods="u-borderTop u-padTopMd u-spaceTopMd">
          <Cell mods="u-size1of3">
            <LabelIndicator label="Open Invoices" value={ openInvoices } mods="u-padBottomNone" />
          </Cell>
          <Cell mods="u-size1of3">
            <LabelIndicator label="Paid Invoices" value={ paidInvoices } mods="u-padBottomNone" />
          </Cell>
          <Cell mods="u-size1of3">
            <LabelIndicator label="Total Invoices" value={ totalInvoices } mods="u-padBottomNone" />
          </Cell>
        </Grid>

        { note && (
          <small className="u-padTopMd" style={ { marginBottom: '-16px', display: 'block' } }>
            { note }
          </small>
        ) }
      </div>
    );
  }
}

WidgetPaymentOverview.propTypes = {
  amountCollected: PropTypes.number.isRequired,
  amountTotal: PropTypes.number.isRequired,
  amountTotalWithCurrency: PropTypes.string.isRequired,
  amountCollectedWithCurrency: PropTypes.string.isRequired,
  amountDueWithCurrency: PropTypes.string.isRequired,
  openInvoices: PropTypes.number.isRequired,
  paidInvoices: PropTypes.number.isRequired,
  totalInvoices: PropTypes.number.isRequired,
  note: PropTypes.string,
};

WidgetPaymentOverview.defaultProps = {
  note: null,
};

export default WidgetPaymentOverview;
