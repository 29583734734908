/**
 * Batch Invoice Payment Schedule Details Selector
 *
 * Various selectors using reselect for selecting data from state
 *
 */

import { createSelector } from 'reselect';
import _values from 'lodash/values';
import { selectBatchInvoiceId } from 'state/routerLocation/selectors';

const stateSelector = (state) => state.batchInvoicePaymentScheduleDetails;
const itemSelector = (state) => stateSelector(state).items;

export const selectBatchInvoicePaymentScheduleDetails = createSelector(itemSelector, (items) => _values(items));

export const selectBatchInvoicePaymentScheduleDetailsByBatchRoute = createSelector(
  selectBatchInvoiceId,
  selectBatchInvoicePaymentScheduleDetails,
  (batchInvoiceId, items) => items && items.filter((item) => item.batchInvoiceId === batchInvoiceId),
);
