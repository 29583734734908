/**
 * InputField Component Wrapper
 *
 * A 'Field' is used by reduxForms to wrap our internal toolkit components,
 * in this case Input. see http://redux-form.com/6.8.0/docs/api/Field.md/
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Input from 'components/teamsnapUIExtensions/Input';

const InputField = (props) => <Field component={ Input } { ...props } />;

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  size: PropTypes.string,
  componentStyles: PropTypes.shape({}),
  className: PropTypes.string,
  pattern: PropTypes.string,
  autoComplete: PropTypes.string,
};

InputField.defaultProps = {
  type: 'text',
  label: undefined,
  placeholder: undefined,
  disabled: false,
  required: false,
  size: undefined,
  componentStyles: {},
  className: 'Input',
  pattern: null,
  autoComplete: null,
};

export default InputField;
