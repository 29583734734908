import { SAVE_INVOICE_MESSAGE_RECIPIENTS } from './actions';

const initialState = {
  recipientIds: [],
};

const invoiceMessageRecipientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_INVOICE_MESSAGE_RECIPIENTS:
      return {
        recipientIds: action.payload.recipientIds,
      };
    default:
      return state;
  }
};

export default invoiceMessageRecipientsReducer;
