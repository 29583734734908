import { GROUP_PREFERENCES } from './actions';

const initialState = {
  isFetching: true,
  groupType: null,
  groupId: null,
  groupParams: {},
  hasInternationalDate: null,
  hasInternationalTime: null,
  dateFormat: 'MM/DD/YYYY',
  activePaymentProvider: null,
  defaultPaymmentProvider: null,
  paymentProvider: null,
  isPaymentProviderStripe: false,
  isPaymentProviderWePay: false,
  hasActivePaymentProvider: false,
  hasActiveClassicPayments: false,
};

function groupReducer(state = initialState, action) {
  switch (action.type) {
    case GROUP_PREFERENCES:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
      };

    default:
      return state;
  }
}

export default groupReducer;
