/**
 * Input Component
 *
 * An input component that requires an input object and calls <Field />, this input
 * also accepts a "type", default to 'text'.
 *
 * TODO:
 *  -> The css needs to be updated in teamsnapUI for better use with our components
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'components/teamsnapUIExtensions';
import { Icon } from '@teamsnap/teamsnap-ui';
import defaultStyles from './Input.module.scss';

class Input extends PureComponent {
  render() {
    const {
      input,
      meta,
      type,
      label,
      placeholder,
      disabled,
      required,
      size,
      icon,
      note,
      componentStyles,
      className,
      pattern,
      autoComplete,
    } = this.props;

    const styles = { ...defaultStyles, ...componentStyles };
    const inputClass = meta.touched && meta.error ? `${className}Error` : className;

    return (
      <Field
        name={ input.name }
        label={ label }
        required={ required }
        meta={ meta }
        size={ size }
        className={ className === 'InputAligned' ? 'FieldAligned' : 'Field' }
        componentStyles={ styles }
      >
        { note && <small className={ styles.InputNote }>{ note }</small> }
        <div className={ icon ? styles.InputIconContainer : '' }>
          <input
            type={ type }
            id={ input.name }
            disabled={ disabled }
            required={ required }
            className={ styles[inputClass] }
            placeholder={ placeholder }
            pattern={ pattern }
            autoComplete={ autoComplete }
            { ...input }
          />
          { icon && (
            <span className={ styles.InputIcon }>
              { /* Temp check until a $ is added to TeamsnapUI */ }
              { icon === '$' ? '$' : <Icon name={ icon } style={ { fontSize: '15px', color: '#999' } } /> }
            </span>
          ) }
        </div>
      </Field>
    );
  }
}

Input.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onKeyUp: PropTypes.func,
    onChange: PropTypes.func,
  }).isRequired,
  note: PropTypes.string,
  meta: PropTypes.shape({}),
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  size: PropTypes.string,
  icon: PropTypes.string,
  componentStyles: PropTypes.shape({}),
  className: PropTypes.string,
  pattern: PropTypes.string,
  autoComplete: PropTypes.string,
};

Input.defaultProps = {
  meta: {},
  type: 'text',
  label: '\u00a0',
  note: null,
  placeholder: undefined,
  disabled: false,
  required: false,
  size: undefined,
  icon: null,
  componentStyles: {},
  className: 'Input',
  pattern: null,
  autoComplete: null,
};

export default Input;
