const getResourcePath = () => (window.location.href.includes('team_payments') ? 'team_payments' : 'league_payments');
const getFinancialsPath = () =>
  window.location.href.includes('team_payments') ? 'team_financials' : 'league_financials';

// ------------------------------------
// General Links
// ------------------------------------
const linkPaymentSettings = (resourceId) => `/${resourceId}/${getResourcePath()}/payment_provider_settings`;

const linkPaymentIndexList = (resourceId) => `/${resourceId}/payments/list`;

const linkFinancialAccounts = (resourceId) => `/${resourceId}/${getFinancialsPath()}`;

const linkFinancialReports = (resourceId) => `/${resourceId}/${getFinancialsPath()}/reports/payments`;

// -------------------------------------
// League Payments / Invoicing links
// -------------------------------------

const linkInvoicing = (resourceId) => `/${resourceId}/${getResourcePath()}/invoicing`;

const linkInvoicingAccount = (resourceId) => `/${resourceId}/${getResourcePath()}/invoicing/account`;

const linkInvoicingNew = (resourceId) => `/${resourceId}/${getResourcePath()}/invoicing/new`;

const linkInvoicingEdit = (resourceId, invoicingId) =>
  `/${resourceId}/${getResourcePath()}/invoicing/${invoicingId}/edit`;

const linkInvoicingPaymentInfo = (resourceId, invoicingId) =>
  `/${resourceId}/${getResourcePath()}/invoicing/${invoicingId}/payment-info`;

const linkInvoicingMembers = (resourceId, invoicingId) =>
  `/${resourceId}/${getResourcePath()}/invoicing/${invoicingId}/members`;

const linkInvoicingSend = (resourceId, invoicingId) =>
  `/${resourceId}/${getResourcePath()}/invoicing/${invoicingId}/send`;

const linkInvoices = (resourceId, invoicingId) =>
  `/${resourceId}/${getResourcePath()}/invoicing/${invoicingId}/invoices`;

const linkInvoice = (resourceId, invoicingId, invoiceId) =>
  `/${resourceId}/${getResourcePath()}/invoicing/${invoicingId}/invoices/${invoiceId}`;

// ------------------------------------
// Invoice Payment landing page links
// ------------------------------------

const linkInvoicePaymentDetails = (uuid) => `/pay_invoice/${uuid}`;

const linkInvoicePaymentSubmitted = (uuid) => `/pay_invoice/${uuid}?status=submitted`;

export {
  linkPaymentSettings,
  linkInvoicing,
  linkInvoicingAccount,
  linkInvoicingNew,
  linkInvoicingEdit,
  linkInvoicingPaymentInfo,
  linkInvoicingMembers,
  linkInvoicingSend,
  linkInvoices,
  linkInvoice,
  linkInvoicePaymentDetails,
  linkInvoicePaymentSubmitted,
  linkFinancialAccounts,
  linkFinancialReports,
  linkPaymentIndexList,
};
