/**
 * Popover Component
 *
 * Popover component can be used as a tooltip popover or a dropdown list of
 * menu action items.
 *
 * TODO:
 *  -> Should Popover * Tooltip components be split up
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import defaultStyles from './Popover.module.scss';

class Popover extends PureComponent {
  renderItem = (item, styles) => (
    <li key={ item.key } className={ styles.PopoverItem }>
      { item }
    </li>
  );

  renderPopoverItems = (items, styles) => (
    <ul className={ styles.PopoverList }>{ items.map((item) => this.renderItem(item, styles)) }</ul>
  );

  renderChildren = (styles) => <div className={ styles.PopoverBody }>{ this.props.children }</div>;

  render() {
    const { text, items, componentStyles, className } = this.props;
    const styles = { ...defaultStyles, ...componentStyles };

    return (
      <div className={ styles[className] }>
        <span className={ items.length ? styles.PopoverTrigger : styles.PopoverText }>{ text }</span>

        <div className={ styles.PopoverContainer }>
          <div className={ styles.PopoverContent }>
            { items.length ? this.renderPopoverItems(items, styles) : this.renderChildren(styles) }
          </div>
        </div>
      </div>
    );
  }
}

Popover.propTypes = {
  text: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.node),
  children: PropTypes.node,
  componentStyles: PropTypes.shape({}),
  className: PropTypes.string,
};

Popover.defaultProps = {
  text: '...',
  items: [],
  children: null,
  componentStyles: {},
  className: 'Popover',
};

export default Popover;
