import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { notify } from 'utils/notifier';

class ErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    // Update state so next render will show the fallback UI
    console.warn('ErrorBoundary', error);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Send error to reporting service.
    notify(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <p>Something went wrong.</p>;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ErrorBoundary;
