// Setup Invoice categories locally, until API create an endpoint
export default {
  1: 'Fees',
  2: 'Dues',
  3: 'Tournament',
  4: 'Uniform',
  5: 'Team Expense',
  6: 'Cancel',
  7: 'Adjustment',
};
