import React from 'react';
import PropTypes from 'prop-types';
const Tab = ({ activeTab, label, onClick, ...props }) => {
  let className = 'Tabs-headerItem';

  if (activeTab === label) {
    className += ' is-active';
  }

  return (
    <li
      { ...props }
      className={ className }
      onClick={ () => onClick(label) }
    >
      { label }
    </li>
  );
};

Tab.propTypes = {
  activeTab: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Tab;
