import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'components/teamsnapUIExtensions';
import { linkInvoices } from 'utils/links';
import InvoicingFormSend from 'components/baseComponents/InvoicingFormSend';
import { getGroupId } from 'utils/group';
import { matchPropShape, historyPropShape } from 'interfaces/navigation';
import { batchInvoicePropShape } from 'interfaces/batchInvoice';

class InvoicingSend extends Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { queryBatchInvoiceWithLineItems, batchInvoiceId } = this.props;
    queryBatchInvoiceWithLineItems(batchInvoiceId);
  }

  redirectToInvoices = (groupId, batchInvoiceId, needsRefresh) => {
    if (needsRefresh) {
      window.location.assign(linkInvoices(groupId, batchInvoiceId));
    } else {
      this.props.history.push(linkInvoices(groupId, batchInvoiceId));
    }
  };

  onSubmit = (props) => {
    const {
      batchInvoice: { id: batchId },
      createInvoiceMessage,
      invoiceRecipientIds,
      needsRefresh,
      match: { params },
    } = this.props;
    const groupId = getGroupId(params);

    const message = {
      member_ids: invoiceRecipientIds,
      batch_invoice_id: batchId,
      ...props,
    };

    return createInvoiceMessage(message).then(() => this.redirectToInvoices(groupId, batchId, needsRefresh));
  };

  render() {
    const {
      batchInvoice,
      batchInvoiceLineItems,
      isFetching,
      invoiceRecipientIds,
      dateFormat,
      needsRefresh,
      groupName,
      history,
      groupType,
      match: { params },
    } = this.props;

    const groupId = getGroupId(params);

    // Return Loader if still fetching batchInvoice
    if (isFetching) {
      return <Loader message="random" />;
    }

    return (
      <InvoicingFormSend
        onSubmit={ this.onSubmit }
        needsRefresh={ needsRefresh }
        history={ history }
        groupId={ groupId }
        dateFormat={ dateFormat }
        batchInvoice={ batchInvoice }
        batchInvoiceLineItems={ batchInvoiceLineItems }
        invoiceMessageRecipients={ invoiceRecipientIds }
        groupType={ groupType }
        groupName={ groupName }
      />
    );
  }
}

InvoicingSend.propTypes = {
  match: PropTypes.shape(matchPropShape).isRequired,
  history: PropTypes.shape(historyPropShape).isRequired,
  batchInvoice: PropTypes.shape(batchInvoicePropShape).isRequired,
  batchInvoiceLineItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  batchInvoiceId: PropTypes.string.isRequired,
  invoiceRecipientIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  queryBatchInvoiceWithLineItems: PropTypes.func.isRequired,
  createInvoiceMessage: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  dateFormat: PropTypes.string,
  groupName: PropTypes.string,
  needsRefresh: PropTypes.bool.isRequired,
  groupType: PropTypes.string.isRequired,
};

InvoicingSend.defaultProps = {
  dateFormat: 'MM/DD/YYYY',
  groupName: null,
};

export default InvoicingSend;
