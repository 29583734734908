import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@teamsnap/teamsnap-ui';
import { convertToCSV } from 'utils/exportFormatter';
class ExportButton extends Component {
  constructor (props) {
    super(props);
    this.state = {
      dataIsDownloading: false
    };
    this.csvTag = React.createRef();
  }

  handleCSVDownload = () => {
    this.csvTag.current.click();

    this.setState({
      dataIsDownloading: true
    });

    setTimeout(() => this.setState({ dataIsDownloading: false }), 2000);
  }

  render () {
    const { dataIsDownloading } = this.state;
    const { rows, columns } = this.props;

    const csv = '\ufeff' + convertToCSV(columns, rows);
    const blob = new Blob([csv], { encoding: 'UTF-8', type: 'text/csv;charset=UTF-8' });
    const csvData = window.URL.createObjectURL(blob);

    return (
      <>
        <Button
          className="Button u-spaceLeftSm"
          icon="export"
          isDisabled={ rows.length === 0 || dataIsDownloading }
          onClick={ this.handleCSVDownload }
        >
          { dataIsDownloading ? 'Exporting...' : 'Export Invoices' }
        </Button>
        <a
          style={ { display: 'none' } }
          href={ rows.length === 0 ? undefined : csvData }
          download={ rows.length === 0 ? false : `invoices_${Date.now()}.csv` }
          ref={ this.csvTag }
        />
      </>
    );
  }
}

ExportButton.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
};

export default ExportButton;
