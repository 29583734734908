import { requestParams } from 'services/api';
import { paymentsRequest } from 'services/paymentsApi';

export const FETCH_CAN_ACCEPT_PAYMENT = 'FETCH_CAN_ACCEPT_PAYMENT';
export const SAVE_CAN_ACCEPT_PAYMENT = 'SAVE_CAN_ACCEPT_PAYMENT';
export const ERROR_CAN_ACCEPT_PAYMENT = 'ERROR_CAN_ACCEPT_PAYMENT';

export const fetchCanAcceptPayments = (params) => async (dispatch) => {
  dispatch({ type: FETCH_CAN_ACCEPT_PAYMENT });
  try {
    const data = await paymentsRequest(requestParams(
      'get', '/v2/can_accept_payments', params
    ));
    const error = typeof data !== 'boolean' ? 'Error fetching can accept payments' : null;
    if (error) {
      dispatch({ type: ERROR_CAN_ACCEPT_PAYMENT });
      return error;
    } else {
      return dispatch({
        type: SAVE_CAN_ACCEPT_PAYMENT,
        payload: data,
      });
    }
  } catch (error) {
    dispatch({ type: ERROR_CAN_ACCEPT_PAYMENT });
    return error;
  }
};
