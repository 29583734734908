import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'utils/date-service';
import { linkInvoicingAccount, linkInvoicingPaymentInfo } from 'utils/links';
import InvoicingFormInfo from 'components/baseComponents/InvoicingFormInfo';
import { getGroupType, getGroupId, getGroupParams } from 'utils/group';
import { matchPropShape, historyPropShape, locationPropShape } from 'interfaces/navigation';
import { featureRollout, isTeam } from 'utils/window';
import { Loader } from 'components/teamsnapUIExtensions';

class InvoicingNew extends Component {
  componentDidMount() {
    const {
      analyticEvent,
      userId,
      match: { params },
      location: { pathname },
    } = this.props;
    const groupId = getGroupId(params);

    analyticEvent({
      url: pathname,
      eventCategory: groupId === 'divisionId' ? 'Club_Invoicing' : 'Team_Invoicing',
      eventAction: 'Create New Invoice',
      eventLabel: {
        userId,
        groupId,
      },
    });
  }

  handleSubmit = (props) => {
    const {
      createBatchInvoice,
      createBatchInvoiceLineItem,
      match: { params },
    } = this.props;
    const groupParams = getGroupParams(params);
    const groupId = getGroupId(params);

    const newInvoice = {
      title: props.batchInvoice.title,
      description: props.batchInvoice.description,
      dueAt: formatDate(props.batchInvoice.dueAt),
      batchInvoiceLineItems: props.batchInvoiceLineItems,
    };

    // Create Batch Invoice
    return createBatchInvoice({ ...newInvoice, ...groupParams })
      .then((batchInvoice) => {
        const batchInvoiceId = batchInvoice[0].id;

        // Create Batch Invoice Line Items by batchInvoice
        return Promise.all(
          props.batchInvoiceLineItems.items.map((batchInvoiceLineItem) =>
            createBatchInvoiceLineItem({ ...batchInvoiceLineItem, batchInvoiceId }),
          ),
        ).then((batchInvoiceLineItems) => ({ batchInvoiceId, batchInvoiceLineItems }));
      })
      .then((data) => this.redirectOnCreate(groupId, data.batchInvoiceId))
      .catch((error) => error);
  };

  redirectOnCreate = (groupId, invoicingId) => this.props.history.push(linkInvoicingPaymentInfo(groupId, invoicingId));

  isVisaMandateTeam = () => isTeam && featureRollout('invoicing_frontend_visa_mandate_standalone')

  render() {
    const {
      match: { params },
      dateFormat,
      history,
      paymentAccountIsLoaded,
      paymentAccount,
      depositAccountIsLoaded,
      depositAccount,
    } = this.props;
    const groupId = getGroupId(params);
    const groupType = getGroupType(params);

    if (this.isVisaMandateTeam() && (!paymentAccountIsLoaded || !depositAccountIsLoaded)) {
      return <Loader message="random" />;
    }
    // if no payment account, or deposit account is not in 'good' status, redirect to account page
    if(this.isVisaMandateTeam() && (!paymentAccount || (depositAccount && depositAccount.status !== 'approved' && depositAccount.status !== 'restricted_soon'))) {
      history.push(linkInvoicingAccount(groupId));
    }

    return (
      <InvoicingFormInfo
        history={ history }
        onSubmit={ this.handleSubmit }
        groupId={ groupId }
        disableLineItems={ groupType === 'teamId' }
        dateFormat={ dateFormat }
        groupType={ groupType }
      />
    );
  }
}

InvoicingNew.propTypes = {
  match: PropTypes.shape(matchPropShape).isRequired,
  history: PropTypes.shape(historyPropShape).isRequired,
  createBatchInvoice: PropTypes.func.isRequired,
  createBatchInvoiceLineItem: PropTypes.func.isRequired,
  dateFormat: PropTypes.string.isRequired,
  location: PropTypes.shape(locationPropShape).isRequired,
  userId: PropTypes.string,
  analyticEvent: PropTypes.func,
  paymentAccountIsLoaded: PropTypes.bool.isRequired,
  paymentAccount: PropTypes.object.isRequired,
  depositAccountIsLoaded: PropTypes.bool.isRequired,
  depositAccount: PropTypes.object.isRequired,
};

InvoicingNew.defaultProps = {
  analyticEvent: null,
  userId: null,
};

export default InvoicingNew;
