import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Cell, Button } from '@teamsnap/teamsnap-ui';
import { hideModal } from 'state/modal/actions';
import { notify } from 'utils/notifier';
import Modal from 'components/teamsnapUIExtensions/Modal';
import CheckoutForm from 'components/baseComponents/CheckoutForm';
import styles from './ModalCheckout.module.scss';
import { currencyPropShape } from 'interfaces/financials';

class ModalCheckout extends Component {
  state = {
    isSubmitting: false,
    submitError: null,
  };

  handleClose = () => {
    this.props.closeModal();
  };

  createCreditCardToken = (creditCard, cardTokenizer) => {
    this.setState({ isSubmitting: true, submitError: null }, () => cardTokenizer(creditCard));
  };

  updateCreditCardToken = (data, error = null) => {
    const { invoicePaymentScheduleId, activePaymentProvider } = this.props;

    const email = activePaymentProvider === 'stripe' ? data.email : null;

    if (error) {
      this.setState({
        isSubmitting: false,
        submitError: error,
      });

      notify(`CheckoutError Update - ${error}`, data.token);
    } else {
      return this.props
        .updateCreditCardToken(invoicePaymentScheduleId, data.token, email, activePaymentProvider)
        .then((_) => {
          this.setState({
            isSubmitting: false,
            submitError: null,
          });
        })
        .finally(() => this.handleClose());
    }
  };

  render() {
    const { invoiceId, currency, activePaymentProvider } = this.props;

    return (
      <Modal
        overlay
        title={ `Update Credit Card for Invoice #${invoiceId}` }
        handleClose={ this.handleClose }
        componentStyles={ styles }
        className="ModalMedium"
        { ...this.props }
      >
        <div className={ styles.ModalBody }>
          <CheckoutForm
            activeProvider={ activePaymentProvider }
            currency={ currency }
            saveCreditCardToken={ this.updateCreditCardToken }
            createCreditCardToken={ this.createCreditCardToken }
            submitError={ this.state.submitError }
          />
        </div>

        <div className={ styles.ModalFooter }>
          <Grid>
            <Cell mods="u-size1of1 u-sm-size1of3 u-textCenter u-sm-textLeft">
              <p className={ styles.CheckoutNote }>
                * Failed installments will be re-scheduled after credit card is updated
              </p>
            </Cell>
            <Cell mods="u-size1of1 u-spaceTopMd u-sm-size2of3 u-sm-spaceTopNone u-textCenter u-sm-textRight">
              <Button
                type="button"
                onClick={ this.handleClose }
                label="Cancel"
                icon="dismiss"
                size="medium"
                color="negative"
                style={ { marginRight: '10px' } }
              />
              <Button
                isDisabled={ this.state.isSubmitting }
                color="primary"
                size="medium"
                icon="check"
                type="submit"
                label="Update Credit Card"
                otherProps={ { form: 'checkoutForm' } }
              />
            </Cell>
          </Grid>
        </div>
      </Modal>
    );
  }
}

ModalCheckout.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  activePaymentProvider: PropTypes.string.isRequired,
  invoicePaymentScheduleId: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  currency: PropTypes.shape(currencyPropShape).isRequired,
  updateCreditCardToken: PropTypes.func.isRequired,
};

export default connect(null, {
  closeModal: hideModal,
})(ModalCheckout);
