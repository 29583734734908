/**
 * FormGroup Component
 *
 * A form fields wrapper/container for handling grid layouts with various fields.
 * Children is required with for options of column size (size) and nestedGrids (isGrid).
 *
 * FormGroup is essentially just a 'Cell' that we can style specifically for Form rows.
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { SectionHeading } from 'components/teamsnapUIExtensions';
import { Grid, Cell } from '@teamsnap/teamsnap-ui';
import defaultStyles from './FormGroup.module.scss';

class FormGroup extends PureComponent {
  renderHeader = (styles) => {
    const { title, cta } = this.props;

    if (!title && !cta) {
      return null;
    }

    return <SectionHeading title={ title } component={ cta } componentStyles={ styles } />;
  };

  render() {
    const { children, size, isGrid, cellModifiers, componentStyles, className } = this.props;
    const styles = { ...defaultStyles, ...componentStyles };

    return (
      <Cell mods={ `u-size${size} ${cellModifiers}` }>
        <div className={ styles[className] }>
          { this.renderHeader(styles) }
          { isGrid ? <Grid isWithGutter>{ children }</Grid> : children }
        </div>
      </Cell>
    );
  }
}

FormGroup.propTypes = {
  children: PropTypes.node,
  size: PropTypes.string,
  title: PropTypes.string,
  cta: PropTypes.node,
  isGrid: PropTypes.bool,
  cellModifiers: PropTypes.string,
  componentStyles: PropTypes.shape({}),
  className: PropTypes.string,
};

FormGroup.defaultProps = {
  children: null,
  size: '1of1',
  title: null,
  cta: null,
  isGrid: false,
  cellModifiers: null,
  componentStyles: {},
  className: 'FormGroup',
};

export default FormGroup;
