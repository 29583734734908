/**
 * Division Aggregate Selector
 *
 * Various selectors using reselect for selecting data from state
 *
 */

import _values from 'lodash/values';

const stateSelector = (state) => state.divisionAggregates;
const isFetchingSelector = (state) => stateSelector(state).isFetching;
const itemSelector = (state) => stateSelector(state).items;

export const selectDivisionAggregateIsFetching = (state) => isFetchingSelector(state);

export const selectDivisionAggregates = (state) => itemSelector(state) || {};
export const selectDivisionAggregateValues = (state) => _values(itemSelector(state));
