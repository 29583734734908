import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Cell } from '@teamsnap/teamsnap-ui';
import { Link } from 'react-router-dom';
import { formatDate } from 'utils/date-service';
import PaymentLog from 'components/baseComponents/PaymentLog';
import styles from '../PayInvoice.module.scss';
import { paymentPropShape } from 'interfaces/financials';

class ScheduledPayments extends Component {
  state = {
    showSchedule: false,
  };

  showSchedule = () => {
    this.setState({
      showSchedule: !this.state.showSchedule,
    });
  };

  render() {
    const { payments, dateFormat } = this.props;
    const nextPayment = payments[0] || {};

    return (
      <div className={ styles.ScheduledPayments }>
        <div className={ styles.ScheduledPaymentsInner }>
          <Grid>
            <Cell mods="u-size6of8" style={ { fontWeight: 700 } }>
              Next Payment Scheduled
            </Cell>
            <Cell mods="u-size2of8 u-textRight" style={ { fontWeight: 700 } }>
              { formatDate(nextPayment.scheduledAt, dateFormat) }
            </Cell>
          </Grid>

          <Grid mods="u-spaceTopSm">
            <Cell mods="u-size6of8">Payment Amount</Cell>
            <Cell mods="u-size2of8 u-textRight">{ nextPayment.amountWithCurrency }</Cell>
          </Grid>

          { /* TODO: Update to new field from apiv3 to remove additional logic */ }
          { nextPayment.processingFeePaidBy === 'payer' && (
            <Grid mods="u-spaceTopSm">
              <Cell mods="u-size6of8">Processing Fee</Cell>
              <Cell mods="u-size2of8 u-textRight">{ nextPayment.processingFeeWithCurrency }</Cell>
            </Grid>
          ) }
        </div>

        <div className={ styles.ScheduledPaymentsView }>
          <Link to="#" onClick={ this.showSchedule }>
            View all scheduled Installments ({ payments.length })
          </Link>
        </div>

        { this.state.showSchedule && (
          <div className={ styles.ScheduledPaymentsInner }>
            <PaymentLog hasBorder={ false } payments={ payments } dateFormat={ dateFormat } />
          </div>
        ) }
      </div>
    );
  }
}

ScheduledPayments.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.shape(paymentPropShape)),
  dateFormat: PropTypes.string,
};

ScheduledPayments.defaultProps = {
  payments: [],
  dateFormat: null,
};

export default ScheduledPayments;
