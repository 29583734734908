import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Status, LabelButton } from '@teamsnap/snap-ui';

import '@teamsnap/snap-ui/build/snap-ui.min.css';

const Container = styled.div`

  .account-details-left {
    float: left;
    max-width: 17rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .account-details-right {
    display: flex;
    float: right;
    align-items: center;
    height: inherit;
  }
`;

const AccountCard = ({ type, name, lastFour, status, isTeamOwner, onManageAccount, isLoading }) => {

  const [statusText, setStatusText] = React.useState('');
  const [statusState, setStatusState] = React.useState('negative');
  const [hideButton, setHideButton] = React.useState(false);

  React.useEffect(() => {
    switch (status) {
      case 'approved':
        setStatusText('Approved');
        setStatusState('success');
        return;
      case 'under_review':
        setStatusText('Under review');
        setHideButton(true);
        return;
      case 'rejected':
        setStatusText('Rejected');
        setHideButton(true);
        return;
      case 'restricted_soon':
      case 'information_required':
        setStatusText('Information required');
        return;
      default:
    }
  }, [status]);

  return (
    <Container>
      <div className="sui-p-4 sui-m-auto sui-border sui-border-solid sui-rounded sui-border-gray-80 sui-bg-neutral-background/100" 
        data-testid="account-card"
        style={ { width: '33.5rem' } }
      >
        <div className="sui-my-1">
          <h3 className='sui-my-1 sui-text-desktop-8' style={ { color: 'var(--Neutrals-Gray-40, #666)' } } >{ type }</h3>
        </div>

        <div className="sui-table sui-w-full sui-mt-2 sui-px-2 sui-py-1 sui-border sui-rounded sui-border-solid sui-border-gray-80"
          style={ { height: '3.5rem' } }
        >
          <div className='account-details-left'>
            <p data-testid='account-name' className='sui-text-desktop-5 sui-text-gray-10'>{ name }</p>

            <div className='sui-inline-flex sui-items-center sui-mt-1'>
              { lastFour && 
                <p className='sui-text-desktop-2 sui-mr-2 sui-text-gray-40' data-testid='account-last-four'>*{ lastFour }</p>
              }
              <Status
                data-testid="account-status-pill"
                state={ statusState }
                text={ statusText }
              />
            </div>
          </div>
          <div className='account-details-right'>
            { isTeamOwner && !hideButton && 
              <LabelButton
                disabled={ isLoading }
                data-testid="manage-account-button"
                labelText="Manage account"
                size="default"
                variantType="secondary"
                onClick={ onManageAccount }
              />
            }
          </div>
          
        </div>
      </div>
    </Container>
  );
};

AccountCard.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  lastFour: PropTypes.string,
  status: PropTypes.string,
  isTeamOwner: PropTypes.bool,
  onManageAccount: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

AccountCard.defaultProps = {
  type: 'Deposit Account',
  lastFour: '',
  status: 'success',
  isTeamOwner: false,
  isLoading: false,
};

export default AccountCard;
