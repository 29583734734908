import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Cell } from '@teamsnap/teamsnap-ui';
import defaultStyles from './SectionHeading.module.scss';

class SectionHeading extends PureComponent {
  render() {
    const { title, component, componentStyles } = this.props;
    const styles = { ...defaultStyles, ...componentStyles };

    return (
      <div className={ `Grid ${styles.SectionHeading}` }>
        { title && (
          <Cell mods="u-sizeFill">
            <h3 className={ styles.SectionHeadingTitle }>{ title }</h3>
          </Cell>
        ) }

        { component && <Cell mods="u-sizeFit">{ component }</Cell> }
      </div>
    );
  }
}

SectionHeading.propTypes = {
  title: PropTypes.string.isRequired,
  component: PropTypes.node,
  componentStyles: PropTypes.shape({}),
};

SectionHeading.defaultProps = {
  component: null,
  componentStyles: {},
};

export default SectionHeading;
