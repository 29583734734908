import {
  FETCH_INVOICE_PAYMENT_DETAILS,
  SAVE_INVOICE_PAYMENT_DETAILS,
  ERROR_INVOICE_PAYMENT_DETAILS,
  INVOICE_PAYMENT_SUBMITTED,
  INVOICE_PAYMENT_UPDATE_SUCCESS,
  INVOICE_PAYMENT_UPDATE_ERROR,
  INVOICE_PAYMENT_ERROR,
} from './actions';

export const initialState = {
  items: {
    division: {},
    divisionPreferences: {},
    team: {},
    teamPreferences: {},
    invoice: {},
    currency: {},
    invoiceLineItems: [],
    invoicePayment: [],
    invoicePaymentSchedule: {},
    invoicePaymentOptionAmounts: {},
    invoiceSchedulePaymentOptionAmounts: {},
    invoiceSchedulePaymentsPaymentOptionAmounts: [],
  },
  isFetching: true,
  isError: false,
  submitted: false,
  paymentUpdateStatus: null,
  payError: false,
  payErrorMessage: '',
};

const payInvoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INVOICE_PAYMENT_DETAILS:
      return {
        ...state,
        isFetching: false,
        isError: false,
      };

    case SAVE_INVOICE_PAYMENT_DETAILS:
      return {
        ...state,
        isFetching: false,
        isError: true,
        items: { ...action.payload },
      };

    case ERROR_INVOICE_PAYMENT_DETAILS:
      return {
        ...state,
        isFetching: false,
        isError: true,
      };

    case INVOICE_PAYMENT_SUBMITTED:
      return {
        ...state,
        submitted: true,
      };

    case INVOICE_PAYMENT_ERROR:
      return {
        ...state,
        submitted: false,
        payError: true,
        payErrorMessage: action.payload,
      };

    case INVOICE_PAYMENT_UPDATE_SUCCESS:
      return {
        ...state,
        paymentUpdateStatus: 'success',
      };

    case INVOICE_PAYMENT_UPDATE_ERROR:
      return {
        ...state,
        paymentUpdateStatus: 'error',
      };

    default:
      return {
        ...state,
      };
  }
};

export default payInvoiceReducer;
