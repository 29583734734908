import { Button } from '@teamsnap/teamsnap-ui';
import InvoiceSummaryOverview from 'components/baseComponents/InvoiceSummaryOverview';
import ApplyPayment from 'components/baseComponents/ModalApplyPayment';
import CancelInvoice from 'components/baseComponents/ModalCancelInvoice';
import AddAdjustment from 'components/baseComponents/ModalInvoiceAdjustment';
import IssueRefund from 'components/baseComponents/ModalIssueRefund';
import SendMessage from 'components/baseComponents/ModalSendInvoice';
import { Heading, Loader } from 'components/teamsnapUIExtensions';
import Table from 'components/teamsnapUIExtensions/Table';
import { invoicePaymentPropShape } from 'interfaces/financials';
import { invoicePropShape } from 'interfaces/invoice';
import { invoiceRecipientPropShape } from 'interfaces/member';
import { historyPropShape } from 'interfaces/navigation';
import _startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { formatDate } from 'utils/date-service';
import styles from './InvoiceSummary.module.scss';

const lineItemColumns = [
  { label: 'Category', key: 'type', className: 'Panel-cell u-size4of24' },
  { label: 'Description', key: 'description' },
  { label: 'Amount', key: 'amount', className: 'Panel-cell u-size1of12' },
];

const paymentColumns = [
  { label: 'ID', key: 'id', className: 'Panel-cell u-size2of24', nestedLink: true },
  { label: 'Payment Status', key: 'status', className: 'Panel-cell u-size3of24' },
  { label: 'Date', key: 'date', className: 'Panel-cell u-size3of24' },
  { label: 'Type', key: 'paymentType', className: 'Panel-cell u-size2of24' },
  { label: 'Details', key: 'detail', className: 'Panel-cell u-size6of24' },
  { label: 'Net Collected', key: 'netAmountWithCurrency', className: 'Panel-cell u-size3of24 u-textRight' },
  {
    label: 'Gross Collected',
    key: 'grossAmountWithCurrency',
    className: 'Panel-cell u-size3of24 u-textRight',
  },
  { label: 'Actions', key: 'actions', className: 'Panel-cell u-size3of24 u-textCenter' },
];

const lineItemCategories = {
  1: 'Fee',
  2: 'Dues',
  3: 'Tournament',
  4: 'Uniform',
  5: 'Team Expense',
  6: 'Canceled',
  7: 'Adjustment',
};

class InvoiceSummary extends Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { invoiceId, queryInvoiceWithMemberEmailCollections } = this.props;
    return queryInvoiceWithMemberEmailCollections(invoiceId);
  }

  showMessageModal = () => {
    const {
      showModal,
      batchInvoiceId: batchId,
      invoiceRecipient: { invoiceableId: invoiceRecipientIds },
    } = this.props;
    return showModal(SendMessage, { invoiceRecipientIds, batchId });
  };

  showCancelModal = () => {
    const { showModal, invoiceId, cancelInvoice } = this.props;
    return showModal(CancelInvoice, { id: invoiceId, handleConfirm: cancelInvoice });
  };

  showPaymentModal = () => {
    const { showModal, invoiceId } = this.props;
    return showModal(ApplyPayment, { invoiceId });
  };

  showAdjustmentModal = () => {
    const { showModal, invoiceId } = this.props;
    return showModal(AddAdjustment, { invoiceId });
  };

  showRefundModal = (invoicePayment) => {
    const { showModal, invoiceId } = this.props;
    return showModal(IssueRefund, { invoiceId, invoicePayment });
  };

  // TODO: Currently only amount is returned from the api.  This calculation will need to be provided
  renderLineItems = (lineItems) =>
    lineItems.map((lineItem) => ({
      type: lineItemCategories[lineItem.invoiceCategoryId] || 'Fee',
      description: lineItem.description,
      amount: lineItem.amountWithCurrency,
    }));

  renderPlaceholder = (invoice) => (
    <Table columns={ paymentColumns } paginate={ false } footerComponent={ this.renderPaymentsFooter(invoice) }>
      <p className={ styles.PanelRow }>
        No payments created.
        { invoice.isPayableOffline === 'true' && ' Easily apply offline payments to any invoice. Create one now.' }
      </p>
    </Table>
  );

  renderPaymentsFooter = (invoice) => (
    <div>
      { invoice.isPayableOffline === 'true' && (
        <Button onClick={ this.showPaymentModal } color="primary" icon="plus">
          Apply Payment
        </Button>
      ) }
    </div>
  );

  renderRefundButton = (invoicePayment) => {
    if (!invoicePayment.isRefundable) {
      return null;
    }
    return (
      <Button onClick={ () => this.showRefundModal(invoicePayment) } size="small" mods="u-spaceRightSm" color="negative">
        Refund
      </Button>
    );
  };

  renderNetAmount = (invoicePayment) => {
    if (invoicePayment.status === 'scheduled') {
      return `${invoicePayment.amountWithCurrency} (scheduled)`;
    }
    return invoicePayment.amountWithCurrency;
  };

  renderGrossAmount = (invoicePayment) => {
    if (invoicePayment.status === 'scheduled') {
      return `${invoicePayment.amountWithProcessingFeeWithCurrency} (scheduled)`;
    }
    return invoicePayment.amountWithProcessingFeeWithCurrency;
  };

  renderPaymentsTable = (invoice, invoicePayments) => {
    // TODO: Quick Fix for paymentType 'Stripe' (07-02-19).  Need to update the paymentType in the API.
    const payments = invoicePayments.map((invoicePayment) => ({
      ...invoicePayment,
      dateForSort: formatDate(invoicePayment.scheduledAt || invoicePayment.createdAt, 'YYYY-MM-DD'),
      date: formatDate(invoicePayment.scheduledAt || invoicePayment.createdAt, this.props.dateFormat),
      paymentType: invoicePayment.initialPaymentProviderName.match(/stripe/i)
        ? 'Credit Card'
        : invoicePayment.initialPaymentProviderName,
      status: _startCase(invoicePayment.status),
      nestedRows: invoicePayment.transactions.map((invoiceTransactions) => ({
        ...invoiceTransactions,
        status: _startCase(invoiceTransactions.status),
        date: formatDate(invoiceTransactions.transactedAt, this.props.dateFormat),
        actions: '-',
      })),
      amountWithCurrency: this.renderNetAmount(invoicePayment),
      amountWithProcessingFeeWithCurrency: this.renderGrossAmount(invoicePayment),
      actions: this.renderRefundButton(invoicePayment),
    }));

    return (
      <Table
        columns={ paymentColumns }
        rows={ payments }
        defaultSort="dateForSort"
        paginate={ false }
        footerComponent={ this.renderPaymentsFooter(invoice) }
      />
    );
  };

  render() {
    const {
      invoice,
      invoiceLineItems,
      invoicePayments,
      memberEmails,
      isFetching,
      dateFormat,
      groupType,
      history,
    } = this.props;

    if (isFetching) {
      return <Loader message="random" />;
    }

    const rows = this.renderLineItems(invoiceLineItems);

    return (
      <section>
        <InvoiceSummaryOverview
          invoice={ invoice }
          memberEmails={ memberEmails }
          showMessageModal={ this.showMessageModal }
          showCancelModal={ this.showCancelModal }
          showAdjustmentModal={ this.showAdjustmentModal }
          dateFormat={ dateFormat }
          history={ history }
          groupType={ groupType }
        />

        { groupType === 'divisionId' && (
          <>
            <Heading size={ 3 } title="Invoice Line Items" componentStyles={ styles } />
            <Table columns={ lineItemColumns } rows={ rows } paginate={ false } />
          </>
        ) }

        <Heading size={ 3 } title="Payments Log" componentStyles={ styles } />
        { invoicePayments.length ?
          this.renderPaymentsTable(invoice, invoicePayments) :
          this.renderPlaceholder(invoice)
        }

        { /* TODO: Add Activity log */ }
      </section>
    );
  }
}

InvoiceSummary.propTypes = {
  batchInvoiceId: PropTypes.string.isRequired,
  invoiceId: PropTypes.string.isRequired,
  invoice: PropTypes.shape(invoicePropShape).isRequired,
  invoiceRecipient: PropTypes.shape(invoiceRecipientPropShape).isRequired,
  invoiceLineItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  invoicePayments: PropTypes.arrayOf(PropTypes.shape(invoicePaymentPropShape)).isRequired,
  memberEmails: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFetching: PropTypes.bool.isRequired,
  queryInvoiceWithMemberEmailCollections: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  dateFormat: PropTypes.string.isRequired,
  cancelInvoice: PropTypes.func.isRequired,
  groupType: PropTypes.string,
  history: PropTypes.shape(historyPropShape).isRequired,
};

InvoiceSummary.defaultProps = {
  groupType: null,
};

export default InvoiceSummary;
