/**
 * Invoice Line Item Selector
 *
 * Various selectors using reselect for selecting data from state
 *
 */

import { createSelector } from 'reselect';
import _values from 'lodash/values';
import { selectInvoiceId } from 'state/routerLocation/selectors';
import { selectAndSortBy } from 'state/baseSelectors';

const stateSelector = (state) => state.invoiceLineItems;
const itemSelector = (state) => stateSelector(state).items;
const isFetchingSelector = (state) => stateSelector(state).isFetching;
const isErrorSelector = (state) => stateSelector(state).isError;

// Select invoice line item fetching and error states
export const selectInvoiceLineItemIsFetching = (state) => isFetchingSelector(state);
export const selectInvoiceLineItemIsError = (state) => isErrorSelector(state);

// ReSelect - Select all invoice line items and return as array
export const selectInvoiceLineItems = createSelector(itemSelector, (items) => _values(items));

// ReSelect - Select invoice line items filtered by invoice route
export const selectInvoiceLineItemsByInvoiceRoute = createSelector(
  selectInvoiceId,
  selectInvoiceLineItems,
  (invoiceId, invoiceLineItems) =>
    invoiceLineItems && invoiceLineItems.filter((invoiceLineItem) => invoiceLineItem.invoiceId === invoiceId),
);

// Sort all the invoice line items by invoice id
export const selectAndSortInvoiceLineItemsByInvoiceId = createSelector(selectInvoiceLineItems, (invoiceLineItems) =>
  selectAndSortBy(invoiceLineItems, 'invoiceId'),
);

// return an array of invoice line items given an invoice id, may be an empty array
export const selectInvoiceLineItemsByInvoiceId = (state) => (invoiceId) =>
  selectAndSortInvoiceLineItemsByInvoiceId(state)[invoiceId] || [];
