import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FieldArray, FormSection } from 'redux-form';
import Table from 'components/teamsnapUIExtensions/Table';
import InvoicingLineItems from 'components/baseComponents/InvoicingLineItems';

class LineItemsSection extends PureComponent {
  renderStaticContent = (items) => {
    const columns = [
      { label: 'Category', key: 'category', className: 'Panel-cell u-size2of8' },
      { label: 'Description', key: 'description', className: 'Panel-cell u-size4of8' },
      { label: 'Price', key: 'amount', className: 'Panel-cell u-size1of8 u-textRight' },
    ];

    const rows = items.map((item) => ({ ...item }));
    return <Table columns={ columns } rows={ rows } />;
  };

  render() {
    const { invoicingId, sectionName, batchInvoiceLineItems, disableLineItems } = this.props;

    return (
      <FormSection name={ sectionName }>
        { invoicingId ? (
          this.renderStaticContent(batchInvoiceLineItems.lineItems)
        ) : (
          <FieldArray name="items" disableLineItems={ disableLineItems } component={ InvoicingLineItems } />
        ) }
      </FormSection>
    );
  }
}

LineItemsSection.propTypes = {
  disableLineItems: PropTypes.bool.isRequired,
  invoicingId: PropTypes.string,
  sectionName: PropTypes.string,
  batchInvoiceLineItems: PropTypes.object,
};

LineItemsSection.defaultProps = {
  invoicingId: null,
  sectionName: null,
  batchInvoiceLineItems: {},
};

export default LineItemsSection;
