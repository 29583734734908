/**
 * Form Component
 *
 * Simple form components that requires children and a handleSubmit function.
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@teamsnap/teamsnap-ui';
import defaultStyles from './Form.module.scss';

class Form extends PureComponent {
  render() {
    const { children, handleSubmit, isGrid, componentStyles, className } = this.props;
    const styles = { ...defaultStyles, ...componentStyles };

    return (
      <form onSubmit={ handleSubmit } className={ styles[className] }>
        { isGrid ? <Grid isWithGutter>{ children }</Grid> : children }
      </form>
    );
  }
}

Form.propTypes = {
  children: PropTypes.node.isRequired,
  handleSubmit: PropTypes.func,
  isGrid: PropTypes.bool,
  componentStyles: PropTypes.shape({}),
  className: PropTypes.string,
};

Form.defaultProps = {
  handleSubmit: null,
  isGrid: false,
  componentStyles: {},
  className: 'Form',
};

export default Form;
