/**
 * Batch Invoice Payment Schedules
 *
 * Various actions using redux-thunk and axios for managing communication with API and redux store
 *
 */

import { getCollection } from 'state/baseActions';
import { formatLocalDate } from 'utils/date-service';
import api from 'services/api';

export const BATCH_INVOICE_PAYMENT_SCHEDULE = 'BATCH_INVOICE_PAYMENT_SCHEDULE';

export const loadBatchInvoicePaymentSchedule = (batchId) => (dispatch) =>
  dispatch(
    getCollection(BATCH_INVOICE_PAYMENT_SCHEDULE, 'batch_invoice_payment_schedules/search', {
      batch_invoice_id: batchId,
    }),
  );

export const createBatchInvoicePaymentSchedule = (batchInvoiceId, scheduled) => () => {
  const paymentDates = scheduled.map((date) => formatLocalDate(date.paymentDate)).join(',');

  return api
    .post('batch_invoice_payment_schedules', { batchInvoiceId, paymentDates })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
};
